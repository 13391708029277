export const OverviewSection = ({ order, title, children }) => {
  return (
    <div className="bg-white border border-softCloud rounded-lg p-8 shadow-sm">
      <div className="flex items-center gap-3 mb-6">
        <div className="flex items-center justify-center w-6 h-6 text-xs text-duskyHarbor font-medium border border-whisperBlue rounded bg-transparent">
          {order}
        </div>

        <h2 className="text-base text-deepSpaceBlue font-medium">{title}</h2>
      </div>

      <div className="md:ml-9">
        {children}
      </div>
    </div>
  );
}