import { useMemo } from "react";
import { GroupOptions } from "./groupOptions/GroupOptions";
import { groupUsers } from "./utils";

const GroupListRow = ({ group }) => {
  const { educators, students } = useMemo(
    () => groupUsers(group?.users),
    [group?.users]
  );

  return (
    <tr
      className="transition-all duration-300 border-t border-alabaster group hover:bg-lightGrey text-center text-sm">
      <td
        className="flex text-xs text-duskyHarbor items-center gap-4 text-left py-[14px] px-2 pl-6 group-hover:bg-lightGrey transition-all duration-300 bg-white">
        <div className="flex items-center gap-4 font-medium">
          {group?.name}
        </div>
      </td>

      <td className="text-center px-2 text-xs">
        {educators.length}
      </td>

      <td className="text-center px-2 text-xs">
        {students.length}
      </td>

      <td className="px-2 font-medium">
        <div className="flex items-center justify-center">
          <GroupOptions
            group={group}
            educators={educators}
            students={students}
            containerClassName="m-auto"
            modalPosition="right"
          />
        </div>
      </td>
    </tr>
  );
}

export const GroupList = ({groups}) => {
  return (
    <table className="w-full">
      <thead>
      <tr className="text-center text-fadedDenim pb-2 text-xs">
        <th className="w-1/3 text-left py-[14px] px-2 pl-6 font-normal">
          Name
        </th>
        <th className="w-[22%] px-2 font-normal text-center">
          Educators
        </th>
        <th className="w-[22%] px-2 font-normal text-center">
          Students
        </th>
        <th className="w-[22%] px-2 font-normal text-center py-[14px]"></th>
      </tr>
      </thead>
      <tbody className="text-sm text-right text-black">
      {groups?.map((group) => (
        <GroupListRow
          key={group?.id}
          group={group}
        />
      ))}
      </tbody>
    </table>
  );
}