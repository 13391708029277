import { useMemo } from "react";
import { assessmentStore } from "../../../../store";
import { extendTimeByAccommodations } from "../ViewCustomisation";
import { getAssessmentSectionTime } from "../../../../helpers/assessment";

function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const parts = [];

  if (hours > 0) {
    parts.push(`${hours}h`);
  }

  if (minutes > 0) {
    parts.push(`${minutes}min`);
  }

  if (remainingSeconds > 0 || (hours === 0 && minutes === 0)) {
    parts.push(`${remainingSeconds}s`);
  }

  return parts.join(' ');
}

export const AssessmentInfoSpecs = () => {
  const {
    assessmentId,
    assessmentVersionId,
    publishedAssessments,
    accommodations,
  } = assessmentStore();

  const {
    totalAssessmentTime = '-',
    totalQuestions = '-',
    maxScore = '-',
  } = useMemo(() => {
    const assessment = publishedAssessments.find((item) =>
      item?.assessmentId?.toLowerCase() === assessmentId?.toLowerCase() &&
      item?.assessivVersionId?.toLowerCase() === assessmentVersionId?.toLowerCase()
    );

    if (!assessment) {
      return {};
    }

    const assessmentTime = assessment?.sectionResponses?.reduce?.(
      (acc, section) => acc + getAssessmentSectionTime(section),
      0
    ) ?? 0;

    const assessmentQuestions = assessment?.sectionResponses?.reduce?.(
      (acc, section) => acc + (section?.totalQuestions ?? 0),
      0
    ) ?? 0;

    return {
      totalAssessmentTime: formatTime(extendTimeByAccommodations(assessmentTime, accommodations)),
      totalQuestions: assessmentQuestions,
      maxScore: assessmentQuestions,
    };
  }, [assessmentId, assessmentVersionId, publishedAssessments, accommodations]);

  return (
    <table className="text-sm border-spacing-1 border-separate">
      <tbody>
      <tr>
        <td className="text-duskyHarbor">Time:</td>
        <td className="text-navySmoke">{totalAssessmentTime}</td>
      </tr>
      <tr>
        <td className="text-duskyHarbor">Questions:</td>
        <td className="text-navySmoke">{totalQuestions}</td>
      </tr>
      <tr>
        <td className="text-duskyHarbor pr-2">Max score:</td>
        <td className="text-navySmoke">{maxScore}</td>
      </tr>
      <tr>
        <td className="text-duskyHarbor">Company:</td>
        <td className="text-navySmoke">-</td>
      </tr>
      </tbody>
    </table>
  );
}