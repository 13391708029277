export const AssessmentInfoDescription = () => {
  return (
    <p className="text-xs text-duskyHarbor">
      Selecting
      <span className="font-bold"> Continue </span>
      will allow you to continue a test if you were disconnected for any reason, preserving your existing answers and
      test timing. Selecting
      <span className="font-bold"> Start from Beginning </span>
      will allow you to retake the full test and create a partial score report from your previous answers. If you choose
      <span className="font-bold"> Start from Beginning </span>
      , you will no longer be able to continue your previous attempt and that score report will be automatically
      generated.
    </p>
  );
}