const TableHeadCell = ({ children, className, isFirstCell, isLastCell }) => {
  const getClassName = () => {
    if (isFirstCell) {
      return 'text-left pl-6 pr-2 pb-2 font-normal sticky left-0 bg-white';
    } else if (isLastCell) {
      return 'pr-6 pl-2 font-normal'
    } else {
      return 'px-2 font-normal';
    }
  }

  return (
    <th className={`${getClassName() ?? ''} ${className ?? ''}`}>
      {children}
    </th>
  );
}

const TableBodyCell = ({ children, className, isFirstCell, isLastCell }) => {
  const getClassName = () => {
    if (isFirstCell) {
      return 'text-left py-[14px] pl-6 pr-2 sticky left-0 bg-white group-hover:bg-lightGrey max-w-[75%]';
    } else if (isLastCell) {
      return 'pl-2 pr-6 text-center lg:text-right font-medium'
    } else {
      return 'px-2';
    }
  }

  return (
    <td className={`${getClassName() ?? ''} ${className ?? ''}`}>
      {children}
    </td>
  );
}

export const Table = ({ columns = [], rows = [], containerClassName = '', columnRowClassName = '' }) => {
  return (
    <div className={`overflow-auto relative scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md w-full ${containerClassName}`}>
      <table className="min-w-full">
        <thead>
        <tr className={`text-right text-fadedDenim pb-2 ${columnRowClassName}`}>
          {columns.map((column, index) => (
            <TableHeadCell
              key={column.id}
              className={column.className}
              isFirstCell={!index}
              isLastCell={index + 1 === columns.length}
            >
              {column.content}
            </TableHeadCell>
          ))}
        </tr>
        </thead>

        <tbody className="text-sm text-right text-black">
        {rows.map((row) => (
          <tr
            key={row.id}
            className={`px-[14px] border-t border-alabaster cursor-pointer group hover:bg-lightGrey ${row?.className ?? ''}`}
            onClick={row.onClick}
          >
            {row?.cells?.map((cell, index) => (
              <TableBodyCell
                key={cell.id}
                className={cell.className}
                isFirstCell={!index}
                isLastCell={index + 1 === columns.length}
              >
                {cell.content}
              </TableBodyCell>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
}