import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { ReactComponent as CloseCrossIcon } from "../../../../assets/close-cross.svg";
import { Tabs } from "../../../../components/Tabs";
import { Table } from "../../../../components/Table";
import Pagination from "../../../../components/Pagination";
import { SortedComboBox, SORTING } from "../../../../components/SortedCombobox";
import { assessmentStore, tablesStore } from "../../../../store";
import { INTROJS_SELECTOR } from "../../sections/home/components/student/StudentDashboardExcursion";
import { StatusLabel } from "../../sections/home/components/general/StatusLabel";
import { ASSESSMENT_STATUS } from "../../../../helpers/constants";
import { formatToUSDate } from "../../../../helpers/date.ts";
import {
  fetchCompletedAssessmentList,
  fetchPublishedAssessmentListByStudent,
  fetchUserAssessmentList
} from "../../../../actions/assessment";
import { StudentCompletedAssessments } from "../../sections/home/components/student/analytics";
import { Tooltip } from "../../../../components/Tooltip";
import {
  attachAssignInfoToPublishedAssessments,
  dividePracticeAndNotPracticeAssessments,
  isPaidAssessment,
  sortAssessmentsByNameAlphabetically,
  sortAssessmentsByNewest,
  sortAssessmentsByStatus,
} from "../../../../helpers/assessment";
import { filterItemsByPage } from "../../../../helpers/functions.ts";

const backdropVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modalVariants = {
  visible: { y: 0 },
  hidden: { y: 50 },
};

const TAB = {
  AVAILABLE: 'Assessivs',
  PRACTICE: 'Practice Assessivs',
  COMPLETED: 'Completed Assessivs'
};

const ASSESSMENTS_PER_PAGE = 7;

const tableColumns = [
  { content: 'Name', id: 'Name', className: 'pl-0 py-[14px] font-normal' },
  { content: 'Date', id: 'Date', className: '!text-center lg:text-center py-[14px] font-normal' },
  { content: 'Math Score', id: 'Math Score', className: 'text-center lg:text-right py-[14px] font-normal' },
  { content: 'RW Score', id: 'RW Score', className: 'text-center lg:text-right py-[14px] font-normal' },
  { content: 'Total Score', id: 'Total Score', className: 'text-center lg:text-right py-[14px] font-normal' },
  { content: 'Status', id: 'Status', className: 'text-center lg:text-right pr-0 py-[14px] font-normal' },
];

const tabs = [
  {
    id: TAB.PRACTICE,
    content: `${TAB.PRACTICE}`
  },
  {
    id: TAB.AVAILABLE,
    content: `${TAB.AVAILABLE}`
  },
  {
    id: TAB.COMPLETED,
    content: `${TAB.COMPLETED}`
  },
];

export const TryAssessmentModal = ({ closeModal }) => {
  const { publishedAssessments, assessments: assignedAssessments, setAssessments, setCompletedAssessments, setPublishedAssessments, setAssessmentId, setAssessmentVersionId, setEducatorId, resetResults, setResultsReady } = assessmentStore();
  const { studentsTableSortingType, availableAssessmentTablePage, assessmentTableTab, practiceAssessmentTablePage, setStudentsTableSortingType, setPracticeAssessmentTablePage, setAvailableAssessmentTablePage, setAssessmentTableTab,  } = tablesStore();
  const [currentTab, setCurrentTab] = useState(assessmentTableTab ?? TAB.PRACTICE);
  const navigate = useNavigate();

  const handleOnClickTab = (tab) => {
    setCurrentTab(tab);
    setAssessmentTableTab(tab);
  };

  const [availableAssessments, practiceAssessments] = useMemo(() => {
      const assessmentsWithStatus = attachAssignInfoToPublishedAssessments(publishedAssessments, assignedAssessments);

      return dividePracticeAndNotPracticeAssessments(assessmentsWithStatus);
    },
    [publishedAssessments, assignedAssessments]
  );

  const assessments = useMemo(
    () => currentTab === TAB.AVAILABLE ? availableAssessments : practiceAssessments,
    [currentTab, availableAssessments, practiceAssessments]
  );

  const sortedAssessments = useMemo(() => {
    switch (studentsTableSortingType) {
      case SORTING.ALPHABETICAL: return sortAssessmentsByNameAlphabetically(assessments);
      case SORTING.NEWEST: return sortAssessmentsByNewest(assessments);
      default: return sortAssessmentsByStatus(assessments);
    }
  }, [assessments, studentsTableSortingType]);

  const filteredAssessments = useMemo(() => {
    const currentPage = currentTab === TAB.AVAILABLE ? availableAssessmentTablePage : practiceAssessmentTablePage;
    return filterItemsByPage(sortedAssessments, ASSESSMENTS_PER_PAGE, currentPage);
  }, [currentTab, availableAssessmentTablePage, practiceAssessmentTablePage, sortedAssessments]);

  const tableData = useMemo(() => {
    const handleSelectAssessment = (assessment) => () => {
      return Promise.all([
        setAssessmentId(assessment?.assessmentId),
        setAssessmentVersionId(assessment?.assessmentVersionId ?? assessment?.assessivVersionId ?? ''),
        setEducatorId(assessment?.educatorId ?? ''),
        resetResults(),
        setResultsReady(false)
      ])
        .then(() => navigate('/assessment/start'))
        .catch((error) => console.log(error));
    }

    return filteredAssessments?.map(assessment => (
      {
        id: `${assessment.assessmentId}${assessment.assessmentVersionId}${assessment.educatorId}`,
        onClick: isPaidAssessment(assessment) ? undefined : handleSelectAssessment(assessment),
        cells: [
          {
            className: 'pl-0 py-[14px]',
            id: assessment.assessmentId + 'Name',
            content: (
              <div className="flex items-center gap-3">
                {isPaidAssessment(assessment) && (
                  <Tooltip wrapperClassName="-mr-1.5" tooltipPopupClassName="!w-[156px]" text="Paid Assessiv. Payment required to access.">
                    <LockClosedIcon className="h-4" />
                  </Tooltip>
                )}
                {assessment.name}
                {(assessment?.status === ASSESSMENT_STATUS.FRESH || !assessment?.status) && (
                  <StatusLabel className="relative top-px" status={assessment?.status} />
                )}
              </div>
            )
          },
          {
            id: assessment.assessmentId + 'Date',
            className: 'text-center',
            content: formatToUSDate(assessment?.publishedDate ?? '') ?? '-'
          },
          {
            id: assessment.assessmentId + 'Math Score',
            content: '-'
          },
          {
            id: assessment.assessmentId + 'RW Score',
            content: '-'
          },
          {
            id: assessment.assessmentId + 'Total Score',
            content: '-'
          },
          {
            className: 'pr-0 font-normal',
            id: assessment.assessmentId + 'Status Score',
            content: (
              (assessment?.status === ASSESSMENT_STATUS.FRESH || !assessment?.status) ? (
                <span className="text-xs text-gray-500">Not Started</span>
              ) : (
                <StatusLabel status={assessment?.status} />
              )
            )
          },
        ]
      }
    )) ?? [];
  }, [filteredAssessments]);

  useEffect(() => {
    fetchPublishedAssessmentListByStudent()
      .then((data) => setPublishedAssessments(data?.data ?? []));
    fetchUserAssessmentList()
      .then(({ data }) => setAssessments(data));
    fetchCompletedAssessmentList()
      .then((data) => setCompletedAssessments(data?.data ?? []))
  }, []);

  useEffect(() => {
    if (assessmentTableTab !== currentTab) {
      setCurrentTab(assessmentTableTab);
    }
  }, [assessmentTableTab, currentTab]);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={backdropVariants}
        transition={{ duration: 0.3 }}
        id="staticModal"
        data-modal-backdrop="static"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 bg-black bg-opacity-20 "
      >
        <motion.div
          variants={modalVariants}
          transition={{ duration: 0.5, stiffness: 150 }}
          className="relative w-full max-w-[1025px] max-h-full"
        >
          <div className="flex flex-col relative bg-white rounded shadow">
            <button
              type="button"
              className="text-gray-400 mt-3 mr-3 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm w-5 h-5 ml-auto inline-flex justify-center items-center"
              data-modal-hide="staticModal"
              onClick={closeModal}
            >
              <CloseCrossIcon className="w-3 h-3"/>
              <span className="sr-only">Close modal</span>
            </button>

            <div
              className={`flex flex-col p-8 md:p-6 pt-0 md:pt-0 text-gray-500 bg-white flex-1 rounded-lg shadow-sm ${INTROJS_SELECTOR.ASSESSMENTS}`}>
              <div>
                <Tabs currentTabId={currentTab} onChangeTab={handleOnClickTab} tabs={tabs}/>
              </div>

              <div className="flex flex-col flex-1 overflow-y-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-lg min-h-[468px] max-h-[468px]">
                {assessments.length ? (
                  currentTab === TAB.COMPLETED ? (
                    <StudentCompletedAssessments
                      assessmentPerPage={ASSESSMENTS_PER_PAGE}
                      className="flex flex-col justify-between flex-1"
                    />
                  ) : (
                    <>
                      <Table
                        columns={tableColumns}
                        rows={tableData}
                        containerClassName="mb-2"
                        columnRowClassName="text-xs"
                      />

                      <div className="flex items-center justify-between mt-auto">
                        <div className="opacity-0 pointer-events-none"><SortedComboBox/></div>
                        <Pagination
                          currentPage={currentTab === TAB.AVAILABLE ? availableAssessmentTablePage : practiceAssessmentTablePage}
                          onPageChange={currentTab === TAB.AVAILABLE ? setAvailableAssessmentTablePage : setPracticeAssessmentTablePage}
                          totalPages={Math.ceil((assessments?.length ?? 0) / ASSESSMENTS_PER_PAGE)}
                        />
                        <SortedComboBox/>
                      </div>
                    </>
                  )
                ) : (
                  <div className="text-xl text-slate-500 text-center p-4 md:p-6 pt-8 md:pt-12">
                    No assessments
                  </div>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};