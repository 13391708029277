import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { ArrowUturnLeftIcon, ArrowUturnRightIcon, CubeTransparentIcon } from "@heroicons/react/20/solid";
import { AnimatePresence, motion } from "framer-motion";
import AvatarEditor from "react-avatar-editor";
import { SettingRangeInput } from "./SettingRangeInput";
import { SettingsButton } from "./SettingsButton";
import { ReactComponent as CloseCrossIcon } from "../../../../assets/close-cross.svg";

const backdropVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modalVariants = {
  visible: { y: 0 },
  hidden: { y: 50 },
};

function isValidURL(string) {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}

async function imageUrlToBase64(imageUrl) {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error converting image URL to base64:', error);
    throw error;
  }
}

export const EditLogo = ({ image, setImage, isEditModalVisible, setIsEditModalVisible }) => {
  const avatarEditorRef = useRef(null);
  const [base64Src, setBase64src] = useState('');
  const [editProps, setEditProps] = useState({
    allowZoomOut: true,
    position: {x: 0.5, y: 0.5},
    scale: 1,
    rotate: 0,
    borderRadius: 0,
    preview: undefined,
    width: 720,
    height: 200,
    disableCanvasRotation: false,
    isTransparent: false,
    backgroundColor: undefined,
  });

  const handleScale = (event) => {
    const scale = parseFloat(event.target.value)
    setEditProps({ ...editProps, scale })
  }

  const rotateScale = (event) => {
    event.preventDefault()
    setEditProps({ ...editProps, rotate: parseFloat(event.target.value) })
  }

  const rotateLeft = (event) => {
    event.preventDefault()
    setEditProps({ ...editProps, rotate: (editProps.rotate - 90) % 360 })
  }

  const rotateRight = (event) => {
    event.preventDefault()
    setEditProps({ ...editProps, rotate: (editProps.rotate + 90) % 360 })
  }

  const handleWidth = (event) => {
    let width = parseInt(event.target.value);
    if (width > 720) {
      width = 720;
    }
    setEditProps({ ...editProps, width })
  }

  const handleHeight = (event) => {
    let height = parseInt(event.target.value);
    if (height > 300) {
      height = 300;
    }
    setEditProps({ ...editProps, height })
  }

  const handlePositionChange = (position) => {
    setEditProps({ ...editProps, position })
  }

  const handleApplyChanges = async () => {
    setIsEditModalVisible(false);
    const canvas = avatarEditorRef.current.getImageScaledToCanvas();
    if (canvas) {
      const img = new Image();
      img.onload = () => {
        console.log(canvas)
        const editedImageBase64 = canvas.toDataURL('image/png', 0.85);
        setImage(editedImageBase64);
      };
      img.src = image;
    }
  }

  useEffect(() => {
    if (isValidURL(image) || image?.startsWith('/static/media/')) {
      imageUrlToBase64(image)
        .then(base64 => {
          setBase64src(base64);
        })
        .catch(error => {
          console.error('Error converting image URL to base64:', error);
        });
    }
  }, [image]);

  return (
    <>
      <button
        className="text-sm flex items-center gap-2 px-3 py-1 border border-grayLight rounded-md cursor-pointer h-9"
        onClick={() => setIsEditModalVisible(true)}
      >
        <CubeTransparentIcon className="w-4 h-4"/>
        Edit
      </button>

      {createPortal(
        <AnimatePresence mode="wait">
          {isEditModalVisible && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={backdropVariants}
              transition={{duration: 0.3}}
              id="staticModal"
              data-modal-backdrop="static"
              tabIndex="-1"
              aria-hidden="true"
              className="fixed top-0 left-0 right-0 bottom-0 z-[999] flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 bg-black bg-opacity-20"
            >
              <motion.div
                variants={modalVariants}
                transition={{duration: 0.3, stiffness: 150}}
                className="relative max-w-full max-h-full"
              >
                <div
                  className="relative flex flex-col gap-6 p-6 rounded-lg bg-white ma-h-[600px] overflow-y-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md">
                  <h2 className="text-2xl text-center font-semibold">Edit logo</h2>
                  <button
                    type="button"
                    className="absolute top-7 right-6 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm w-5 h-5 inline-flex justify-center items-center"
                    data-modal-hide="staticModal"
                    onClick={() => setIsEditModalVisible(false)}
                  >
                    <CloseCrossIcon className="w-3 h-3" />
                    <span className="sr-only">Close modal</span>
                  </button>
                  <div className="flex justify-center min-w-[770px] max-w-full">
                    <AvatarEditor
                      disableCanvasRotation
                      ref={avatarEditorRef}
                      image={base64Src}
                      scale={editProps.scale}
                      width={editProps.width}
                      height={editProps.height}
                      position={editProps.position}
                      showGrid={editProps.showGrid}
                      rotate={editProps.rotate}
                      borderRadius={editProps.width / (100 / editProps.borderRadius)}
                      backgroundColor={editProps.backgroundColor}
                      onPositionChange={handlePositionChange}
                    />
                  </div>
                  <div className="flex justify-between gap-6">
                    <div className="flex flex-col gap-3 flex-1">
                      <div className="flex flex-col gap-1">
                        <h2 className="flex items-baseline gap-1 font-semibold text-sm text-gray-700 self-start">
                          Width
                          <span className="text-xs text-gray-300">
                        (max: 720)
                      </span>
                        </h2>
                        <input
                          name="width"
                          type="number"
                          className="hide-input-arrows flex h-9 w-full rounded-md border border-slate-300 bg-transparent px-3 py-1 text-sm shadow-sm placeholder:text-slate-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                          onChange={handleWidth}
                          step="10"
                          value={editProps.width}
                        />
                      </div>
                      <div className="flex flex-col gap-1">
                        <h2 className="flex items-baseline gap-1 font-semibold text-sm text-gray-700 self-start">
                          Height
                          <span className="text-xs text-gray-300">
                        (max: 300)
                      </span>
                        </h2>
                        <input
                          name="height"
                          type="number"
                          onChange={handleHeight}
                          className="hide-input-arrows flex h-9 w-full rounded-md border border-slate-300 bg-transparent px-3 py-1 text-sm shadow-sm placeholder:text-slate-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                          step="10"
                          value={editProps.height}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 flex-1">
                      <h2 className="font-semibold text-sm text-gray-700 self-start">
                        Zoom
                      </h2>
                      <SettingRangeInput
                        name="scale"
                        onChange={handleScale}
                        min={editProps.allowZoomOut ? '0.1' : '1'}
                        max="2"
                        step="0.01"
                        defaultValue="1"
                      />
                    </div>
                    <div className="flex flex-col gap-3 flex-1">
                      <div className="flex flex-col gap-1">
                        <h2 className="font-semibold text-sm text-gray-700 self-start">
                          Rotate
                        </h2>
                        <div className="flex items-center gap-2">
                          <SettingsButton onClick={rotateLeft}>
                            <ArrowUturnLeftIcon className="w-4 h-4"/>
                            Left
                          </SettingsButton>
                          <SettingsButton onClick={rotateRight}>
                            <ArrowUturnRightIcon className="w-4 h-4"/>
                            Right
                          </SettingsButton>
                        </div>
                      </div>
                      <div className="flex flex-col gap-1">
                        <h2 className="font-semibold text-sm text-gray-700 self-start">
                          Rotation
                        </h2>
                        <SettingRangeInput
                          name="rotation"
                          onChange={rotateScale}
                          min="0"
                          max="180"
                          step="1"
                          defaultValue="0"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end gap-2 mt-3">
                    <SettingsButton
                      className="text-white !bg-blue-500 !hover:bg-blue-600 "
                      onClick={handleApplyChanges}
                    >
                      Apply
                    </SettingsButton>
                    <SettingsButton onClick={() => setIsEditModalVisible(false)}>
                      Cancel
                    </SettingsButton>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>,
        document.body
      )}
    </>
  );
}