import { Switch } from "@headlessui/react";
import { loginStore } from "../../../../store";

export const EmailNotifications = () => {
  const { emailNotificationsEnabled, setEmailNotificationsEnabled, } = loginStore();

  return (
    <div className="flex flex-col gap-1">
      <h2 className="font-semibold text-sm text-gray-700 self-start">
        Email Notifications
      </h2>
      <Switch
        checked={emailNotificationsEnabled}
        onChange={setEmailNotificationsEnabled}
        className={`${emailNotificationsEnabled ? 'bg-blue-500' : 'bg-blue-100'} relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
      >
        <span className="sr-only">Email notifications</span>
        <span
          aria-hidden="true"
          className={`${emailNotificationsEnabled ? 'translate-x-6' : 'translate-x-0'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    </div>
  );
}