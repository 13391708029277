import { Fragment, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { VerifyLoading } from "./verifyStates/VerifyLoading";
import { VerifySuccess } from "./verifyStates/VerifySuccess";
import { VerifyError } from "./verifyStates/VerifyError";
import { VerifyInValidParams } from "./verifyStates/VerifyInValidParams";
import { registerUser } from "../../../actions/registration";

export default function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const password = searchParams.get("password");
  const token = searchParams.get("token");
  const firstName = searchParams.get("firstName");
  const lastName = searchParams.get("lastName");
  const companyName = searchParams.get("companyName");

  // 0 for loading, 1 for success, 2 for verify error, 3 any error, 4 for invalid URL params error
  const [verificationState, setVerificationState] = useState(0);

  const VerifyContent = useMemo(() => {
    switch (verificationState) {
      case 0: return VerifyLoading;
      case 1: return VerifySuccess;
      case 2: return VerifyError;
      case 3: return VerifyInValidParams;
      default: return Fragment;
    }
  }, [verificationState]);

  useEffect(() => {
    const name = `${firstName ?? ''} ${lastName ?? ''}`;
    if (!!email && !!token && !!password && name.length > 1) {
      registerUser({ email, token, password, name, companyName: companyName ? companyName : undefined })
        .then(() => {
          setVerificationState(1);
        })
        .catch(() => {
          setVerificationState(2);
        });
    } else {
      setVerificationState(3);
    }
  }, [email, token, password, firstName, lastName, companyName]);

  return (
    <div className="flex flex-1 flex-col h-screen  w-screen items-center justify-center overflow-hidden">
      <VerifyContent />
    </div>
  );
}
