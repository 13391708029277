import { ReactComponent as TrackingIconS1 } from "../../../assets/trackS1.svg";
import trackImage_s1 from "../../../assets/step1.png";
import { ReactComponent as TrackingIconS2 } from "../../../assets/trackS2.svg";
import trackImage_s2 from "../../../assets/step2.png";
import { ReactComponent as TrackingIconS3 } from "../../../assets/trackS3.svg";
import trackImage_s3 from "../../../assets/step3.png";

export const Guide = () => {
  return (
    <div>
      <div className="flex flex-col md:flex-row container mx-auto gap-8 justify-center py-16">
        <TrackingIconS1 className="h-[22em] max-w-full"/>
        <img src={trackImage_s1} alt="" className="rounded-lg w-[52em] h-[26em]"/>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto gap-8 justify-center py-16">
        <img src={trackImage_s2} alt="" className="w-[32em] h-[30em]"/>
        <TrackingIconS2 className="h-[22em] max-w-full"/>
      </div>
      <div className="flex flex-col md:flex-row container mx-auto gap-8 justify-center py-16 mb-16">
        <TrackingIconS3 className="h-[22em] max-w-full"/>
        <img src={trackImage_s3} alt="" className="w-[33em] h-[22em]"/>
      </div>
    </div>
  );
}