import { useEffect, useState } from "react";
import { INTROJS_SELECTOR } from "../StudentDashboardExcursion";
import { AssessmentTable } from "./AssessmentTable";
import { fetchUserAssessmentList } from "../../../../../../../actions/assessment";
import { assessmentStore } from "../../../../../../../store";
import { StatusLabel } from "../../general/StatusLabel";
import { formatDateStatus } from "../../../../../../../helpers/date.ts";
import { ASSESSMENT_STATUS } from "../../../../../../../helpers/constants";
import { Skeleton } from "../../../../../../../components/Skeleton";

export const UpcomingActivity = ({ onClickAssessment }) => {
  const { assessments, setAssessments, setAssessmentId, setAssessmentVersionId, setEducatorId, resetResults, setResultsReady } = assessmentStore();
  const [isLoading, setIsLoading] = useState(true);

  const handleSelectAssessment = (assessment) => () => {
    return Promise.all([
      setAssessmentId(assessment?.assessmentId),
      setAssessmentVersionId(assessment?.assessmentVersionId),
      setEducatorId(assessment?.educatorId),
      resetResults(),
      setResultsReady(false)
    ])
      .then(onClickAssessment)
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    fetchUserAssessmentList()
      .then(({ data }) => setAssessments(data))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className={`flex flex-col p-6 bg-white border border-softCloud rounded-lg shadow-sm ${INTROJS_SELECTOR.UPCOMING_ACTIVITY}`}>
      <h2 className="text-base font-medium mb-3">Upcoming activity</h2>

      {isLoading && !assessments?.length ? (
        <div
          className="flex flex-col max-h-[256px] -mr-5 pr-5 overflow-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md scrollbar-rounded-md">
          {Array.from({ length: 10 }, (_, index) => (
            <div key={index} className={`py-3 border-t ${index === 0 ? 'border-transparent' : 'border-gray-300'}`}>
              <Skeleton className="h-8 w-full" />
            </div>
          ))}
        </div>
      ) : !assessments?.length ? (
        <div className="text-xl text-slate-500 text-center p-4 md:p-6 pt-8 md:pt-12">
          No assessments
        </div>
      ) : (
        <div
          className="flex flex-col max-h-[256px] -mr-5 pr-5 overflow-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md scrollbar-rounded-md">
          {assessments.map((assessment, index) => (
            <div
              key={`${assessment.assessmentId} ${assessment.assessmentVersionId} ${assessment.educatorId}`}
              className={`py-3 flex flex-col gap-2 border-t cursor-pointer ${index === 0 ? 'border-transparent' : 'border-gray-300'}`}
              onClick={handleSelectAssessment(assessment)}
            >
              <div className="flex gap-4 items-center justify-between">
                <span className="text-deepSpaceBlue text-xs">{assessment.name}</span>
                <span className="text-fadedDenim text-xs text-right">{assessment?.status === ASSESSMENT_STATUS.COMPLETED ? <StatusLabel status={assessment?.status} /> : formatDateStatus(assessment.dueDate)}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export const UpcomingActivityExpanded = ({ onClickAssessment }) => {
  const { assessments } = assessmentStore();

  return (
    <div className={`flex flex-col p-8 md:p-6 text-gray-500 bg-white border border-softCloud rounded-lg shadow-sm ${INTROJS_SELECTOR.UPCOMING_ACTIVITY}`}>
      <h2 className="text-base text-navySmoke font-medium mb-3">Upcoming activity</h2>

      {assessments?.length ? (
        <AssessmentTable assessments={assessments ?? []} onClickAssessment={onClickAssessment} />
      ) : (
        <div className="text-xl text-slate-500 text-center p-4 md:p-6 pt-8 md:pt-16">
          No assessments
        </div>
      )}
    </div>
  );
}