import { useMemo} from "react";
import { GroupOptions } from "./groupOptions/GroupOptions";
import { groupUsers } from "./utils";
import { UsersBubbles } from "../../../../../../../../components/UsersBubbles";

const GroupPlate = ({ group }) => {
  const { educators, students } = useMemo(
    () => groupUsers(group?.users),
    [group?.users]
  );

  return (
    <div className="bg-white rounded-lg border border-softCloud p-6 flex flex-col gap-2 min-w-[170px]">
      <h3 className="font-medium text-base mb-auto">{group.name}</h3>
      <span className="font-medium text-xs">{educators?.length} educator{educators.length > 1 && 's'}</span>
      <UsersBubbles users={educators} bubbleBackground="bg-[#eb7900]"/>

      <span className="font-medium text-xs">{students?.length} students</span>
      <UsersBubbles users={students}>
        <GroupOptions group={group} educators={educators} students={students}/>
      </UsersBubbles>
    </div>
  );
}

export const GroupPlates = ({ groups }) => {
  return (
    <div className="flex flex-wrap gap-6">
      {groups.map((group) => (
        <GroupPlate key={group.id} group={group}/>
      ))}
    </div>
  );
}