import { useEffect, useMemo, useRef, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { assessmentStore } from "../../../../../../../store";
import Pagination from "../../../../../../../components/Pagination";
import { Abbreviator } from "../../../../../../../helpers/index.ts";
import { getEducatorUserScoreHistory } from "../../../../../../../actions/assessment";
import { checkUserExists } from "../../../../../../../actions/registration";
import { CompletedResultsTable } from "../../../../../../../components/completedResultsTable";
import { createSortingByLatestFirstCallback } from "../../../../../../../helpers/date.ts";

const RESULTS_PER_PAGE = 10;

const CompletedAssessmentTableRow = ({ assessment }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);
  const [historyOfResults, setHistoryOfResults] = useState([]);

  const toggleExpanded = () => {
    if (!isExpanded && !historyOfResults?.length) {
      checkUserExists(assessment?.studentEmail)
        .then((data) => {
          const accountId = data?.data?.accountId;
          if (accountId) {
            getEducatorUserScoreHistory(accountId)
              .then((data) => {
                setHistoryOfResults(data?.data ?? []);
              })
              .finally(() => {
                setIsExpanded(true);
              });
          } else {
            setIsExpanded(true);
          }
        });
    } else {
      setIsExpanded(prev => !prev);
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isExpanded ? contentRef.current.scrollHeight : 0);
    }
  }, [contentRef.current, isExpanded]);

  return (
    <>
      <tr onClick={toggleExpanded} className={`cursor-pointer transition-all duration-300 border-t ${isExpanded ? 'border-fadedDenim/50 bg-gray-200' : 'border-alabaster hover:bg-lightGrey'} group text-center`}>
        <td className="flex items-center gap-4 font-medium text-left py-[14px] pl-6 pr-2 max-w-[75%]">
          <div className="flex flex-shrink-0 h-6 w-6 text-white font-medium text-[10px] leading-[16px] items-center justify-center rounded-full bg-blue-500">
            {Abbreviator(assessment?.name?.toUpperCase() ?? '')}
          </div>
          {assessment?.name}
        </td>

        <td className="text-left px-2">
          {assessment?.studentEmail}
        </td>

        <td className="pl-16 pr-6 text-right font-medium">
          <button>
            <ChevronRightIcon className={`h-5 transition-all duration-300 ${isExpanded ? 'rotate-90' : ''}`} />
          </button>
        </td>
      </tr>

      <tr>
        <td
          className={`transition-all duration-300 ${isExpanded ? 'border-b border-b-fadedDenim/50' : ''}`}
          colSpan={3}
        >
          <div
            style={{
              height: isExpanded ? `${contentHeight}px` : '0px',
              overflow: 'hidden',
              transition: 'height 300ms ease'
            }}
            ref={contentRef}
          >
            <CompletedResultsTable results={historyOfResults} />
          </div>
        </td>
      </tr>
    </>
  );
}

export const EducatorCompletedAssessments = ({ searchParam }) => {
  const [filteredResults, setFilteredResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { studentsResults } = assessmentStore();

  const completedAssessments = useMemo(
    () => {
      const completed = studentsResults?.filter(result => result?.isComplete) ?? [];
      return Array
        .from(new Set(completed.map(result => result.studentEmail)))
        .map((studentEmail) => {
          const revisions = completed
            .filter((result) => result.studentEmail === studentEmail)
            .toSorted(createSortingByLatestFirstCallback((revision) => revision?.invitedDate));
          return {
            ...revisions[0],
            revisions: revisions
          };
        })
        .toSorted(createSortingByLatestFirstCallback((student) => student?.revisions?.[0]?.invitedDate));
    },
    [studentsResults]
  );

  const resultsPerPage = useMemo(() => {
    const firstPageIndex  = RESULTS_PER_PAGE * (currentPage - 1);
    const lastPageIndex  = RESULTS_PER_PAGE * currentPage - 1;
    return filteredResults?.filter(
      (_, index) => index >= firstPageIndex && index <= lastPageIndex) ?? [];
  }, [filteredResults, currentPage]);

  const totalPages = useMemo(() => {
    const pagesNumber = Math.ceil((filteredResults?.length ?? 0) / RESULTS_PER_PAGE);
    return pagesNumber > 0 ? pagesNumber : 1;
  }, [filteredResults?.length]);

  useEffect(() => {
    const newFilteredResults = completedAssessments.filter((result) => result.studentEmail.toLowerCase().includes(searchParam.toLowerCase()));

    if (newFilteredResults.length !== completedAssessments.length) {
      setCurrentPage(1);
    }

    setFilteredResults((prev) => {
      if (newFilteredResults.length !== prev.length) {
        setCurrentPage(1);
      }

      return newFilteredResults;
    });
  }, [searchParam, completedAssessments]);

  return (
    <>
      {resultsPerPage.length ? (
        <div className="overflow-auto relative scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md w-full mb-6">
          <table className="min-w-full">
            <thead>
            <tr className="text-center text-fadedDenim pb-2 text-xs">
              <th className="w-2/5 text-left pl-6 py-[14px] pr-2 font-normal">
                Name
              </th>
              <th className="w-2/5 px-2 font-normal text-center lg:text-left">
                Email address
              </th>
              <th className="w-1/5 pr-6 pl-2 font-normal text-center py-[14px]" />
            </tr>
            </thead>

            <tbody className="text-sm text-right text-black">
            {resultsPerPage.map((assessment) => (
              <CompletedAssessmentTableRow
                key={assessment?.studentEmail}
                assessment={assessment}
              />
            ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-xl text-slate-500 text-center p-4 md:p-6 w-full mb-7">
          No data
        </div>
      )}

      <Pagination
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
}