import { api } from "../../helpers/apiHelper";
import { ACTIVE_URL } from "../constants";

export const fetchBestScores = () => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/report/bestscore`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchImprovement = () => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/assessiv/improvement`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEducatorStudentsCount = () => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/users/count`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const getEducatorStudentsCompletedInAWeekCount = () => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/educator/assessiv/complete`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEducatorStudentsLoggedInAWeekCount = () => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/educator/student/loggedIn`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEducatorStudentsCompletionRate = () => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/educator/assessiv/completion-rate`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEducatorStatistics = () => {
  return new Promise((resolve, reject) => {
    api
      .get(ACTIVE_URL + "/api/educator/assessiv/statistics")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEducatorLastLoginDetails = () => {
  return new Promise((resolve, reject) => {
    api
      .get(ACTIVE_URL + "/api/users/lastlogins")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchHierarchy = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`${ACTIVE_URL}/api/hierarchy`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};