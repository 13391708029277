import { useState } from "react";
import { TrashIcon } from "@heroicons/react/24/solid";
import { COLOR_VARIANTS, ConfirmationModal } from "./ConfirmationModal";

export const RemoveFromInvitedListModal = ({ handleRemoveUser }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = () => setIsModalVisible(true);

  const handleCloseModal = () => setIsModalVisible(false);

  const handleConfirmDeletion = () => {
    handleRemoveUser();
    handleCloseModal();
  }

  return (
    <>
      <button
        className="flex items-center gap-1 p-1.5 rounded-full opacity-0 text-xs group-hover:opacity-100 invisible group-hover:visible hover:bg-red-200 active:bg-red-300"
        onClick={handleOpenModal}
      >
        <TrashIcon className="h-5 text-red-500" />
      </button>

      {isModalVisible && (
        <ConfirmationModal
          colorVariant={COLOR_VARIANTS.RED}
          description="This action will remove the user from the list. Are you sure you want to complete this action?"
          checkBoxText="Yes, I am sure."
          confirmButtonText="Remove"
          title="Remove user"
          handleConfirm={handleConfirmDeletion}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  );
}