import { useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuBar } from "../pages/dashboard/components/utilityComponents";
import { loginStore, logoutStore } from "../store";
import { getRole, getRoleDisplayName, isStudent } from "../helpers/authentication.ts";
import { Notifications } from "./Notifications";
import { PlaceholderLogo } from "./PlaceholderLogo";

export const Header = ({ onClickLogo }) => {
  const navigate = useNavigate();
  const { fullName, email } = loginStore();
  const [headerHeight, setHeaderHeight] = useState(72);
  const headerRef = useRef(null);
  const role = getRole();

  const handleSignOut = () => {
    logoutStore.getState().handleSignOut(() => navigate('/login'));
  };

  const handleOnClickLogo = () => {
    onClickLogo?.();
    navigate('/dashboard');
  }

  useLayoutEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, []);

  return (
    <>
      <div ref={headerRef} className="flex px-4 sm:px-6 lg:px-8 shadow justify-between py-4 items-center bg-white fixed top-0 inset-x-0 z-50">
        <div className="flex flex-row items-center">
          <div className="flex items-stretch h-8 max-w-[112px] overflow-none cursor-pointer" onClick={handleOnClickLogo}>
            <PlaceholderLogo />
          </div>
          {!isStudent() && (
            <h2 className="flex items-center gap-2 ml-2 text-md text-neutral-400">| {getRoleDisplayName(role)}</h2>
          )}
        </div>
        <div className="flex flex-row gap-4">
          <Notifications />

          <div className="flex flex-row items-center gap-4">
            <MenuBar
              email={email}
              fullName={fullName}
              btnSignOut={handleSignOut}
            />
          </div>
        </div>
      </div>

      <div style={{ height: headerHeight }} className="w-full flex-shrink-0" />
    </>
  );
}