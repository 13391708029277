import { useEffect } from "react";
import { ReactComponent as BatmanIcon } from "../../../../../../assets/studentPage/batman.svg";
import { ReactComponent as TrophyIcon } from "../../../../../../assets/studentPage/trophy.svg";
import algebraIcon from "../../../../../../assets/studentPage/algebraIcon.png";
import writingIcon from "../../../../../../assets/studentPage/writingIcon.png";
import { fetchBestScores } from "../../../../../../actions/dashboard";
import { assessmentStore } from "../../../../../../store";

const ScoreLabel = ({ icon, text, value, total }) => {
  return (
    <div className="flex items-start gap-4 p-6 text-gray-500 bg-white border border-softCloud rounded-lg flex-1 min-w-[250px] lg:min-w-0 shadow-sm">
      {icon}
      <div className="flex flex-col justify-between gap-1 self-stretch">
        <div className="text-sm">{text}</div>
        <div className="text-xs">
          {!!value ? (
            <span className="text-black font-medium text-2xl">{value}</span>
          ) : (
            <div className="inline-block bg-winterMist rounded w-16 h-8 text-transparent font-medium text-2xl">.</div>
          )}
          &nbsp;/&nbsp;
          {total}
        </div>
      </div>
    </div>
  );
}

export const fetchStudentScores = () => {
  const { setBestScores } = assessmentStore.getState();

  fetchBestScores()
    .then((data) => {
      const scores = data?.data?.scores ?? [];
      const totalScore = scores?.find((score) => score?.name === 'Total');
      const mathScore = scores?.find((score) => score?.name === 'Math');
      const readingAndWritingScore = scores?.find((score) => score?.name === 'Reading and Writing');

      setBestScores?.({
        total: totalScore?.markes,
        superScore: (typeof mathScore?.markes  === 'number' && typeof readingAndWritingScore?.markes) ? mathScore?.markes + readingAndWritingScore?.markes : undefined,
        math: mathScore?.markes,
        readingAndWriting: readingAndWritingScore?.markes
      });
    })
    .catch(console.log);
}

export const LessonsScore = () => {
  const {
    bestScores: {
      total = '-  ',
      superScore = '-  ',
      math = '-  ',
      readingAndWriting  = '-  '
    },
  } = assessmentStore();

  useEffect(() => {
    fetchStudentScores();
  }, []);

  return (
    <div className="flex flex-col md:flex-row md:flex-wrap lg:flex-nowrap items-stretch gap-6">
      <ScoreLabel
        icon={
          <div className="w-12 h-12 flex-shrink-0 p-2 rounded bg-winterMist">
            <TrophyIcon className="w-full h-full" />
          </div>
        }
        text="Best Single Score"
        value={total}
        total="1600"
      />

      <ScoreLabel
        icon={
          <div className="w-12 h-12 flex-shrink-0 p-2 rounded bg-winterMist">
            <BatmanIcon className="w-full h-full" />
          </div>
        }
        text="Super Score"
        value={superScore}
        total="1600"
      />

      <ScoreLabel
        icon={
          <img
            className="w-12 h-12 flex-shrink-0"
            src={writingIcon}
            alt="icon"
          />
        }
        text="Best R/W Score"
        value={readingAndWriting}
        total="800"
      />

      <ScoreLabel
        icon={
          <img
            className="w-12 h-12 flex-shrink-0"
            src={algebraIcon}
            alt="icon"
          />
        }
        text="Best Math Score"
        value={math}
        total="800"
      />
    </div>
  );
}