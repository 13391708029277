import "./App.css";

import { Routes, Route } from "react-router-dom";

import Dashboard from "./pages/dashboard";

// import AdminDashboard from "./pages/dashboard/admin";

import Landing from "./pages/landing";
import Login from "./pages/authentication/login";
import LoginImpersonate from "./pages/authentication/loginImpersonate";
import Register from "./pages/authentication/register";
import Reset from "./pages/authentication/reset";
import Verify from "./pages/verify";
import VerifyEmail from "./pages/authentication/verifyemail";
import AssessmentPlatform from "./pages/assessmentPlatform";
import AssessmentResults from "./pages/assessmentResults";
import PrivateRoute from "./routes/privateroute";
import NoAuthRoute from "./routes/NoAuthRoute";
import { EducatorsAssessivInvite } from "./pages/inviteUsers/inviteEducator";
import { StudentsAssessivInvite } from "./pages/inviteUsers/inviteStudent";
import { AssignStudent } from "./pages/inviteUsers/assignStudent";
import { Settings } from "./pages/settings";

import mixpanel from "mixpanel-browser";
import { Toaster } from "react-hot-toast";
import { Organization } from "./pages/organization";
import AssessmentStart from "./pages/assessmentStart";
import { Plan } from "./pages/plan";
import { CookiesPolicy, EULA, Privacy, Terms } from "./pages/policies";
mixpanel.init("13ec4adeee71000cec36b81acec48818", { debug: true });

function App() {
  return (
    <>
      <Toaster />
      <Routes>
        <Route index element={<Landing />} />
        {/* If there is no token value don't route to dashboard. */}
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/EULA" element={<EULA />} />
        <Route path="/cookies-policy" element={<CookiesPolicy />} />
        <Route exact path="/dashboard" element={<PrivateRoute />}>
          <Route exact path="/dashboard" element={<Dashboard />}>
            <Route path=":screen" element={<Dashboard />} />
          </Route>
          <Route path="/dashboard/assessment" element={<AssessmentPlatform />}></Route>
          <Route path="/dashboard/assessment/results" element={<AssessmentResults />}>
            <Route path=":ref" element={<AssessmentResults />} />
          </Route>
        </Route>
        <Route path="/assessment/start" element={<AssessmentStart />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/assessiv/invite" element={<AssignStudent />} />
        <Route path="/invite/educator" element={<EducatorsAssessivInvite />} />
        <Route path="/invite/student" element={<StudentsAssessivInvite />} />
        <Route path="/organization" element={<Organization />} />
        <Route path="/plan" element={<Plan />} />
        {/* <Route path="admin" element={<AdminDashboard />} /> */}
        <Route path="assessment" element={<AssessmentPlatform />} />
        <Route exact path="/verify">
          <Route path=":id" element={<Verify />} />
        </Route>
        <Route path="/verifyemail" element={<VerifyEmail />} />
        <Route path="login/impersonate" element={<LoginImpersonate />} />
        <Route element={<NoAuthRoute />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="reset" element={<Reset />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
