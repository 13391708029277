import { ReactComponent as Arrow } from "../../../../assets/rt-arrow-white.svg";

export const PrimaryButton = ({ children, className = '', ...buttonProps }) => {
  return (
    <button
      {...buttonProps}
      className={`w-full flex items-center justify-between gap-1 px-5 py-3 rounded-lg bg-skyBlue hover:bg-blue-600 transition text-base text-white ${className}`}
    >
      {children}
      <Arrow className="w-6 h-6" />
    </button>
  );
}