import { SafeHTML } from "../../../../../helpers/safeHtml";

const Alphabets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

const getCorrectAnswerContent = (answer) => {
  if (answer.isGridIn) {
    return answer.correctValue?.[0];
  }

  const letterIndex = isNaN(+(answer.correctValueIndex ?? '')) ? 0 : (+answer.correctValueIndex - 1);
  return Alphabets[letterIndex];
}

export const CorrectAnswer = ({ answer }) => {

  const answerContent = getCorrectAnswerContent(answer);

  return (
    <div className="px-4 m-1 rounded">
      <SafeHTML html={answerContent} />
    </div>
  );
}