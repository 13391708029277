import { Select } from "./Select";

export const generatePlanGroupOptions = (userEmailDomain) => [
  `${userEmailDomain} (Entire Organization)`,
  `${userEmailDomain} (Group 1)`,
  `${userEmailDomain} (Group 2)`,
  `${userEmailDomain} (Group 3)`,
];

export const PlanGroupSelect = ({
  planGroup,
  setPlanGroup,
  userEmailDomain,
}) => {
  const options = generatePlanGroupOptions(userEmailDomain);

  return (
    <Select
      containerClassName="flex-1"
      title="Plan group"
      selectedOption={planGroup}
      onSelectOption={setPlanGroup}
      options={options}
    />
  );
}