import { useState } from "react";
import toast from "react-hot-toast";
import { PasswordInput } from "../../../../components/PasswordInput";
import { changePassword } from "../../../../actions/registration";
import { loginStore } from "../../../../store";

export const PasswordEdit = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handleChangePassword = (event) => {
    event.preventDefault();

    const { email } = loginStore.getState();
    if (!email) {
      return;
    }

    if (newPassword === confirmNewPassword) {
      changePassword({ username: email, password: newPassword })
        .then(() => {
          setConfirmNewPassword('');
          setNewPassword('');
          toast.success('Password has been successfully changed.');
        })
        .catch((error) => {
          console.log(error)
          toast.error(error?.response?.data?.message ?? 'Error when changing password.')
        });
    } else {
      toast.error('The entered passwords does not match.');
    }
  }

  return (
    <form className="flex flex-col gap-3 items-stretch" onSubmit={handleChangePassword}>
      <h2 className="font-semibold text-sm text-gray-700 self-start">
        Change Password
      </h2>

      <div className="flex items-stretch gap-2">
        <PasswordInput
          required
          wrapperClasName="flex-1"
          className="flex h-9 w-full rounded-md border border-slate-300 bg-transparent px-3 py-1 text-sm shadow-sm placeholder:text-slate-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
          placeholder="New password"
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
        />

        <PasswordInput
          required
          wrapperClasName="flex-1"
          className="flex h-9 w-full rounded-md border border-slate-300 bg-transparent px-3 py-1 text-sm shadow-sm placeholder:text-slate-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
          placeholder="Confirm new password"
          value={confirmNewPassword}
          onChange={(event) => setConfirmNewPassword(event.target.value)}
        />

        <button
          type="submit"
          disabled={!(newPassword.length && confirmNewPassword.length)}
          className="w-max inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition disabled:pointer-events-none disabled:opacity-50 bg-blue-500 hover:bg-blue-600 text-white disabled:text-gray-500 disabled:bg-gray-300 h-9 px-4 py-2"
        >
          Submit
        </button>
      </div>
    </form>
  );
}