import { api } from "../../helpers/apiHelper";
import { ACTIVE_URL } from "../constants";

export const fetchGroups = () => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/groups`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createGroup = (name) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/groups`,
        { name },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateGroupActive = (groupId, status) => {
  return new Promise((resolve, reject) => {
    api
      .patch(
        `${ACTIVE_URL}/api/groups/${groupId}/${status}`,
        null,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteGroup = (groupId) => {
  return new Promise((resolve, reject) => {
    api
      .delete(
        `${ACTIVE_URL}/api/groups/${groupId}`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const assignUserToGroup = (groupId, userId) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/users/${userId}/groups/${groupId}`,
        null,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteAssignUserToGroup = (groupId, userId) => {
  return new Promise((resolve, reject) => {
    api
      .delete(
        `${ACTIVE_URL}/api/users/${userId}/groups/${groupId}`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};