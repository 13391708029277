import Lottie from "react-lottie";
import ReportsAnimation from "../../../assets/animations/businessReport.json";
import { assessmentStore, loginStore } from "../../../store";
import { useNavigate } from "react-router-dom";
import { fetchUserAnswerVersions, getResult } from "../../../actions/assessment";

function ResultsReady() {
  const { setStudentEmailResult, resetResults, assessmentId, assessmentVersionId, educatorId, setStudentAnswerVersionResult } = assessmentStore();
  const { email } = loginStore();
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ReportsAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleViewResults = (result) => {
    fetchUserAnswerVersions({ assessivId: assessmentId, assessivVersionId: assessmentVersionId, educatorId })
      .then((data) => {
        const latest = data?.data?.reduce?.((latest, current) => {
          if (!latest.createdDate) {
            return current;
          }

          return new Date(current.createdDate) > new Date(latest.createdDate) ? current : latest;
        }, {});
        const latestVersion = latest?.versionId;
        const latestReportId = latest?.resultId;

        if (latestVersion !== undefined) {
          Promise.all([
            setStudentAnswerVersionResult(latestVersion),
            setStudentEmailResult(email),
            resetResults()
          ])
          .then(() => {
            if (!latestReportId || latestReportId === '00000000-0000-0000-0000-000000000000') {
              getResult({
                assessivId: assessmentId,
                assessivVersionId: assessmentVersionId,
                educatorId: educatorId,
                studentEmail: email,
                answerVersion: latestVersion
              }).then((res) => {
                if (res?.data?.resultId) {
                  navigate(`/dashboard/assessment/results?reportId=${res?.data?.resultId}`);
                }
              })
            } else {
              const queryParams = new URLSearchParams({ reportId: latestReportId }).toString();
              navigate(`/dashboard/assessment/results?${queryParams}`);
            }
          });
        }
    });
  }

  return (
    <div className="absolute flex items-center justify-center h-full w-full z-20 backdrop-blur-md">
      <div className="flex flex-col justify-center items-center">
        <Lottie options={defaultOptions} height={200} width={200} />

        <span className="text-2xl font-semibold text-gray-700">Results are ready!</span>

        <button
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600"
          onClick={handleViewResults}
        >
          View Results
        </button>
      </div>
    </div>
  );
}

export { ResultsReady };
