import { useState } from "react";
import toast from "react-hot-toast";
import { ASSESSIV_PLAN, AssessivPlanSelect } from "./components/AssessivPlanSelect";
import { BILLING_PLAN, BillingPlanSelect } from "./components/BillingPlanSelect";
import { PricingSummary } from "./components/PricingSummary";
import { SeatsInput } from "./components/SeatsInput";
import { generatePlanGroupOptions, PlanGroupSelect } from "./components/PlanGroupSelect";
import { Header } from "../../components/Header";
import { loginStore } from "../../store";

export const Plan = () => {
  const email = loginStore((state) => state.email);
  const userEmailDomain = email?.split('@')?.[1] ?? '';
  const [asanaPlan, setAsanaPlan] = useState(ASSESSIV_PLAN.COMPANY_EARLY_BIRD);
  const [billingPlan, setBillingPlan] = useState(BILLING_PLAN.MONTHLY);
  const [seats, setSeats] = useState('10');
  const [planGroup, setPlanGroup] = useState(() => generatePlanGroupOptions(userEmailDomain)[0]);

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.success('Your tariff plan has been updated.');
  };

  return (
    <div className="App-header flex flex-col bg-slate-200">
      <Header />

      <div className="flex items-start justify-center flex-1 bg-slate-200 z-0">
        <div className="p-10 bg-white shadow-md rounded-2xl border-gray-300 mt-10">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-2xl text-black font-bold mb-4">Change plan details</h2>

            <p className="text-gray-600 mb-6">{userEmailDomain} is using 8 seats in a free plan.</p>

            <form className="flex flex-col" onSubmit={handleSubmit}>
              <div className="flex items-start gap-10">
                <div className="flex flex-col gap-6">
                  <AssessivPlanSelect
                    assessivPlan={asanaPlan}
                    setAssessivPlan={setAsanaPlan}
                  />

                  <div className="flex gap-6">
                    <BillingPlanSelect
                      billingPlan={billingPlan}
                      setBillingPlan={setBillingPlan}
                    />

                    <SeatsInput
                      seats={seats}
                      setSeats={setSeats}
                    />
                  </div>

                  <PlanGroupSelect
                    planGroup={planGroup}
                    setPlanGroup={setPlanGroup}
                    userEmailDomain={userEmailDomain}
                  />

                  <p className="mt-2 text-sm text-gray-500">
                    All members with {userEmailDomain} email addresses will be able to automatically join your
                    organization.
                  </p>
                </div>

                <PricingSummary
                  assessivPlan={asanaPlan}
                  billingPlan={billingPlan}
                  seats={seats}
                />
              </div>

              <div className="flex justify-end">
                <button
                  disabled={+seats <= 0}
                  type="submit"
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-skyBlue disabled:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skyBlue"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}