export const formatDateStatus = (dateString: string): string => {
    const inputDate = new Date(dateString).getTime();
    const currentDate = Date.now();
    const millisecondsInSeconds = 1000;
    const secondsInMinute = 60;
    const minutesInHour = 60;
    const hoursInDay = 24;

    const timeDifferenceInHours = (inputDate - currentDate) / (millisecondsInSeconds * secondsInMinute * minutesInHour);

    if (timeDifferenceInHours >= hoursInDay) {
        const daysRemaining = Math.floor(timeDifferenceInHours / hoursInDay);
        const daysString = `${daysRemaining > 90 ? '90+' : daysRemaining}`;
        return `due in ${daysString} days`;
    } else if (timeDifferenceInHours > 1) {
        return `due in ${Math.floor(timeDifferenceInHours)} hours`;
    } else if (timeDifferenceInHours >= 0) {
        return `due in 1 hour`;
    } else if (timeDifferenceInHours > -24) {
        return `due ${Math.abs(Math.floor(timeDifferenceInHours))} hours ago`;
    } else {
        const daysAgo = Math.abs(Math.floor(timeDifferenceInHours / hoursInDay));
        const daysString = `${daysAgo > 90 ? '90+' : daysAgo}`;
        return `due ${daysString} days ago`;
    }
}

export const formatToUSTime = (date: Date) => {
    return date.toLocaleTimeString(
        'en-US',
        {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        }
    );
}

export const formatToUSDate = (dateString: string, showPlugOnInvalidDate: boolean = true): string => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        return showPlugOnInvalidDate ? 'Invalid date' : undefined;
    }

    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
}

export const formatTime = (seconds = 0) => {
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = [minutes, remainingSeconds].map(
        (unit) => unit < 10 ? `0${unit}` : unit).join(':');

    return formattedTime;
}

export const formatDateToTimeAgo = (dateString: string) => {
  if (typeof dateString !== 'string' || !dateString.length) {
    return null;
  }

  const date = new Date(dateString);
  const now = new Date();

  const localTimeOffset = now.getTimezoneOffset() * 60000;

  const secondsAgo = Math.round((now.getTime() - date.getTime() + localTimeOffset) / 1000);
  const minutesAgo = Math.round(secondsAgo / 60);
  const hoursAgo = Math.round(minutesAgo / 60);
  const daysAgo = Math.round(hoursAgo / 24);

  if (daysAgo >= 90) return "90+ days ago";
  else if (daysAgo >= 1) return `${daysAgo} day${daysAgo === 1 ? '' : 's'} ago`;
  else if (hoursAgo >= 1) return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
  else if (minutesAgo >= 1) return `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago`;
  else return `${secondsAgo} second${secondsAgo === 1 ? '' : 's'} ago`;
};

export const formatDateWithDynamicTimeZone = (dateString: string) => {
  if (dateString === '0001-01-01T00:00:00') {
    return '1/1/1111';
  }

  const date = new Date(dateString);

  const userOffsetMinutes = date.getTimezoneOffset();

  const userOffsetMilliseconds = userOffsetMinutes * 60 * 1000;

  const userDate = new Date(date.getTime() - userOffsetMilliseconds);

  return userDate.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
}

export function parseDate(value: string | Date | undefined): Date | null {
  if (value instanceof Date) return value;
  if (typeof value === 'string') {
    const date = new Date(value);
    return isNaN(date.getTime()) ? null : date;
  }
  return null;
}

export function createSortingByLatestFirstCallback<T>(
  dateGetter: (item: T) => string | Date | undefined
): (firstItem: T, secondItem: T) => number {

  return (firstItem: T, secondItem: T): number => {
    const firstDate = parseDate(dateGetter(firstItem));
    const secondDate = parseDate(dateGetter(secondItem));

    if (firstDate && secondDate) {
      return secondDate.getTime() - firstDate.getTime();
    }

    if (!firstDate && !secondDate) return 0;
    if (!firstDate) return 1;
    if (!secondDate) return -1;

    return 0;
  };
}