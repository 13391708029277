import { useEffect, useState } from "react";
import sampleLogo from "../assets/assessiv_logo_back.png";

export const ImagePlaceholder = ({ src }) => {
  const [imageSrc, setImageSrc] = useState(src);

  const handleImageError = () => {
    setImageSrc(sampleLogo);
  };

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  return (
    <img
      src={imageSrc ? imageSrc : sampleLogo}
      className="h-auto max-w-full max-h-[62px]"
      alt="logo"
      onError={handleImageError}
    />
  );

}