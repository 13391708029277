import { useState } from "react";
import { ClipboardDocumentCheckIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { ReactComponent as Logo } from "../../../assets/logo_blue.svg";
import { AnimIn } from "../../../components/mountAnim";

import Lottie from "react-lottie";
import newMailAnim from "../../../assets/animations/newMail.json";
import Loader from "../../../assets/animations/circlesLoader.json";

import {
  checkUserExists,
  getRegistrationToken,
  getRoles,
  sendSignupEmail
} from "../../../actions/registration";

import {useNavigate, useSearchParams} from "react-router-dom";

import happyStudent from "../../../assets/happy_student.jpeg";

import toast from "react-hot-toast";
import { RadioGroup } from "@headlessui/react";
import { PasswordInput } from "../../../components/PasswordInput";
import { NavigateToLandingLink } from "../components/NavigateToLandingLink";
import { ROLE } from "../../../helpers/authentication.ts";
import {UserTypeSelector} from "./components/UserTypeSelector";

//style definition
const buttonStyle = "block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10 px-4";

const getRegistrationTokenService = async (email, selectedRole) => {
  const roles = await getRoles();
  const roleId = roles?.find(role => role?.role?.toLowerCase() === selectedRole?.toLowerCase())?.roleId;

  if (roleId) {
    const currentDate = new Date();
    const expirationDate = new Date(currentDate);
    expirationDate.setDate(currentDate.getDate() + 7);

    const response = await getRegistrationToken({
      email,
      roleId,
      expiration: expirationDate.toISOString()
    });

    return response?.data;
  }
}

const REGISTRATION_STEP = {
  ROLE: 'role',
  CREDENTIALS: 'credentials',
};

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: newMailAnim,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const allowedRoles = [ROLE.SUPER_ADMIN_EDUCATOR, 'student'];

const getRegistrationRole = (registrationRole) => () => {
  if (registrationRole === 'company') {
    return ROLE.SUPER_ADMIN_EDUCATOR;
  }

  if (allowedRoles.includes(registrationRole)) {
    return registrationRole;
  }

  return '';
}

export default function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const registrationEmail = searchParams.get('email');
  const registrationToken = searchParams.get('token');
  const registrationRole = searchParams.get('role');
  const isInviteRegistration = [registrationEmail, registrationToken, registrationRole].every(item => !!item?.length);

  const [step, setStep] = useState(isInviteRegistration ? REGISTRATION_STEP.CREDENTIALS : REGISTRATION_STEP.ROLE);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [sentEmail, setSentEmail] = useState('');
  const [userType, setUserType] = useState(getRegistrationRole(registrationRole));

  const navigateToCredentialsStep = (event) => {
    event.preventDefault();
    setStep(REGISTRATION_STEP.CREDENTIALS);
  }

  const navigateToRoleStep = (event) => {
    event.preventDefault();
    setStep(REGISTRATION_STEP.ROLE);
  }

  async function handleRegSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    const targetVal = event.target;
    const passwordLowerCaseRegex = /(?=.*[a-z])/;
    const passwordUpperCaseCaseRegex = /(?=.*[A-Z])/;
    const containsDigitRegex = /(?=.*\d)/;
    let token = registrationToken;

    if (targetVal.password.value.length < 6) {
      toast.error('Passwords must be at least 6 characters.');
      setIsLoading(false);
      return;
    } else if (!passwordLowerCaseRegex.test(targetVal.password.value)) {
      toast.error('Passwords must have at least one lowercase (\'a\'-\'z\').');
      setIsLoading(false);
      return;
    } else if (!passwordUpperCaseCaseRegex.test(targetVal.password.value)) {
      toast.error('Passwords must have at least one uppercase (\'A\'-\'Z\').');
      setIsLoading(false);
      return;
    } else if (!containsDigitRegex.test(targetVal.password.value)) {
      toast.error('Passwords must have at least one digit (\'0\'-\'9\').');
      setIsLoading(false);
      return;
    } else if (targetVal.password.value !== targetVal.confirmPassword.value) {
      toast.error('Passwords do not match.');
      setIsLoading(false);
      return;
    }

    if (userType === 'student') {
      if (!targetVal.firstName?.value?.length) {
        toast.error('Enter firstname.');
        setIsLoading(false);
        return;
      } else if (!targetVal.lastName?.value?.length) {
        toast.error('Enter lastname.');
        setIsLoading(false);
        return;
      }
    } else {
      if (!targetVal.companyName?.value?.length) {
        toast.error('Enter Company name.');
        setIsLoading(false);
        return;
      }
    }

    if (!token) {
      try {
        const response = await getRegistrationTokenService(targetVal.email.value, userType);
        if (response?.length) {
          token = response;
        } else {
          setIsLoading(false);
          return;
        }
      } catch (error) {
        if (error?.response?.data?.message && typeof error?.response?.data?.message === 'string') {
          toast.error(error.response.data.message);
        }
        setIsLoading(false);
        return;
      }
    }

    const postData = {
      email: targetVal.email.value,
      password: targetVal.password.value,
      companyName: targetVal?.companyName?.value,
      firstName: targetVal.firstName.value,
      lastName: targetVal.lastName.value,
      role: userType,
      token
    };

    checkUserExists(postData.email)
      .then((data) => {
        if (data.data) {
          toast.error('User exists');
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data?.errorCode === 'User doesn\'t exist') {
          sendSignupEmail(postData)
            .then(() => {
              setSentEmail(postData.email);
              setIsEmailSent(true);
            })
            .catch(() => {
              toast.error('Internal error.');
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
        }
      });
  }

  const copySentEmailToClipboard = () => {
    navigator.clipboard.writeText(sentEmail)
      .catch((error) => {
        console.error(error);
      });
  }

  const navigateToLoginPage = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate('/login');
  }

  return (
    <>
      <div className="flex flex-row h-screen w-screen">
        <div className="flex flex-col justify-between items-center lg:items-start h-full p-[2em] md:p-14 bg-white lg:w-[33em] w-full">
          <NavigateToLandingLink className="flex-shrink-0">
            <Logo className="w-93 h-24" />
          </NavigateToLandingLink>

          <AnimIn isVisible={true} className="w-full">
            <form className="w-full" onSubmit={handleRegSubmit}>
              {isEmailSent ? (
                <>
                  <Lottie options={defaultOptions} height={200} width={200} autoplay />
                  <label htmlFor="email" className="block text-2xl font-medium text-gray-700 text-center">
                    Please check your email
                  </label>
                  <p className="text-sm text-gray-500 mt-12 text-center leading-9">
                    We have sent you a verification link to &nbsp;
                    <span className="inline-flex items-center gap-2 p-1 px-2 bg-gray-100 rounded-md">
                      {sentEmail}
                      <button
                        className="hover:bg-gray-200 active:bg-gray-300 p-1 rounded"
                        onClick={copySentEmailToClipboard}
                      >
                        <ClipboardDocumentCheckIcon className="h-5" />
                      </button>
                    </span>
                  </p>
                </>
              ) : (
                <>
                  <div className="mt-32 flex items-center gap-2">
                    {step === REGISTRATION_STEP.CREDENTIALS && (
                      <button
                        type="button"
                        className="flex items-center justify-center w-8 h-8 rounded-md transition hover:bg-black/5 active:bg-black/10"
                        onClick={navigateToRoleStep}
                      >
                        <ArrowLeftIcon className="h-4"/>
                      </button>
                    )}
                    <label className="block text-2xl font-medium text-gray-700 text-center md:text-start">
                      Create an account
                    </label>
                  </div>
                  {step === REGISTRATION_STEP.ROLE ? (
                    <div className={registrationToken ? 'pointer-events-none' : ''}>
                    <UserTypeSelector userType={userType} setUserType={setUserType} />
                    </div>
                  ) : (
                    <>
                      {userType === ROLE.SUPER_ADMIN_EDUCATOR && (
                        <div className="mt-32">
                          <input
                            required
                            name="companyName"
                            placeholder="Company name"
                            className={buttonStyle}
                          />
                        </div>
                      )}
                      <div className="mt-32 flex flex-row gap-4">
                        <input
                          required
                          name="firstName"
                          autoComplete="given-name"
                          placeholder="First Name"
                          className={buttonStyle}
                        />
                        <input
                          required
                          name="lastName"
                          autoComplete="family-name"
                          placeholder="Last Name"
                          className={buttonStyle}
                        />
                      </div>
                      <div className="mt-32">
                        <input
                          required
                          name="email"
                          value={registrationEmail ?? undefined}
                          disabled={!!registrationEmail}
                          placeholder="Email address"
                          className={buttonStyle}
                        />
                      </div>
                      <div className="mt-32">
                        <PasswordInput required name="password" placeholder="Password" className={buttonStyle}/>
                      </div>
                      <div className="mt-32">
                        <PasswordInput
                          required
                          name="confirmPassword"
                          placeholder="Confirm password"
                          className={buttonStyle}
                        />
                      </div>
                    </>
                  )}

                  {step === REGISTRATION_STEP.ROLE ? (
                    <button
                      type="button"
                      disabled={!allowedRoles.includes(userType)}
                      className="mt-32 bg-blue-700 hover:bg-blue-600 disabled:bg-gray-200 text-white font-bold py-2 px-4 rounded-lg w-full"
                      onClick={navigateToCredentialsStep}
                    >
                      <span className="py-2 px-4 text-md">Continue</span>
                    </button>
                  ) : (
                    <button
                      disabled={isLoading}
                      type="submit"
                      className="mt-32 bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg w-full"
                    >
                      {isLoading ?
                        <Lottie options={defaultOptions2} height={24} width={40} isStopped={false} isPaused={false}/> :
                        <span className="py-2 px-4 text-md">Sign up</span>}
                    </button>
                  )}

                  <div className="text-center mt-24">
                    <p className="text-sm text-gray-500 mt-12">Already have an account?</p>
                    <button
                      type="button"
                      className="text-sm font-bold text-blue-600 cursor-pointer"
                      onClick={navigateToLoginPage}
                    >
                      Login Here
                    </button>
                  </div>
                </>
              )}
            </form>
          </AnimIn>
          <p className="text-sm text-gray-500 mt-12">All rights reserved.</p>
        </div>
        <AnimIn isVisible={true} className="w-full flex flex-1">
          <img alt="" src={happyStudent} className="h-screen w-full bg-auto bg-no-repeat bg-center object-cover" />
        </AnimIn>
      </div>
    </>
  );
}
