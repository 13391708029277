export const convertUserIDtoUserName = (userID: string | undefined | null): string => {
    if (typeof userID !== 'string') {
        return '';
    }

    return userID?.split('_')?.[0] ?? userID;
}

export const capitalize = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}