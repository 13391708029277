import { useEffect, useState } from "react";
import { EducatorAssignedAssessments } from "./EducatorAssignedAssessments";
import { EducatorCompletedAssessments } from "./EducatorCompletedAssessments";
import { Tabs } from "../../../../../../../components/Tabs";
import { Search } from "../../../../../../../components/Search";
import { fetchStudentsResults } from "../../../../../../../actions/user";
import { assessmentStore } from "../../../../../../../store";
import { EDUCATOR_INTROJS_SELECTOR } from "../EducatorDashboardExcursion";
import { Skeleton } from "../../../../../../../components/Skeleton";

const TAB = {
  ASSIGNED_ASSESSMENTS: 'Assigned Assessments',
  COMPLETED_ASSESSMENTS: 'Completed Assessments',
};

const tabs = Object.values(TAB)
  .map((tab) => ({ id: tab, content: tab }));

export const StudentsManagement = () => {
  const { isFetchingStudentResults, setStudentsResults } = assessmentStore();
  const [currentTab, setCurrentTab] = useState(TAB.ASSIGNED_ASSESSMENTS);
  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchStudentsResults()
      .then(data => setStudentsResults(data?.data ?? []))
      .catch(() => setStudentsResults([]));
  }, []);

  const renderContent = () => {
    switch (currentTab) {
      case TAB.ASSIGNED_ASSESSMENTS: return <EducatorAssignedAssessments searchParam={search} />;
      case TAB.COMPLETED_ASSESSMENTS: return <EducatorCompletedAssessments searchParam={search} />;
      default: return <EducatorAssignedAssessments searchParam={search} />;
    }
  }

  return (
    <div className={`bg-white rounded-lg border border-softCloud p-6 shadow ${EDUCATOR_INTROJS_SELECTOR.ASSESSMENTS}`}>
      <div className="flex items-center justify-between gap-2 mb-4">
        <Tabs currentTabId={currentTab} onChangeTab={setCurrentTab} tabs={tabs} />

        <Search searchString={search} onChangeSearchString={setSearch} />
      </div>

      {isFetchingStudentResults ? (
        <div className="flex flex-col gap-1">
          {Array.from({ length: 3 }, (_, index) => (
            <Skeleton key={index} className="h-8 w-full" />
          ))}
        </div>
      ) : renderContent()}
    </div>
  );
}