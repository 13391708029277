import jwtDecode from "jwt-decode";
import toast from "react-hot-toast";
import { mx } from "./mixpanel.ts";
import { loginUser } from "../actions/registration";
import { loginStore } from "../store";

export const isUserAuthenticated = ():boolean => {
  const token = localStorage.getItem("applicationToken");

  if (token) {
    const decodedToken = jwtDecode(token);
    // IF TOKEN EXPIRES THEN REMOVE TOKEN FROM LOCAL STORAGE
    // if (decodedToken.exp * 1000 < Date.now()) {
    //   localStorage.removeItem("applicationToken");
    //   return false;
    // } else {
    //   return true;
    // }
    return true;
  } else {
    return false;
  }
};

export const ROLE = {
  STUDENT: 'Student',
  EDUCATOR: 'Educator',
  EDUCATOR_ADMIN: 'EducatorAdmin',
  SUPER_ADMIN_EDUCATOR: 'SuperAdminEducator',
}

export const getAccessTokenFromLocalStorage = (): string | null => {
  return localStorage.getItem("applicationToken");
};

export const getRole = () => {
  try {
    const loginData: { actort?: string } = jwtDecode(getAccessTokenFromLocalStorage());
    return loginData?.actort;
  } catch (error) {
    return '';
  }
}

export const getRoleDisplayName = (role: string) => {
  if (typeof role !== 'string') return '';

  const roleSignByRole = {
    [ROLE.EDUCATOR]: 'Educator',
    [ROLE.EDUCATOR_ADMIN]: 'Admin Educator',
    [ROLE.SUPER_ADMIN_EDUCATOR]: 'Owner Educator',
  };

  return roleSignByRole[role] ?? role;
}

export const isEducator = (): boolean => {
  return ROLE.EDUCATOR === getRole();
}

export const isAdminEducator = (): boolean => {
  return [ROLE.EDUCATOR_ADMIN, ROLE.SUPER_ADMIN_EDUCATOR].includes(getRole());
}

export const isOwnerEducator = (): boolean => {
  return ROLE.SUPER_ADMIN_EDUCATOR === getRole();
}

export const isStudent = (): boolean => {
  return ROLE.STUDENT === getRole();
}

export const login = (postData, navigate) => {
  const allowedRoles = [ROLE.STUDENT, ROLE.EDUCATOR, ROLE.EDUCATOR_ADMIN, ROLE.SUPER_ADMIN_EDUCATOR];
  const { setFullName, setEmail, setId, setApplicationToken, setRefreshToken, setExpireAt, storeToLocal } = loginStore.getState();

  return loginUser(postData)
      .then((res) => {
        const { nonce, actort: role } = (jwtDecode(res.data.token.accessToken ?? '') ?? {}) as { nonce?: string; actort?: string; };
        if (!!role && !allowedRoles.includes(role)) {
          toast.error('You can\'t login with this account.');
          return;
        }
        toast.success("Login Successful");
        mx.identify(res.data.email);
        mx.people.set({
          $email: res.data.email,
          $name: res.data.fullName,
        });
        setId(nonce ?? '');
        setFullName(res.data.fullName);
        setEmail(res.data.email);
        setApplicationToken(res.data.token.accessToken);
        setRefreshToken(res.data.token.refreshToken.tokenString);
        setExpireAt(res.data.token.refreshToken.expireAt);
        storeToLocal({
            accessToken: res.data.token.accessToken,
            refreshToken: res.data.token.refreshToken.tokenString,
            expireAt: res.data.token.refreshToken.expireAt
        });
        if (navigate) {
            navigate('/dashboard');
        } else {
            window.location.href = "/dashboard";
        }
      })
      .catch((err) => {
        toast.error(JSON.parse(err.request.response).message);
      })
}