import { getResultByResultId } from "../../actions/report";
import { assessmentStore } from "../../store";

export const orderSubjects = (subjects = []) => {
  return [...subjects].sort((firstSubject, secondSubject) => {
    if (firstSubject.name === "Total") {
      return -1;
    } else if (secondSubject.name === "Total") {
      return 1;
    } else if (firstSubject.name === "Reading and Writing") {
      return -1;
    } else if (secondSubject.name === "Reading and Writing") {
      return 1;
    } else {
      return 0;
    }
  });
}

export const formatAssessivSettings = (settings = {}) => {
  return Object
    .entries(settings)
    .map(([name, value]) => ({ name, value }));
}

export const fetchReport = () => {
  const { getResults, setResults } = assessmentStore.getState();

  const urlParams = new URLSearchParams(window.location.search);
  const reportId = urlParams.get('reportId');
  const assessivId = urlParams.get('assessivId');
  const assessivVersionId = urlParams.get('assessivVersionId');
  const educatorId = urlParams.get('educatorId');
  const studentEmail = urlParams.get('studentEmail');
  const answerVersion = urlParams.get('answerVersion');

  if (reportId) {
    return getResultByResultId(reportId)
      .then((res) => {
        setResults(res.data ?? null);
      });
  }

  if (assessivId && assessivVersionId && educatorId && studentEmail && (answerVersion !== undefined && answerVersion !== null)) {
    return getResults({
      assessivId,
      assessivVersionId,
      educatorId,
      studentEmail,
      answerVersion
    })
      .then((res) => {
        console.log("Got Results...", res.data);
        setResults(res.data ?? null);
      });
  }

  return Promise.reject(new Error('Required URL parameters are missing'));
};