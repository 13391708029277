import Chart from "react-apexcharts";

const defaultChartData = [
  {
    name: "Series 1",
    data: [10, 45, 40, 9],
  },
];

export const ChartSpline = ({
  chartData,
  categories,
  tickAmount,
  studentPosition,
  subject,
}) => {
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      height: "100%",
      type: "area",
    },
    stroke: {
      width: 2,
      curve: "smooth",
      colors: ["black"],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      tickAmount: tickAmount,
      labels: {
        hideOverlappingLabels: true,
      },
    },
    yaxis: {
      categories: [0, 20, 40, 60, 80, 100],
    },
    annotations: {
      yaxis: [
        {
          y: studentPosition ? studentPosition.percentile : 0,
          borderColor: "#2698FF",
          label: {
            borderColor: "#2698FF",
            style: {
              color: "#fff",
              background: "#2698FF",
            },
            text: "You",
          },
        },
      ],
      points: [
        {
          x: studentPosition ? studentPosition.score : 0,
          y: studentPosition ? studentPosition.percentile : 0,
          marker: {
            size: 5,
            fillColor: "#2698FF",
            strokeColor: "white",
            radius: 2,
          },
          label: {
            borderColor: "#2698FF",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#2698FF",
            },
          },
        },
      ],
    },
  };

  return (
    <div className="h-full w-full">
      <div className="row">
        <div className="flex flex-col ml-4 mt-2">
          <span className="text-sm font-semibold">
            {studentPosition.score} {subject}
          </span>
          <span className="text-xs text-gray-400">{studentPosition.percentile} Percentile</span>
        </div>
        <div className="mixed-chart">
          <Chart
            options={options}
            series={chartData ? chartData : defaultChartData}
            type="line"
            width="236"
            height="236"
          />
        </div>
      </div>
    </div>
  );
};
