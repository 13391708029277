export function createDebounceFunction(func: Function, delay = 1000): Function {
    let timerId;

    return function(...args) {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

export const filterItemsByPage = (items, itemsPerPage, currentPage) => {
    const firstPageIndex  = itemsPerPage * (currentPage - 1);
    const lastPageIndex  = itemsPerPage * currentPage - 1;
    return items?.filter(
      (_, index) => index >= firstPageIndex && index <= lastPageIndex) ?? [];
}