import { Circle } from "./Circle";
import pIcon from "../../../../../../../assets/studentPage/p-icon.png";
import comprassIcon from "../../../../../../../assets/studentPage/comprass.png";
import calculatorIcon from "../../../../../../../assets/studentPage/calculator.png";
import unicornIcon from "../../../../../../../assets/studentPage/unicorn.png";

const getIconByIndex = (index = 0) => {
  const arrayOfIcons = [pIcon, comprassIcon, calculatorIcon, unicornIcon];
  if (index < 4) {
    return arrayOfIcons[index];
  } else {
    let resultIndex = index;
    while (resultIndex >= 4) {
      resultIndex -= 4;
    }
    return arrayOfIcons[resultIndex];
  }
}

const getIcon = (areaName, index) => {
  const iconByName = {
    'Information of Ideas': unicornIcon,
    'Advanced math': pIcon,
    'Algebra': calculatorIcon,
    'Problem-Solving and Data Analysis': comprassIcon,
    'Craft and Structure': calculatorIcon,
    'Expression of Ideas': unicornIcon,
  };
  return iconByName[areaName] ?? getIconByIndex(index);
}

export const AreaForImprovementTile = ({
  name,
  correct = 0,
  inCorrect = 0,
  index = 0,
}) => {
  const sum = correct + inCorrect;
  const total = sum < 1 ? 1 : sum;

  return (
    <div className="flex flex-col items-center text-center border border-softCloud rounded-lg p-6">
      <div className="relative flex items-center justify-center">
        <Circle value={correct} totalValue={total}/>
        <img className="absolute" src={getIcon(name, index)} alt="icon"/>
        {total > 0 && (
          <div
            className="w-4 h-4 absolute top-9 -right-0.5 outline outline-2 outline-white flex-shrink-0 flex items-center justify-center rounded-full bg-deepTeal text-[10px] leading-none text-white text-center">
            {Math.floor(correct / total * 100)}
          </div>
        )}
      </div>

      <span className="text-sm font-medium inline-block mt-2">{name}</span>
      {/*<span className="text-xs text-slateBlue inline-block mt-1 mb-3">{area.section}</span>*/}
    </div>
  );
}