import { useState } from "react";
import { ReactComponent as Arrow } from "../../../../assets/rt-arrow-blue.svg";
import { suspendAssessiv } from "../../../../actions/assessment";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { assessmentStore } from "../../../../store";

export const StartFromBeginning = ({ handleStartAssessment }) => {
  const { assessmentId, assessmentVersionId, educatorId } = assessmentStore();
  const [isAssessmentRetakeModalOpen, setIsAssessmentRetakeModalOpen] = useState(false);

  const closeRetakeModal = () => setIsAssessmentRetakeModalOpen(false);

  const openRetakeModal = () => setIsAssessmentRetakeModalOpen(true);

  const handleContinueAssessment = () => {
    suspendAssessiv({
      assessivId: assessmentId,
      assessivVersionId: assessmentVersionId,
      educatorId: educatorId
    })
      .then(() => handleStartAssessment?.());
  }

  return (
    <>
      <button
        className="w-full flex items-center justify-between gap-1 px-5 py-3 rounded-lg border border-skyBlue bg-transparent hover:bg-blue-50 transition text-base text-skyBlue"
        onClick={openRetakeModal}
      >
        Start from beginning
        <Arrow className="w-6 h-6" />
      </button>

      {isAssessmentRetakeModalOpen && (
        <ConfirmationModal
          checkBoxText="I agree to start a new assessment"
          description="Are you sure you want to begin a new assessment? This action cannot be undone."
          title="Confirm New Assessment Start"
          handleConfirm={handleContinueAssessment}
          handleCloseModal={closeRetakeModal}
        />
      )}
    </>
  );
}