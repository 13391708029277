import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import toast from "react-hot-toast";
import { groupsStore } from "../../../../../../../store";
import { createGroup, fetchGroups } from "../../../../../../../actions/group";
import { ReactComponent as CloseCrossIcon } from "../../../../../../../assets/close-cross.svg";

const backdropVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modalVariants = {
  visible: { y: 0 },
  hidden: { y: 50 },
};

export const CreateGroupModal = ({ onCloseModal }) => {
  const { setGroups } = groupsStore();
  const [groupName, setGroupName] = useState('');

  const handleOnClickConfirm = () => {
    if (groupName) {
      createGroup(groupName)
        .then(() => fetchGroups()
          .then((data) => {
            setGroups(data?.data ?? []);
            setGroupName('');
            onCloseModal();
        }))
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    }
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={backdropVariants}
        transition={{ duration: 0.3 }}
        id="staticModal"
        data-modal-backdrop="static"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 bg-black bg-opacity-20 "
      >
        <motion.div
          variants={modalVariants}
          transition={{ duration: 0.5, stiffness: 150 }}
          className="relative w-full max-w-[570px] max-h-full"
        >
          <div className="relative bg-white rounded shadow">
            <div className="flex flex-col items-stretch pt-10 pb-2 px-8 rounded-t">
              <h2 className="text-xl text-center font-medium text-navySmoke mb-2">
                Create group
              </h2>
              <h3 className="text-sm text-center text-navySmoke mb-6">
                Enter the new group's name
              </h3>
              <button
                type="button"
                className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm w-5 h-5 ml-auto inline-flex justify-center items-center"
                data-modal-hide="staticModal"
                onClick={onCloseModal}
              >
                <CloseCrossIcon className="w-3 h-3" />
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <div className="flex flex-col items-stretch px-8 mb-4 bg-white">
              <input
                type="text"
                className="rounded border border-whisperBlue py-2 px-4 flex-1 text-base text-navySmoke placeholder:text-fadedDenim"
                placeholder="Name"
                value={groupName}
                onChange={(event) => setGroupName(event.target.value)}
              />
            </div>

            <div className="flex items-center justify-end px-8 pt-4 pb-6 space-x-6 rounded-b">
              <button
                data-modal-hide="staticModal"
                type="button"
                className="text-navySmoke w-36 max-w-full bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-whisperBlue text-sm font-medium px-4 py-2.5 focus:z-10"
                onClick={onCloseModal}
              >
                Cancel
              </button>

              <button
                data-modal-hide="staticModal"
                type="button"
                disabled={!groupName.length}
                className="text-white w-36 max-w-full bg-skyBlue focus:ring-4 disabled:opacity-30 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center"
                onClick={handleOnClickConfirm}
              >
                Create
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  )
}