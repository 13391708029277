import { convertISOToUSDate, getScoreByScoreName } from "./utils";

export const RegularResult = ({ result, index, revision, handleViewResults }) => {
  return (
    <tr className="group transition hover:bg-lightGrey/60">
      <td
        className="col-span-4 break-all border-t border-alabaster px-2 py-2 pl-6 text-left sticky left-0">
        {!index && (
          <div className="flex items-center gap-4 font-medium text-duskyHarbor">
            {result?.name ?? '-'}
          </div>
        )}
      </td>
      <td className="col-span-2 break-all border-t border-alabaster px-2 py-2">
        <div className="flex items-center justify-center gap-0.5 min-w-full w-max">
          {convertISOToUSDate(revision?.completedDate ?? '')}
        </div>
      </td>
      <td className="col-span-2 break-all text-center border-t border-alabaster px-2 py-2">
        {getScoreByScoreName(revision?.scores, 'Total')}
      </td>
      <td className="col-span-2 break-all text-center border-t border-alabaster px-2 py-2">
        {getScoreByScoreName(revision?.scores, 'Reading and Writing')}
      </td>
      <td className="col-span-2 break-all text-center border-t border-alabaster px-2 py-2">
        {getScoreByScoreName(revision?.scores, 'Math')}
      </td>
      <td
        className="col-span-2 px-2 font-normal text-center py-2 border-t border-alabaster">
        <button
          className="text-indigo-600 font-medium min-w-max"
          onClick={() => handleViewResults(revision)}
        >
          View report
        </button>
      </td>
    </tr>
  );
}