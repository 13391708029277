import React from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { ReactComponent as DownloadIcon } from "../../../../assets/pDownload.svg";
import { assessmentStore } from "../../../../store";

const elementIds = [
  'download-page-id-1',
  'download-page-id-2',
  'download-page-id-3',
  'download-page-id-4',
  'download-page-id-5',
  'download-page-id-6',
  'download-page-id-7',
  'download-page-id-8'
];

export const DownloadButton = () => {
  const results = assessmentStore((state) => state?.results);

  const downloadPdfDocument = async () => {
    try {
      document.body.classList.add('cursor-wait');

      const style = document.createElement('style');
      document.head.appendChild(style);
      style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');

      const pdf = new jsPDF();

      for (const [index, id] of elementIds.entries()) {
        const element = document.getElementById(id);
        if (!element) {
          console.log(`Element with Id ${id} not found.`);
          continue;
        }

        const canvas = await html2canvas(element);
        if (index !== 0) {
          pdf.addPage();
        }
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
      }

      style.remove();

      pdf.save(`report${results?.resultId ? `-${results.resultId}` : ''}.pdf`);
    } catch (error) {
      console.error(error);
    } finally {
      document.body.classList.remove('cursor-wait');
    }
  };

  return (
    <button onClick={downloadPdfDocument}>
      <DownloadIcon className="h-4 active:scale-95" />
    </button>
  );
};
