import { useEffect } from "react";
import { resultsStore } from "../../../store";

export const useSaveFullScreenChange = () => {
  const setIsPresentMode = resultsStore(state => state.setIsPresentMode);

  const handleFullscreenChange = () => {
    setIsPresentMode(document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);
}