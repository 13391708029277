import Lottie from "react-lottie";
import Loader from "../assets/animations/circlesLoader.json";

const loaderOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const SubmitLoader = () => {
  return (
    <Lottie
      options={loaderOptions}
      height={24}
      width={40}
      isStopped={false}
      isPaused={false}
    />
  );
}