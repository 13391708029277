import { useMemo, useState } from "react";
import { GROUP_VIEW, GroupViewSelect } from "./GroupViewSelect";
import { GroupPlates } from "./GroupPlates";
import { GroupList } from "./GroupList";
import Pagination from "../../../../../../../../components/Pagination";
import { groupsStore, tablesStore } from "../../../../../../../../store";

const GROUPS_PER_PAGE = 10;

export const Groups = ({ searchString }) => {
  const groups = groupsStore(store => store.groups);
  const groupView = tablesStore(store => store.groupView);
  const [currentPage, setCurrentPage] = useState(1);

  const sortedGroups = useMemo(() => {
    return groups.toSorted((firstGroup, secondGroup) => {
      const firstGroupName = firstGroup.name.toLowerCase();
      const secondGroupName = secondGroup.name.toLowerCase();

      if (firstGroupName < secondGroupName) return -1;
      if (firstGroupName > secondGroupName) return 1;
      return 0;
    });
  }, [groups]);

  const filteredGroups = useMemo(
    () => sortedGroups.filter((group) => group?.name?.toLowerCase()?.includes(searchString?.toLowerCase())),
    [sortedGroups, searchString]
  );

  const groupsPerPage = useMemo(() => {
    const firstPageIndex = GROUPS_PER_PAGE * (currentPage - 1);
    const lastPageIndex = GROUPS_PER_PAGE * currentPage - 1;
    return filteredGroups?.filter(
      (_, index) => index >= firstPageIndex && index <= lastPageIndex) ?? [];
  }, [filteredGroups, currentPage]);

  if (!filteredGroups?.length) {
    return (
      <div className="text-xl text-slate-500 text-center p-4 md:p-6 w-full">
        No groups
      </div>
    );
  }

  return (
    <>
      <div className="my-4">
        {groupView === GROUP_VIEW.PLATES ? (
          <GroupPlates groups={groupsPerPage} />
        ) : (
          <GroupList groups={groupsPerPage} />
        )}
      </div>

      <div className="flex items-center justify-between">
        <div className="pointer-events-none opacity-0">
          <GroupViewSelect />
        </div>
        <Pagination
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          totalPages={Math.ceil((groups?.length ?? 0) / 10)}
        />
        <GroupViewSelect />
      </div>
    </>
  );
}