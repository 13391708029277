import { Home } from "./sections/home";
import { Header } from "../../components/Header";

export default function StudentDashboard() {
  return (
    <div className="flex flex-col bg-lightGrey min-h-screen">
      <Header />
      <div className="w-[1200px] max-w-full pt-8 pb-12 px-4 mx-auto">
        <Home />
      </div>
    </div>
  );
}
