import { useNavigate } from "react-router-dom";
import { PracticeResult } from "./PracticeResult";
import { RegularResult } from "./RegularResult";
import { sortRevisions } from "./utils";

const assessivsTableHeadCells = [
  'Assessivs Taken',
  'Complete date',
  'Total score',
  'R&W score',
  'Math score',
  '',
];

const practiceTableHeadCells = [
  'Practice Assessivs Taken',
  'Complete date',
  'Total',
  'Correct',
  'Incorrect',
  '',
];

export const ResultsTable = ({ results, isPractice }) => {
  const navigate = useNavigate();
  const tableHeadCells = isPractice ? practiceTableHeadCells : assessivsTableHeadCells;
  const RowComponent = isPractice ? PracticeResult : RegularResult;

  const handleViewResults = (revision) => {
    if (revision?.resultId) {
      navigate(`/dashboard/assessment/results?reportId=${revision.resultId}`);
    }
  }

  return (
    <table className="w-full text-xs">
      <thead className="bg-gray-200/50">
      <tr className="text-center text-fadedDenim pb-2 pl-6 text-xs">
        {tableHeadCells.map((cell, index) => (
          <th key={cell} className={`${index ? 'w-[15%] px-2 text-center' : 'w-1/4 text-left pl-6 py-[14px] pr-2'} font-normal`}>
            {cell}
          </th>
        ))}
      </tr>
      </thead>

      <tbody>
      {results?.map((result) => {
        if (!result?.revisions?.length) {
          return (
            <RowComponent
              key={result?.assessivId + 0}
              result={result}
              index={0}
              handleViewResults={handleViewResults}
            />
          );
        }

        return result.revisions?.toSorted(sortRevisions)?.map((revision, index) => (
          <RowComponent
            key={result?.assessivId + index}
            result={result}
            index={index}
            revision={revision}
            handleViewResults={handleViewResults}
          />
        ));
      })}
      </tbody>
    </table>
  );
}