import Lottie from "react-lottie";
import errorMessage from "../../../../assets/animations/errorMessage.json";

const lottie_errorMSG = {
  loop: false,
  autoplay: true,
  animationData: errorMessage,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const VerifyInValidParams = () => {
  return (
    <>
      <Lottie options={lottie_errorMSG} height={200} width={200} autoplay />
      <h1 className="text-2xl font-bold text-slate-900 mb-4">Invalid URL parameters</h1>
      <p className="text-slate-700 text-center">
        It looks like the URL parameters you provided are not valid. Please double-check and try again.  <br />
        If you continue to face issues, contact our support team for assistance: <br />
        <div className="my-4">
          <a className="px-2 py-2 bg-gray-100 rounded-md" href="mailto:support@accessiv.com?subject=ISSUE%3A%20Verify%20Account%20Link&body=I'm%20having%20issues%20when%20I%20click%20on%20the%20account%20link%20that%20was%20sent%20to%20my%20email.">
            support@assessiv.com
          </a>
        </div>
      </p>
    </>
  );
}