import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";

import errorMessage from "../../../assets/animations/errorMessage.json";
import loaderMessage from "../../../assets/animations/loadingRoller.json";
import { mx } from "../../../helpers/mixpanel.ts";
import { loginStore } from "../../../store";

const logSignIn = (email, name) => {
  try {
    mx.identify(email);
    mx.people.set({
      $email: email,
      $name: name,
    });
  } catch (error) {
    console.log(error);
  }
}

export default function Verify() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email');
  const name = searchParams.get('name');
  const accessToken = searchParams.get('accessToken');
  const refreshToken = searchParams.get('refreshToken');
  const expireAt = searchParams.get('expireAt');
  const isAllDataEnabled = !!email?.length && !!name?.length && !!accessToken?.length && !!refreshToken?.length && !!expireAt?.length;

  const lottie_loaderMSG = {
    loop: true,
    autoplay: true,
    animationData: loaderMessage,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const lottie_errorMSG = {
    loop: false,
    autoplay: true,
    animationData: errorMessage,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (isAllDataEnabled) {
      const { setFullName, setEmail, setId, setApplicationToken, setRefreshToken, setExpireAt, storeToLocal } = loginStore.getState();
      const tokenData = jwtDecode(accessToken);
      logSignIn(email, name);
      Promise.all([
        setId(tokenData?.nonce ?? ''),
        setFullName(name),
        setEmail(email),
        setApplicationToken(accessToken),
        setRefreshToken(refreshToken),
        setExpireAt(expireAt),
        storeToLocal({
          accessToken: accessToken,
          refreshToken: refreshToken,
          expireAt: expireAt
        }),
      ]).then(() => {
        setTimeout(() => {
          navigate('/dashboard');
          toast.success("Login Successful");
        }, 0);
      });
    }
  }, []);

  return (
    <>
      <div className="flex flex-1 flex-col h-screen  w-screen items-center justify-center overflow-hidden">
        {(!isAllDataEnabled) ? (
          <>
            <Lottie options={lottie_errorMSG} height={200} width={200} autoplay />
            <h1 className="text-2xl font-bold text-red-500">Invalid link</h1>
          </>
        ) : (
          <>
            <Lottie options={lottie_loaderMSG} height={200} width={200} autoplay />
            <h1 className="text-2xl font-bold text-slate-900">Loading...</h1>
          </>
        )}
      </div>
    </>
  );
}
