const ASSESSMENT_STATUS = {
  FRESH: 'Fresh',
  NOT_STARTED: 'NotStarted',
  IN_PROGRESS: 'InProgress',
  ABANDONED: 'Abandoned',
  COMPLETED: 'Completed',
  SUSPENDED: 'Suspended'
};

const ASSESSMENT_STATUS_BY_INDEX = {
  0: ASSESSMENT_STATUS.FRESH,
  1: ASSESSMENT_STATUS.IN_PROGRESS,
  2: ASSESSMENT_STATUS.ABANDONED,
  3: ASSESSMENT_STATUS.COMPLETED,
  4: ASSESSMENT_STATUS.SUSPENDED
};

const TIME_TAG_ID = '498c0840-33de-44f3-ad6f-01357cac01e3';
const SERVICE_PLAN_TAG_ID = '2ff7eed7-2540-48f6-ab2e-2ff297a88752';
const SERVICE_PLAN_TAG_PAID_VALUE = 'Paid';
const STATUS_TAG_ID = '07368578-8a26-4320-b0bd-b7641a90ea6d';
const STATUS_TAG_PRACTICE_VALUE = 'Practice';

export {
  ASSESSMENT_STATUS,
  ASSESSMENT_STATUS_BY_INDEX,
  TIME_TAG_ID,
  SERVICE_PLAN_TAG_ID,
  SERVICE_PLAN_TAG_PAID_VALUE,
  STATUS_TAG_ID,
  STATUS_TAG_PRACTICE_VALUE,
}