import axios from "axios";
import { ACTIVE_URL } from "../actions/constants";

import { loginStore, resetStores } from "../store";

const api = axios.create({
  baseURL: ACTIVE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("applicationToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");
      const email =  JSON.parse(localStorage.getItem("Assessiv-client")).state.email;
      const data = {
        email: email,
        refreshToken: refreshToken,
      };
      return api
        .post("/api/Token", data)
        .then((res) => {
          if (res.status === 200) {
            loginStore.setState({
              applicationToken: res.data.data.token.accessToken,
              refreshToken: res.data.data.token.refreshToken?.tokenString,
              expireAt: res.data.data.token.refreshToken?.expireAt,
            });
            localStorage.setItem("applicationToken", res.data.data.token.accessToken);
            localStorage.setItem("refreshToken", res.data.data.token.refreshToken?.tokenString);
            localStorage.setItem("expireAt", res.data.data.token.refreshToken?.expireAt);
            api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("applicationToken");
            return api(originalRequest);
          }
        })
        .catch(() => {
          resetStores();
          loginStore.getState().clearToken();
        });
    }
    return Promise.reject(error);
  }
);

// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     const refreshToken = localStorage.getItem("refreshToken");
//     const { loginData, refreshTokenData } = loginStore.getState();
//     const originalRequest = error.config;
//
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       originalRequest.headers.Authorization = `Bearer ${loginData.tokenData}`;
//       const _email = loginData.email;
//       const _refreshToken = refreshTokenData.tokenString;
//       return refreshToken(_email, _refreshToken)
//         .then((res) => {
//           return api(originalRequest);
//         })
//         .catch((err) => {
//           //Navigate away from the page login page
//           window.location.href = "/";
//           return api(originalRequest);
//         });
//     }
//     return Promise.reject(error);
//   }
// );

export { api };
