export const Tabs = ({ currentTabId, onChangeTab, tabs = [], tabClassName = '' }) => {
  return (
    <div className="flex items-center gap-4 flex-1 mr-2 border-b border-softCloud overflow-x-auto text-black scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md">
      {tabs.map((tab) => (
        <h2
          key={tab.id}
          className={`text-base text-black font-medium pb-3 cursor-pointer border-b-2 min-w-max ${tabClassName} ${currentTabId === tab.id ? 'border-skyBlue' : 'border-transparent text-opacity-50'}`}
          onClick={() => onChangeTab(tab.id)}
        >
          {tab.content}
        </h2>
      ))}
    </div>
  )
}