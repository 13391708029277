import { useState } from "react";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import { useNavigate, useSearchParams } from "react-router-dom";

import Loader from "../../../assets/animations/circlesLoader.json";
import newMailAnim from "../../../assets/animations/newMail.json";
import happyStudent from "../../../assets/happy_student.jpeg";
import { ReactComponent as Logo } from "../../../assets/logo_blue.svg";

import { resetPassword, sendEmailToResetPassword } from "../../../actions/registration";
import { AnimIn } from "../../../components/mountAnim";
import { PasswordInput } from "../../../components/PasswordInput";
import { NavigateToLandingLink } from "../components/NavigateToLandingLink";

const buttonStyle = "block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10 px-4";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const emailSentOptions = {
  loop: false,
  autoplay: true,
  animationData: newMailAnim,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function Reset() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const email = searchParams.get('email');

  const handleSendEmailNotification = (event) => {
    event.preventDefault();
    const emailRegExp = new RegExp(/^[\w\.-]+@[\w\.-]+\.\w+$/);

    if (!emailRegExp.test(event.target.email.value)) {
      toast.error('Invalid email.');
      return;
    }

    setIsLoading(true);
    sendEmailToResetPassword(event.target.email.value)
      .then(() => setIsEmailSent(true))
      .finally(() => setIsLoading(false));
  }

  const handleResetPassword = (event) => {
    event.preventDefault();
    const eventTarget = event.target;

    if (!!eventTarget.password.value.length && eventTarget.password.value === eventTarget.confirmPassword.value) {
      setIsLoading(true);
      toast.promise(
        resetPassword(email, eventTarget.password.value),
        {
          success: 'Password successfully reset.',
          error: 'The password should contain numbers and at least one capital letter.'
        }
      )
        .then(() => navigate('/login'))
        .finally(() => setIsLoading(false));
    } else {
      toast.error('Passwords do not match.');
    }
  }

  const navigateToLoginPage = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate('/login');
  }

  return (
    <>
      <div className="flex flex-row h-screen bg-slate-500 w-screen">
        <div className="flex flex-col justify-between items-center lg:items-start h-full p-[2em] md:p-14 bg-white lg:w-[33em] w-full">
          <NavigateToLandingLink>
            <Logo className="w-93 h-24" />
          </NavigateToLandingLink>

          <AnimIn isVisible={true}>
            <form className="w-full" onSubmit={email ? handleResetPassword : handleSendEmailNotification}>
              {isEmailSent ? (
                <>
                  <Lottie options={emailSentOptions} height={200} width={200} autoplay />
                  <label htmlFor="email" className="block text-2xl font-medium text-gray-700 text-center">
                    Please check your email
                  </label>
                  <p className="text-sm text-gray-500 mt-12 text-center">
                    We have sent you a reset link to your email address.
                  </p>
                </>
              ) : (
                <>
                  <label htmlFor="email" className="block text-2xl font-medium text-gray-700">
                    Reset password
                  </label>

                  <p className="text-sm text-gray-500 mt-6">
                    Regain access to your account and secure it by resetting your password.
                  </p>

                  <div className="mt-6">
                    <input
                      required
                      value={email ?? undefined}
                      disabled={!!email}
                      name="email"
                      type="email"
                      placeholder="Email address"
                      className={buttonStyle}
                    />
                  </div>

                  {!!email && (
                    <>
                      <div className="mt-6">
                        <PasswordInput required name="password" placeholder="New password" className={buttonStyle} />
                      </div>
                      <div className="mt-6">
                        <PasswordInput required name="confirmPassword" placeholder="Confirm the new password" className={buttonStyle} />
                      </div>
                    </>
                  )}
                  <button className="mt-6 bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg w-full">
                    {isLoading
                      ? <Lottie options={defaultOptions} height={24} width={40} isStopped={false} isPaused={false} />
                      : <span className="py-2 px-4 text-md">RESET</span>}
                  </button>
                  <div className="text-center mt-32">
                    <p className="text-sm text-gray-500 mt-12">Already have an account?</p>
                    <button
                      type="button"
                      className="text-sm font-bold text-blue-600 cursor-pointer"
                      onClick={navigateToLoginPage}
                    >
                      Login Here
                    </button>
                  </div>
                </>
              )}
            </form>
          </AnimIn>
          <p className="text-sm text-gray-500 mt-12">All rights reserved.</p>
        </div>

        <AnimIn isVisible={true} className="w-full flex flex-1">
          <img alt="" src={happyStudent} className="h-screen w-full bg-auto bg-no-repeat bg-center object-cover" />
        </AnimIn>
      </div>
    </>
  );
}
