import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import { ArrowUturnLeftIcon, ArrowUturnRightIcon, PencilIcon } from "@heroicons/react/24/outline";
import { ReactComponent as EraserIcon } from "../../../../../assets/eraser.svg";
import { ReactComponent as RulerIcon } from "../../../../../assets/ruler.svg";
import { ReactComponent as ResetIcon } from "../../../../../assets/reset.svg";
import { ReactComponent as SaveIcon } from "../../../../../assets/discet.svg";
import { deleteCanvasPaths, fetchSavedCanvasPaths, saveCanvasPaths } from "../../../../../actions/report";
import { assessmentStore } from "../../../../../store";
import { fetchReport } from "../../../utils";

const DRAWER_CANVAS_MODE = {
  OFF: 'off',
  PENCIL: 'pencil',
  ERASER: 'eraser',
}

const CanvasButton = ({ className = '', children, ...props }) => {
  return (
    <button {...props} className={`w-6 h-6 flex items-center justify-center rounded transition ${className}`}>
      {children}
    </button>
  );
}

const Divider = () => {
  return (
    <div className="h-4 border-r"/>
  );
}

const ColorSelect = ({color, handleStrokeColorChange }) => {
  return (
    <label className="w-4 h-4 border rounded cursor-pointer" style={{background: color}}>
      <input
        type="color"
        value={color}
        className="w-full h-full opacity-0"
        onChange={handleStrokeColorChange}
      />
    </label>
  );
}

const WidthSelect = ({ strokeWidth, handleStrokeWidthChange }) => {
  return (
    <div className="flex flex-col items-center group/strokeWidthPopUp relative">
      <CanvasButton className="hover:bg-gray-200 active:bg-gray-300">
        <RulerIcon className="w-4 h-4 -rotate-45 scale-110"/>
      </CanvasButton>

      <motion.div
        className="absolute top-6 z-20 bg-white rounded border transition-all opacity-0 group-hover/strokeWidthPopUp:opacity-100 hidden group-hover/strokeWidthPopUp:flex flex-col gap-1 p-2"
      >
        <label htmlFor="strokeWidth" className="form-label text-sm font-medium">
          Stroke width
        </label>
        <input
          type="range"
          className="form-range"
          min="1"
          max="20"
          step="1"
          id="strokeWidth"
          value={strokeWidth}
          onChange={handleStrokeWidthChange}
        />
      </motion.div>
    </div>
  );
}

const Toolbar = ({
  canvasRef,
  canvasRefObject,
  color,
  setColor,
  strokeWidth,
  setStrokeWidth,
  mode,
  setMode,
  questionId,
}) => {
  const handleEraserClick = () => {
    setMode(mode === DRAWER_CANVAS_MODE.ERASER ? DRAWER_CANVAS_MODE.OFF : DRAWER_CANVAS_MODE.ERASER);
    canvasRef?.eraseMode(true);
  };

  const handlePenClick = () => {
    setMode(mode === DRAWER_CANVAS_MODE.PENCIL ? DRAWER_CANVAS_MODE.OFF : DRAWER_CANVAS_MODE.PENCIL);
    canvasRef?.eraseMode(false);
  };

  const handleStrokeColorChange = (event) => {
    setColor(event.target.value);
  };

  const handleStrokeWidthChange = (event) => {
    setStrokeWidth(+event.target.value);
  };

  const handleUndoClick = () => {
    canvasRef?.undo();
  };

  const handleRedoClick = () => {
    canvasRef?.redo();
  };

  const handleClearClick = () => {
    canvasRef?.clearCanvas();
  };

  const handleSaveCanvas = async () => {
    const canvasPathsData = await canvasRefObject?.current?.exportPaths?.();
    const { results } = assessmentStore.getState();
    const reportId = results?.resultId;

    if (Array.isArray(canvasPathsData) && !!reportId) {
      const handler = canvasPathsData?.length ? saveCanvasPaths : deleteCanvasPaths;
      toast.promise(
        handler(questionId, reportId, canvasPathsData),
        {
          loading: 'Saving...',
          success: 'Canvas is successfully saved!',
          error: 'Error when saving canvas.',
        }
      )
        .then(() => fetchReport())
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <div className="flex items-center border-b gap-2 pb-2">
      <CanvasButton className="hover:bg-gray-200 active:bg-gray-300" onClick={handleUndoClick}>
        <ArrowUturnLeftIcon className="h-4"/>
      </CanvasButton>

      <CanvasButton className="hover:bg-gray-200 active:bg-gray-300" onClick={handleRedoClick}>
        <ArrowUturnRightIcon className="h-4"/>
      </CanvasButton>

      <Divider />

      <CanvasButton
        className={mode === DRAWER_CANVAS_MODE.PENCIL ? 'text-white bg-skyBlue' : 'hover:bg-gray-200 active:bg-gray-300'}
        onClick={handlePenClick}
      >
        <PencilIcon className="h-4"/>
      </CanvasButton>

      <CanvasButton
        className={mode === DRAWER_CANVAS_MODE.ERASER ? 'bg-skyBlue' : 'hover:bg-gray-200 active:bg-gray-300'}
        onClick={handleEraserClick}
      >
        <EraserIcon className={`w-4 h-4 ${mode === DRAWER_CANVAS_MODE.ERASER ? 'fill-white' : 'fill-black'}`}/>
      </CanvasButton>

      <Divider />

      <ColorSelect
        color={color}
        handleStrokeColorChange={handleStrokeColorChange}
      />

      <WidthSelect
        strokeWidth={strokeWidth}
        handleStrokeWidthChange={handleStrokeWidthChange}
      />

      <Divider />

      <CanvasButton className="hover:bg-gray-200 active:bg-gray-300" onClick={handleClearClick}>
        <ResetIcon className="w-4 h-4"/>
      </CanvasButton>

      <Divider/>

      <CanvasButton className="hover:bg-gray-200 active:bg-gray-300" onClick={handleSaveCanvas}>
        <SaveIcon className="w-[18px] h-[18px]"/>
      </CanvasButton>
    </div>
  );
}

export const DrawingCanvas = ({ questionId, canvasHeight }) => {
  const canvas = useRef(null);
  const [color, setColor] = useState('#000000');
  const [strokeWidth, setStrokeWidth] = useState(5);
  const [mode, setMode] = useState(DRAWER_CANVAS_MODE.OFF);

  useLayoutEffect(() => {
    const rect = document.getElementById('react-sketch-canvas__canvas-background');
    if (rect) {
      rect.setAttribute('fill', 'transparent');
    }
  }, []);

  useEffect(() => {
    const { results } = assessmentStore.getState();
    const reportId = results?.resultId;
    if (reportId) {
      fetchSavedCanvasPaths(questionId, reportId)
        .then((res) => {
          const data = JSON.parse(res?.data ?? '');
          if (Array.isArray(data)) {
            canvas.current?.loadPaths(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('No reportId.');
    }
  }, []);

  return (
    <div style={{ height: canvasHeight }} className="flex flex-col p-2 border-l w-2/3">
      <Toolbar
        canvasRef={canvas.current}
        canvasRefObject={canvas}
        color={color}
        setColor={setColor}
        strokeWidth={strokeWidth}
        setStrokeWidth={setStrokeWidth}
        mode={mode}
        setMode={setMode}
        questionId={questionId}
      />

      <div className={`flex-1 ${mode === DRAWER_CANVAS_MODE.OFF ? 'pointer-events-none' : ''}`}>
        <ReactSketchCanvas
          ref={canvas}
          strokeColor={color}
          strokeWidth={strokeWidth}
          eraserWidth={strokeWidth}
          style={{
            position: "absolute",
            top: '40px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            minHeight: 'calc(100%-40px)',
            height: canvasHeight - 40,
            borderWidth: 0,
            background: 'transparent'
          }}
        />
      </div>
    </div>
  );
}