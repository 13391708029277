export const sortRevisions = (firstRevision, secondRevision) => {
  const firstDate = new Date(firstRevision.completedDate);
  const secondDate = new Date(secondRevision.completedDate);
  return secondDate - firstDate;
}

export const getScoreByScoreName = (arrayOfScore, scoreName) =>
  arrayOfScore?.find(score => score?.name === scoreName)?.score ?? '-';

export const calculatePercentage = (revision) => {
  const correctAnswers = revision?.correctCount ?? 0;
  const inCorrectAnswers = revision?.inCorrectCount ?? 0;
  const totalAnswers = correctAnswers + inCorrectAnswers;

  if (!totalAnswers) return 0;

  return Math.floor(correctAnswers / totalAnswers * 100);
}

export function convertISOToUSDate(isoDate) {
  if (!isoDate) {
    return '-';
  }

  const date = new Date(isoDate);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return month + '/' + day + '/' + year;
}

// @todo: temp check if the assessment is practice
export const isAssessmentPractice = (assessment) => {
  return [
    'Writing - ',
    'Reading - ',
    'Math - ',
  ].some(item => assessment?.name?.startsWith(item));
}