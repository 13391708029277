import { useEffect, useRef, useState } from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useClickOutside } from "../../../helpers/hooks/useClickOutside.ts";

export const MultipleInput = ({
  closeDropDownOnSelect,
  defaultDisplayName,
  options,
  selectedOptions,
  onSelectOption,
  onUnselectOption,
  onOpenDropDown,
  noOptionsText = 'No options available',
  className = '',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [top, setTop] = useState(44);
  const dropdownRef = useRef(null);

  const handleCloseDropdown = () => setIsOpen(false);

  const handleOpenDropdown = () => setIsOpen(true);

  const handleSelectOption = (option) => (event) => {
    event.stopPropagation();
    onSelectOption(option);

    if (closeDropDownOnSelect) {
      handleCloseDropdown();
    }
  }

  const handleUnselectOption = (optionId) => (event) => {
    event.stopPropagation();
    onUnselectOption(optionId);
  }

  useEffect(() => {
    if (dropdownRef.current) {
      setTop((dropdownRef.current.clientHeight ?? 40) + 4);
    }
  }, [selectedOptions]);

  useClickOutside(
    dropdownRef,
    handleCloseDropdown
  );

  return (
    <div
      className={`flex flex-col relative border rounded-md bg-white text-sm min-h-[40px] w-full ${className} ${isOpen ? 'border-blue-400' : 'border-gray-300'}`}
      ref={dropdownRef}
    >
      <div className="flex gap-2 items-start pl-3 py-2 cursor-pointer" onClick={handleOpenDropdown}>
        <div className="flex flex-wrap items-center gap-2 pr-11 max-h-[160px] flex-1 text-slate-600 overflow-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md">
          {selectedOptions.length ? (
            selectedOptions.map(selectedOption => (
              <span key={selectedOption.id}
                    className="flex flex-row gap-1 items-center bg-slate-100 hover:bg-slate-200 border border-gray-200 text-sm px-2 py-1 rounded-lg text-black"
                    onClick={handleUnselectOption(selectedOption.id)}>
              {selectedOption.name}
                <XCircleIcon
                  className="h-4 w-4 flex-shrink-0 text-gray-400 hover:text-gray-600 hover:cursor-pointer"></XCircleIcon>
            </span>
            ))
          ) : (
            <span className="text-gray-500 font-normal">
              {defaultDisplayName}
            </span>
          )}
        </div>

        <ChevronDownIcon className={`h-5 text-[#94A3B8] mr-2.5 ${isOpen ? 'rotate-180' : ''}`} />
      </div>

      {isOpen && (
        <div
          className="flex flex-col items-stretch px-3 py-1 absolute top-11 left-0 z-10 border border-blue-400 rounded-md bg-white w-full max-h-[200px] overflow-auto text-black scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md"
          style={{ top }}
        >
          {options?.length ? (
            options.map((option, index) => (
              <div
                key={option?.id}
                className={`flex items-center justify-between gap-4 cursor-pointer px-0.5 py-1.5 border-t ${index === 0 ? 'border-transparent' : 'border-blue-400'}`}
                onClick={handleSelectOption(option)}
              >
                {option?.name}
                {!!option?.additionalText?.length && (
                  <span className="text-xs text-gray-400">{option?.additionalText}</span>
                )}
              </div>
            ))
          ) : (
            <div className="w-full text-center text-slate-400 sm:text-sm px-0.5 py-1 border-t border-transparent">{noOptionsText}</div>
          )}
        </div>
      )}
    </div>
  );
}