import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ReactComponent as CrossIcon } from "../../../../../../../assets/add-cross.svg";
import { ReactComponent as CloseCrossIcon } from "../../../../../../../assets/close-cross.svg";
import { assignUserToGroup, fetchGroups } from "../../../../../../../actions/group";
import { Search } from "../../../../../../../components/Search";
import { groupsStore } from "../../../../../../../store";

export const AddToGroupButton = ({ userId, title = 'Add to Group', isTextButton, onVisibilityChange, }) => {
  const { groups, setGroups } = groupsStore();
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [search, setSearch] = useState('');

  const filteredGroups = useMemo(() => {
    return groups.filter((group) => group?.name?.toLowerCase()?.includes(search?.toLowerCase()));
  }, [groups, search]);

  const openModal = (event) => {
    event.stopPropagation();
    setIsAddModalVisible(true);
  }

  const handleAddUser = (groupId) => () => {
    if (groupId && userId) {
      assignUserToGroup(groupId, userId)
        .then(() =>
          fetchGroups()
            .then((data) => {
              setGroups(data?.data ?? []);
            })
        );
    }
  }

  useEffect(() => {
    onVisibilityChange?.(isAddModalVisible);
  }, [onVisibilityChange, isAddModalVisible]);

  return (
    <>
      {isTextButton ? (
        <button
          className="flex items-center gap-1 p-1.5 text-xs rounded-md hover:bg-gray-100 transition font-medium"
          onClick={openModal}
        >
          {title}
        </button>
      ) : (
        <button
          title={title}
          className="flex items-center gap-1 p-1.5 rounded-full opacity-0 text-xs group-hover:opacity-100 invisible group-hover:visible hover:bg-gray-200 active:bg-gray-300"
          onClick={openModal}
        >
          <CrossIcon/>
        </button>
      )}

      {isAddModalVisible && (
        createPortal(
          <AnimatePresence mode="wait">
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={{
                visible: {opacity: 1},
                hidden: { opacity: 0 },
              }}
              transition={{ duration: 0.3 }}
              id="staticModal"
              data-modal-backdrop="static"
              tabIndex="-1"
              aria-hidden="true"
              className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 bg-black bg-opacity-20 "
            >
              <motion.div
                variants={{
                  visible: { y: 0 },
                  hidden: { y: 50 },
                }}
                transition={{ duration: 0.5, stiffness: 150 }}
                className="relative w-full max-w-[570px] max-h-full"
                onClick={(event) => event.stopPropagation()}
              >
                <div className="relative bg-white rounded shadow lg:min-h-[500px]">
                  <div className="flex flex-col items-stretch pt-10 pb-2 px-8 rounded-t">
                    <h2 className="text-xl text-center font-medium text-navySmoke mb-2">
                      {title}
                    </h2>
                    <button
                      type="button"
                      className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm w-5 h-5 ml-auto inline-flex justify-center items-center"
                      data-modal-hide="staticModal"
                      onClick={() => {
                        setIsAddModalVisible(false);
                      }}
                    >
                      <CloseCrossIcon className="w-3 h-3" />
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>

                  <Search searchString={search} onChangeSearchString={setSearch} className="rounded !p-2 mb-4 mx-8" />

                  <div className="pb-10">
                    <div className="flex flex-col items-stretch pl-8 pr-2 mr-8 bg-white max-h-[320px] overflow-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-m">
                      {filteredGroups.length ? (
                        filteredGroups.map((group, index) => (
                          <div
                            key={group.id}
                            className={`flex items-center justify-between gap-1 py-2 ${!!index ? 'border-t' : ''}`}
                          >
                            {group.name}
                            {group?.users?.find((user) => user.userId === userId) ? (
                              <div className="px-2 text-sm text-fadedDenim font-normal">
                                Added
                              </div>
                            ) : (
                              <button
                                className="flex items-center justify-center text-skyBlue h-[30px]"
                                onClick={handleAddUser(group.id)}
                              >
                                <PlusCircleIcon className="h-5" />
                              </button>
                            )}
                          </div>
                        ))
                      ) : (
                        <span className="inline-block w-full mt-4 text-center text-lg text-fadedDenim">No groups</span>
                      )}
                    </div>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </AnimatePresence>,
          document.body
        )
      )}
    </>
  );
}