import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Header } from "../../../components/Header";
import { MenuCard } from "../../dashboard/components/utilityComponents";
import { getRoleDisplayName, isAdminEducator, ROLE } from "../../../helpers/authentication.ts";
import {
  sendEmailEducatorInvitation
} from "../../../actions/emails";
import {
  addEducator,
} from "../../../actions/user";
import { loginStore } from "../../../store";
import { generateRandomThreeDigitNumber } from "../../../helpers/number.ts";
import { createDebounceFunction } from "../../../helpers/functions.ts";
import { checkUserExists, getRoles } from "../../../actions/registration";
import { emailRegExp } from "../../../helpers/validation.ts";
import { SubmitLoader } from "../../../components/SubmitLoader";
import { InputLoaderWrapper } from "../../../components/InputLoaderWrapper";
import { Select } from "../../plan/components/Select";

const debouncedUserExists = createDebounceFunction((userEmail, setUserExists, setIsUserCheckLoading, setShowNameInputByDefault, setFirstName, setLastName, setRole) => {
  setIsUserCheckLoading(true);
  checkUserExists(userEmail)
    .then((data) => {
      setUserExists(data?.data ?? true);
      const name = data?.data?.name;
      const role = data?.data?.role;
      if (typeof name === 'string') {
        const [firstName, lastName] = name.split(' ');
        setFirstName(firstName);
        setLastName(lastName);
      }
      if (role) {
        setRole(role);
      }
    })
    .catch((error) => {
      const value = error?.response?.data?.errorCode;
      setUserExists(!(value === 'User doesn\'t exist'));
    })
    .finally(() => {
      setShowNameInputByDefault(true);
      setIsUserCheckLoading(false);
    });
}, 600);

const rolesAllowedToInvite = [ROLE.EDUCATOR, ROLE.EDUCATOR_ADMIN];

export const EducatorsAssessivInvite = () => {
  const { fullName } = loginStore();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [showNameInputByDefault, setShowNameInputByDefault] = useState(false);
  const [isUserCheckLoading, setIsUserCheckLoading] = useState(false);

  const handleOnChangeEmail = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    if (emailRegExp.test(newEmail)) {
      debouncedUserExists(newEmail, setUserExists, setIsUserCheckLoading, setShowNameInputByDefault, setFirstName, setLastName, setRole);
    }
  }

  const validateOptions = () => {
    if (!emailRegExp.test(email)) {
      toast.error('Invalid email.');
      return false;
    }

    if (!`${firstName}${lastName}`?.length) {
      toast.error('Name is required.');
      return false;
    }

    if (!role?.length || !rolesAllowedToInvite.includes(role)) {
      toast.error('Invalid role.');
      return false;
    }

    if (!roles.find(item => item?.role === role)?.roleId) {
      toast.error('Error when getting selected role\'s options.');
      return false;
    }

    return true;
  }

  const handleSubmitInvitation = (event) => {
    event.preventDefault();

    if (!isUserCheckLoading && validateOptions()) {
      const name = `${firstName} ${lastName}`;
      const password = `Assessiv@${generateRandomThreeDigitNumber()}`;
      const roleId = roles?.find?.(item => item?.role === role)?.roleId;
      if (!roleId) {
        toast.success('Error when getting selected role\'s options.');
        return;
      }

      setIsLoading(true);

      addEducator({
        email,
        password,
        name,
      }, roleId)
        .then(() => {
          sendEmailEducatorInvitation({
            name,
            email,
            password,
            inviterName: fullName
          })
            .then(() => {
              toast.success('Educator is successfully invited.');
            })
            .catch(() => {
              toast.error('The educator was successfully added, but unfortunately was not notified.');
            })
            .finally(() => {
              setIsLoading(false);
              setEmail('');
              setFirstName('');
              setLastName('');
            });
        })
        .catch(() => {
          setIsLoading(false);
          toast.error('Error when inviting educator or educator is already added.');
        });
    }
  }

  const fetchRoles = async () => {
    const fetchedRoles = await getRoles();
    setRoles(fetchedRoles);
  }

  useEffect(() => {
    if (!isAdminEducator()) {
      navigate(-1);
    }
    fetchRoles();
  }, []);

  return (
    <div className="App-header flex flex-col bg-slate-200">
      <Header />
      <div className="flex flex-1 bg-slate-200 z-0 overflow-y-auto">
        <MenuCard className="flex h-full items-center justify-center">
          <form
            className="w-[400px] max-w-full h-full flex gap-4 flex-col items-stretch justify-center relative bottom-10"
            onSubmit={handleSubmitInvitation}
          >
            <h1 className="text-black font-semibold text-center text-2xl mb-4">
              Invite educator
            </h1>

            <InputLoaderWrapper className="shadow rounded-md" isLoaderVisible={isUserCheckLoading}>
              <input
                required
                name="email"
                type="email"
                value={email}
                onChange={handleOnChangeEmail}
                placeholder="Email"
                className="block w-full text-black rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10 px-4"
              />
            </InputLoaderWrapper>

            {showNameInputByDefault && (
              <div className="flex items-center gap-4">
                <input
                  required
                  name="firstName"
                  type="text"
                  value={firstName}
                  disabled={!!userExists}
                  onChange={(event) => setFirstName(event.target.value)}
                  placeholder="First name"
                  className="block w-full text-black rounded-md border border-gray-300 shadow focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10 px-4 disabled:bg-gray-200 disabled:text-gray-400 disabled:placeholder:text-gray-400 transition"
                />
                <input
                  required
                  name="lastName"
                  type="text"
                  value={lastName}
                  disabled={!!userExists}
                  onChange={(event) => setLastName(event.target.value)}
                  placeholder="Last name"
                  className="block w-full text-black rounded-md border border-gray-300 shadow focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10 px-4 disabled:bg-gray-200 disabled:text-gray-400 disabled:placeholder:text-gray-400 transition"
                />
              </div>
            )}

            <Select
              disable={userExists}
              selectedOption={role}
              onSelectOption={setRole}
              placeholder="Role"
              options={rolesAllowedToInvite}
              getDisplayName={getRoleDisplayName}
            />

            <button
              type="submit"
              disabled={isLoading || !rolesAllowedToInvite.includes(role)}
              className={`bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg w-full shadow ${isLoading ? '' : 'disabled:bg-gray-200'}`}
            >
              {isLoading
                ? <SubmitLoader />
                : <span className="py-2 px-4 text-md">INVITE</span>}
            </button>
          </form>
        </MenuCard>
      </div>
    </div>
  );
}