export const groupUsers = (users) => {
  const educators = [];
  const students = [];

  users?.forEach((user) => {
    const userArray = user.role === 'Student' ? students : educators;
    userArray.push(user);
  });

  return {
    educators,
    students,
  };
}