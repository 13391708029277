import { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

export const PasswordInput = ({ defaultVisible = false, className, wrapperClasName = '', ...props }) => {
  const [showPassword, setShowPassword] = useState(defaultVisible);

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  const ToggleIcon = showPassword ? EyeSlashIcon : EyeIcon;

  return (
    <div className={`relative flex ${wrapperClasName}`}>
      <input
        {...props}
        className={`${className ?? ''} pr-11`}
        type={showPassword ? 'text' : 'password'}
      />

      <button
        type="button"
        className="absolute right-2.5 self-center"
        onClick={togglePasswordVisibility}
      >
        <ToggleIcon className="h-5 stroke-gray-400" />
      </button>
    </div>
  );
}