import axios from "axios";
import { loginStore } from "../../store";
import {ACTIVE_URL, EMAIL_SERVICE_URL} from "../constants";
import { api } from "../../helpers/apiHelper";

// INCOMING
// {
//     "email": "kirubel@kirusoft.com",
//     "password": "password@1234",
//     "name": "Kirubel Girma"
//   }

export const getRoles = (registerData) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${ACTIVE_URL}/api/Role/roles`, registerData, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getRegistrationToken = (registerData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ACTIVE_URL}/api/Token/Registration`, registerData, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const registerUser = (registerData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ACTIVE_URL}/api/Account/invite/signup`, registerData, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendEmailToResetPassword = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${EMAIL_SERVICE_URL}/reset/student`, { email })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetPassword = (username, password) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${ACTIVE_URL}/api/ResetPassword/reset`,
        null,
        {
          headers: { 'Content-Type': 'application/json' },
          params: { username, password }
        })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const verifyUser = (code) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ACTIVE_URL}/api/Account/validatecode`, {code: code}, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loginUser = (userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ACTIVE_URL}/api/account/signin`, userData, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const checkUserExists = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${ACTIVE_URL}/api/users/${email}/exist`, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const sendSignupEmail = ({ email, password, role, token, firstName, lastName, companyName }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${EMAIL_SERVICE_URL}/signup`, { email, password, role, token, firstName, lastName, companyName }, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const changePassword = ({ username, password }) => {
  const url = new URL(`${ACTIVE_URL}/api/ResetPassword/reset`);
  if (username) {
    url.searchParams.set('username', username);
  }
  if (password) {
    url.searchParams.set('password', password);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url.toString(), null, { headers: { "Content-Type": "application/json" } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const updateName = (firstName, lastName) => {
  return new Promise((resolve, reject) => {
    api
      .patch(
        `${ACTIVE_URL}/api/users`,
        { firstName, lastName },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const fetchCompanyImage = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`${ACTIVE_URL}/api/image/company`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadCompanyImage = (formData) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${ACTIVE_URL}/api/image/company/upload`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};