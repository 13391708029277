import { useNavigate } from "react-router-dom";
import { Disclosure, Transition } from "@headlessui/react";
import { Bars4Icon as MenuIcon, XMarkIcon as XIcon } from "@heroicons/react/24/outline";
import { Pricing } from "./pricing";
import { NavigateToLandingLink } from "../../authentication/components/NavigateToLandingLink";
import { ReactComponent as Logo } from "../../../assets/logo_white.svg";
import { mx } from "../../../helpers/mixpanel.ts";

export const LandingHeader = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    mx.track('Login Clicked');
    navigate('/login');
  }

  const handleRegister = () => {
    mx.track('Register Clicked');
    navigate('/register');
  }

  return (
    <Disclosure as="nav" className="w-full bg-deepCerulean">
      {({ open }) => (
        <>
          <div className="px-10 py-5 flex items-center justify-between">
            <NavigateToLandingLink>
              <Logo className="h-7" />
            </NavigateToLandingLink>
            <div className="hidden lg:flex items-center gap-10">
              <Pricing />
              <button
                className="items-center justify-center text-white text-md font-medium cursor-pointer"
                onClick={handleLogin}
              >
                Login
              </button>
              <button
                className="items-center justify-center w-max cursor-pointer text-black text-md font-medium border border-black rounded-lg py-2 px-4 bg-goldenSun"
                onClick={handleRegister}
              >
                Register
              </button>
            </div>
            <div className="lg:hidden">
              <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                {open ? (
                  <XIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
            </div>
          </div>

          <Transition
            show={open}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Disclosure.Panel className="lg:hidden">
              <div className="px-10 pt-2 pb-3 space-y-1">
                <Pricing buttonClassName="px-3 py-2" />
                <Disclosure.Button
                  as="a"
                  className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white hover:bg-gray-700 cursor-pointer"
                  onClick={handleLogin}
                >
                  Login
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white hover:bg-gray-700 cursor-pointer"
                  onClick={handleRegister}
                >
                  Register
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};