const isDifficultySquareFilled = (squareIndex, difficulty) => {
  switch (squareIndex) {
    case 0: return difficulty >= 1;
    case 1: return difficulty >= 3;
    case 2: return difficulty >= 6;
    default: return false;
  }
}

export const DifficultySquares = ({ difficulty }) => {
  return (
    <div className="flex gap-1.5">
      {Array.from({ length: 3 }, (_, index) => {
        const isFilled = isDifficultySquareFilled(index, difficulty);
        return (
          <div key={index} className={`w-3 h-4 border border-gray-500 ${isFilled ? 'bg-gray-500' : ''}`}/>
        );
      })}
    </div>
  );
}