import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { featuresList } from "./constants";

function TableIcon({ isAvailable }) {
  if (isAvailable) {
    return <CheckCircleIcon className="w-5 h-5 text-green-500 mx-auto" />;
  }
  return <XCircleIcon className="w-5 h-5 text-red-500 mx-auto" />;
}

export const FeaturesSection = () => {
  return (
    <>
      <h2 className="text-3xl text-center font-semibold leading-7 text-indigo-600 mt-20 my-8">Features</h2>

      <div className="mt-8 border border-gray-300 relative rounded-xl overflow-auto scrollbar-thumb-gray-400 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md">
        <table className="w-full bg-white border-separate border-spacing-0 gap-0 rounded-xl">
          <thead>
          <tr className="text-navySmoke">
            <th
              className="sticky left-0 bg-white py-3 px-4 border-b border-r min-w-max font-semibold rounded-tl-xl"></th>
            <th
              className="py-4 px-4 border-b border-r min-w-max lg:min-w-[222px] font-semibold text-center">Student
            </th>
            <th className="py-2 px-4 border-b border-r">
              <div className="min-w-max lg:min-w-[222px] font-semibold text-center">Company</div>
            </th>
          </tr>
          </thead>
          <tbody>
          {featuresList.map((feature, index) => (
            <tr key={feature}>
              <td className="sticky left-0 bg-white py-3 px-4 border-b border-r rounded-tl-xl">{feature}</td>
              <td className="py-2 px-4 border-b border-r text-center">
                <TableIcon isAvailable={index < 4} />
              </td>
              <td className="py-2 px-4 border-b text-center">
                <TableIcon isAvailable />
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </>
  );
}