import { ACTIVE_URL } from "../constants";
import { api } from "../../helpers/apiHelper";

export const getResultByResultId = (reportId) => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/report/${reportId}`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchSavedCanvasPaths = (questionId, reportId) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${ACTIVE_URL}/api/svg/questions/${questionId}/result/${reportId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveCanvasPaths = (questionId, reportId, pathsData) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${ACTIVE_URL}/api/svg/questions/${questionId}/result/${reportId}`, pathsData)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteCanvasPaths = (questionId, reportId) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`${ACTIVE_URL}/api/svg/questions/${questionId}/result/${reportId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};