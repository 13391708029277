import { InviteAssignStudent } from "../components/InviteSection";

export const StudentsAssessivInvite = () => {
  return (
    <InviteAssignStudent
      title="Invite student"
      confirmText="Invite"
      newUserConfirmText="Invite"
    />
  );
}