import { useRef, useState } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { ReactComponent as OptionsIcon } from "../../../../assets/optDot.svg";
import { useClickOutside } from "../../../../helpers/hooks/useClickOutside.ts";
import { resultsStore } from "../../../../store";

export const Options = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const ref = useRef(null);
  const { isSidebarOpened, isTwoPageView, setIsSidebarOpened, setIsTwoPageView } = resultsStore();

  const toggleIsSidebarOpened = () => setIsSidebarOpened(!isSidebarOpened);

  const fullScreen = () => {
    document.documentElement.requestFullscreen();
  }

  const toggleModal = () => {
    setIsModalVisible(prev => !prev);
  }

  const toggleTwoPageView = () => {
    setIsTwoPageView(!isTwoPageView);
  }

  useClickOutside(ref, () => {
    setIsModalVisible(false);
  });

  return (
    <div ref={ref} className="relative flex items-center justify-center">
      <button className="active:scale-95" onClick={toggleModal}>
        <OptionsIcon className="h-4" />
      </button>

      {isModalVisible && (
        <div
          className="absolute top-5 right-0 z-10 flex flex-col items-stretch gap-4 bg-[#38343c] rounded py-4 pl-10 pr-5 text-sm text-white">
          <button className="w-max relative" onClick={toggleIsSidebarOpened}>
            {isSidebarOpened && <CheckIcon className="absolute -left-6 top-0.5 h-4"/>}
            Sidebar
          </button>
          <button className="w-max relative" onClick={toggleTwoPageView}>
            {isTwoPageView && <CheckIcon className="absolute -left-6 top-0.5 h-4"/>}
            Two page view
          </button>
          <button className="w-max" onClick={fullScreen}>
            Present mode
          </button>
        </div>
      )}
    </div>
  );
}