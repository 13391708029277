import { ChartSpline } from "./ChartSpline";

const getGraphDataBySubject = (subject) => {
  const totalGraphData = {
    categories: [
      400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520, 530, 540, 550, 560, 570, 580, 590,
      600, 610, 620, 630, 640, 650, 660, 670, 680, 690, 700, 710, 720, 730, 740, 750, 760, 770, 780, 790,
      800, 810, 820, 830, 840, 850, 860, 870, 880, 890, 900, 910, 920, 930, 940, 950, 960, 970, 980, 990,
      1000, 1010, 1020, 1030, 1040, 1050, 1060, 1070, 1080, 1090, 1100, 1110, 1120, 1130, 1140, 1150, 1160,
      1170, 1180, 1190, 1200, 1210, 1220, 1230, 1240, 1250, 1260, 1270, 1280, 1290, 1300, 1310, 1320, 1330,
      1340, 1350, 1360, 1370, 1380, 1390, 1400, 1410, 1420, 1430, 1440, 1450, 1460, 1470, 1480, 1490, 1500,
      1510, 1520, 1530, 1540, 1550, 1560, 1570, 1580, 1590, 1600
    ],
    percentile: [
      1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 3, 3, 4, 5, 6, 7, 8, 9, 10, 11,
      13, 14, 15, 17, 18, 19, 21, 22, 24, 25, 27, 28, 30, 32, 33, 35, 36, 38, 40, 41, 43, 45, 46, 48, 50, 51, 53, 55, 57, 58,
      60, 61, 63, 65, 66, 68, 69, 71, 72, 73, 75, 76, 77, 78, 80, 81, 82, 83, 84, 85, 86, 87, 87, 88, 89, 90, 90, 91, 92, 92,
      93, 94, 94, 95, 95, 96, 96, 97, 97, 97, 98, 98, 98, 99, 99, 99, 99.2, 99.4, 99.5, 99.7, 99.9
    ]
  };

  const mathGraphData = {
    categories: [
      200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350,
      360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510,
      520, 530, 540, 550, 560, 570, 580, 590, 600, 610, 620, 630, 640, 650, 660, 670,
      680, 690, 700, 710, 720, 730, 740, 750, 760, 770, 780, 790, 800
    ],
    percentile: [
      1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3, 4, 6, 7, 9, 12, 14,
      16, 19, 22, 24, 27, 30, 32, 35, 38, 41, 44, 47, 51, 55, 58,
      62, 64, 67, 70, 72, 75, 77, 79, 81, 83, 84, 86, 87, 89, 90,
      91, 92, 93, 94, 94, 95, 96, 97, 98, 99, 99.9
    ]
  };

  const readingAndWritingGraphData = {
    categories: [
      200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350,
      360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510,
      520, 530, 540, 550, 560, 570, 580, 590, 600, 610, 620, 630, 640, 650, 660, 670,
      680, 690, 700, 710, 720, 730, 740, 750, 760, 770, 780, 790, 800
    ],
    percentile: [
      1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3, 4, 6, 7, 9,
      12, 14, 17, 20, 23, 26, 29, 32, 35, 39, 42, 45, 49, 52, 55,
      58, 61, 64, 67, 70, 73, 76, 78, 81, 83, 85, 87, 89, 91, 92,
      93, 95, 96, 97, 97, 98, 99, 99, 99.3, 99.6, 99.9
    ]
  };

  switch (subject) {
    case 'Reading and Writing': return readingAndWritingGraphData;
    case 'Math': return mathGraphData;
    case 'Total': return totalGraphData;
    default: return totalGraphData;
  }
}

export const ScoreCharts = ({ subjectResults, className = '' }) => {
  return (
    <div className={className}>
      <div className="flex flex-row">
        {subjectResults.map((subjectResult) => {
          const { categories, percentile } = getGraphDataBySubject(subjectResult.name);

          return (
            <ChartSpline
              key={subjectResult.name}
              subject={subjectResult.name}
              tickAmount={6}
              categories={categories}
              chartData={[
                {
                  name: 'Percentile',
                  data: percentile,
                },
              ]}
              studentPosition={{
                score: subjectResult?.score,
                percentile: subjectResult?.percentile
              }}
            />
          )
        })}
      </div>
    </div>
  );
}