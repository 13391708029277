import { useNavigate, useSearchParams } from "react-router-dom";
import Lottie from "react-lottie";
import errorMessage from "../../../../assets/animations/errorMessage.json";

const lottie_errorMSG = {
  loop: false,
  autoplay: true,
  animationData: errorMessage,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const VerifyError = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const password = searchParams.get("password");

  const navigateToLogin = () => {
    navigate(`/login?email=${email}&password=${password}`);
  }

  return (
    <>
      <Lottie options={lottie_errorMSG} height={200} width={200} autoplay />

      <h1 className="text-2xl font-bold text-slate-900">Verification ERROR</h1>

      <p className="text-slate-700 text-center">
        You may have already verified this account. <br /> Please try logging in or contact support at: <br />
        <div className="my-4">
          <a className="px-2 py-2 bg-gray-100 rounded-md" href="mailto:support@accessiv.com?subject=ISSUE%3A%20Verify%20Account%20Link&body=I'm%20having%20issues%20when%20I%20click%20on%20the%20account%20link%20that%20was%20sent%20to%20my%20email.">
            support@assessiv.com
          </a>
        </div>
      </p>

      <button className="bg-green-500 text-white px-4 py-2 rounded-md mt-4" onClick={navigateToLogin}>
        Login
      </button>
    </>
  );
}