import { useEffect, useState } from "react";
import { OverviewSection } from "./OverviewSection";
import { DropDown } from "./Dropdown";
import { assessmentStore } from "../../../store";

export const ACCOMMODATIONS_OPTION  = {
  NONE: 'None',
  EXPENDED50: '50% Extended Time',
  EXPENDED100: '100% Extended Time',
  EXPENDED200: '200% Extended Time',
  UNTIMED: 'Untimed',
};

const textToSpeechOptions = ['System default voice', 'Enhanced high-quality voice', 'Slow-paced voice', 'Fast-paced voice'];
const zoomFontSizeOptions = ['Level 0 / 12px', 'Level 1 / 14px', 'Level 2 / 16px', 'Level 3 / 18px', 'Level 4 / 20px', 'Level 5 / 22px', 'Level 6 / 24px'];
const colorContrastOptions = ['Black on white', 'White on black', 'Blue on white', 'White on blue', 'Yellow on black', 'Black on yellow'];
const mousePointerOptions = ['System default', 'Large black pointer', 'Large white pointer', 'Highlighted circle pointer', 'Crosshair pointer', 'Hand icon'];
const streamlineModeOptions = ['Basic view', 'Minimalist mode', 'Focus mode', 'Essential components only'];
const timingOptions = Object.values(ACCOMMODATIONS_OPTION);

export const extendTimeByAccommodations = (time, accommodations) => {
  switch (accommodations) {
    case ACCOMMODATIONS_OPTION.EXPENDED50: return time + (time / 2);
    case ACCOMMODATIONS_OPTION.EXPENDED100: return time * 2;
    case ACCOMMODATIONS_OPTION.EXPENDED200: return time * 3;
    default: return time;
  }
}

export const ViewCustomisation = () => {
  const { accommodations, setAccommodations } = assessmentStore();
  const [textToSpeech, setTextToSpeech] = useState(textToSpeechOptions[0]);
  // const [zoomFontSize, setZoomFontSize] = useState(zoomFontSizeOptions[0]);
  // const [colorContrast, setColorContrast] = useState(colorContrastOptions[0]);
  // const [mousePointer, setMousePointer] = useState(mousePointerOptions[0]);
  // const [streamlineMode, setStreamlineMode] = useState(streamlineModeOptions[0]);

  useEffect(() => {
    setAccommodations(ACCOMMODATIONS_OPTION.NONE);
  }, []);

  return (
    <OverviewSection order={1} title="Customize the view">
      <div className="flex flex-col gap-6 mt-2">
        <div className="flex flex-col md:flex-row md:items-start gap-6">
          <DropDown
            containerClassName="flex-1"
            title="Accommodations"
            selectedOption={accommodations}
            onSelectOption={setAccommodations}
            options={timingOptions}
          />
          <DropDown
            disable
            containerClassName="flex-1 opacity-0 pointer-events-0"
            title="Text-to-Speech/Assistive Technology"
            selectedOption={textToSpeech}
            onSelectOption={setTextToSpeech}
            options={textToSpeechOptions}
          />
        </div>

        {/*<div className="flex flex-col md:flex-row md:items-start gap-6">*/}
        {/*  <DropDown*/}
        {/*    disable*/}
        {/*    containerClassName="flex-1"*/}
        {/*    title="Zoom / Font size"*/}
        {/*    selectedOption={zoomFontSize}*/}
        {/*    onSelectOption={setZoomFontSize}*/}
        {/*    options={zoomFontSizeOptions}*/}
        {/*  />*/}
        {/*  <DropDown*/}
        {/*    disable*/}
        {/*    containerClassName="flex-1"*/}
        {/*    title="Color contrast"*/}
        {/*    selectedOption={colorContrast}*/}
        {/*    onSelectOption={setColorContrast}*/}
        {/*    options={colorContrastOptions}*/}
        {/*  />*/}
        {/*</div>*/}

        {/*<div className="flex flex-col md:flex-row md:items-start gap-6">*/}
        {/*  <DropDown*/}
        {/*    disable*/}
        {/*    containerClassName="flex-1"*/}
        {/*    title="Mouse pointer"*/}
        {/*    selectedOption={mousePointer}*/}
        {/*    onSelectOption={setMousePointer}*/}
        {/*    options={mousePointerOptions}*/}
        {/*  />*/}
        {/*  <DropDown*/}
        {/*    disable*/}
        {/*    containerClassName="flex-1"*/}
        {/*    title="Streamline mode"*/}
        {/*    selectedOption={streamlineMode}*/}
        {/*    onSelectOption={setStreamlineMode}*/}
        {/*    options={streamlineModeOptions}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </OverviewSection>
  );
}