import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Lottie from "react-lottie";
import { ReactComponent as Logo } from "../../../assets/logo_blue.svg";
import Loader from "../../../assets/animations/circlesLoader.json";
import happyStudent from "../../../assets/happy_student.jpeg";
import { NavigateToLandingLink } from "../components/NavigateToLandingLink";
import { AnimIn } from "../../../components/mountAnim";
import { PasswordInput } from "../../../components/PasswordInput";
import { login } from "../../../helpers/authentication.ts";

const buttonStyle = "block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-10 px-4";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const emailParam = searchParams.get('email');
  const passwordParam = searchParams.get('password');

  function handleLgnSubmit(e) {
    e.preventDefault();
    const targetVal = e.target;
    const postData = { email: targetVal.email.value, password: targetVal.password.value };
    setIsLoading(true);

    login(postData, navigate)
      .finally(() => {
        setIsLoading(false);
      });
  }

  const navigateToRegisterPage = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate('/register');
  }

  const navigateToResetPage = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate('/reset');
  }

  return (
    <>
      <div className="flex flex-row h-screen bg-slate-500 w-screen">
        <div className="flex flex-col justify-between items-center lg:items-start h-full p-[2em] md:p-14 bg-white lg:w-[33em] w-full">
          <NavigateToLandingLink>
            <Logo className="w-93 h-24" />
          </NavigateToLandingLink>

          <AnimIn isVisible={true}>
            <form className="w-full" onSubmit={(e) => handleLgnSubmit(e)}>
              <label htmlFor="email" className="block text-2xl font-medium text-gray-700">
                Login
              </label>

              <p className="text-sm text-gray-500 mt-12">
                Start building your confidence and take an exam without fear with assessments.
              </p>

              <div className="mt-32">
                <input
                  required
                  name="email"
                  type="email"
                  placeholder="Email address"
                  defaultValue={emailParam ?? ''}
                  className={buttonStyle}
                />
              </div>

              <div className="mt-32">
                <PasswordInput
                  required
                  name="password"
                  placeholder="Password"
                  defaultValue={passwordParam ?? ''}
                  className={buttonStyle}
                />
              </div>

              <button className="mt-32 bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg w-full">
                {isLoading ?
                  <Lottie options={defaultOptions} height={24} width={40} isStopped={false} isPaused={false} />
                  : <span className="py-2 px-4 text-md">LOGIN</span>}
              </button>

              <div className="text-center -mt-4">
                <p className="text-sm text-gray-500 mt-12">Forgot your password?</p>
                <button
                  type="button"
                  className="text-sm font-bold text-blue-600 cursor-pointer"
                  onClick={navigateToResetPage}
                >
                  Reset
                </button>
              </div>

              <div className="text-center -mt-6">
                <p className="text-sm text-gray-500 mt-12">Don't have an account?</p>
                <button
                  type="button"
                  className="text-sm font-bold text-blue-600 cursor-pointer"
                  onClick={navigateToRegisterPage}
                >
                  Create an account for free
                </button>
              </div>
            </form>
          </AnimIn>

          <p className="text-sm text-gray-500 mt-12">All rights reserved.</p>
        </div>

        <AnimIn isVisible={true} className="w-full flex flex-1">
          <img alt="" src={happyStudent} className="h-screen w-full bg-auto bg-no-repeat bg-center object-cover" />
        </AnimIn>
      </div>
    </>
  );
}
