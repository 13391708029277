import { useEffect } from "react";
import sumIcon from "../../../../../../assets/studentPage/sumIcon.png";
import fileAddIcon from "../../../../../../assets/studentPage/file-add.png";
import likeIcon from "../../../../../../assets/studentPage/like.png";
import statsIcon from "../../../../../../assets/studentPage/stats.png";
import { assessmentStore } from "../../../../../../store";
import {
  getEducatorStudentsCompletedInAWeekCount,
  getEducatorStudentsCompletionRate,
  getEducatorStudentsCount,
  getEducatorStudentsLoggedInAWeekCount
} from "../../../../../../actions/dashboard";

const EducatorScoresTile = ({ icon, value, text }) => {
  return (
    <div className="flex items-start gap-4 p-6 text-gray-500 bg-white border border-softCloud rounded-lg flex-1 min-w-[250px] lg:min-w-0 shadow-sm">
      <img className="w-12 h-12 flex-shrink-0" src={icon} alt="icon"/>
      <div className="flex flex-col justify-between gap-1 self-stretch">
        <div className="text-sm">{text}</div>
        <div className="text-xs">
          {typeof value !== 'undefined' ? (
            <span className="text-black font-medium text-2xl">{value}</span>
          ) : (
            <div className="inline-block bg-winterMist rounded w-16 h-8 text-transparent font-medium text-2xl">.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export const fetchEducatorsScores = () => {
  const { setEducatorScores } = assessmentStore.getState();

  Promise.allSettled([
    getEducatorStudentsCount(),
    getEducatorStudentsCompletedInAWeekCount(),
    getEducatorStudentsLoggedInAWeekCount(),
    getEducatorStudentsCompletionRate()
  ])
    .then((data) => {
      const [allStudentCount, studentCompletedInAWeek, studentsLoggedInAWeek, studentsCompletionRate] = data;
      setEducatorScores?.({
        allStudentCount: allStudentCount?.value?.data,
        studentCompletedInAWeek: studentCompletedInAWeek?.value?.data,
        studentsLoggedInAWeek: studentsLoggedInAWeek?.value?.data,
        studentsCompletionRate: studentsCompletionRate?.value?.data
      });
    })
    .catch((error) => console.log(error));
}

export const EducatorScores = () => {
  const {
    allStudentCount,
    studentCompletedInAWeek,
    studentsLoggedInAWeek,
    studentsCompletionRate
  } = assessmentStore(store => store.educatorScores);

  useEffect(() => {
    fetchEducatorsScores();
  }, []);

  return (
    <div className="flex flex-col md:flex-row md:flex-wrap lg:flex-nowrap items-stretch gap-6">
      <EducatorScoresTile
        text="Your students"
        icon={sumIcon}
        value={allStudentCount ?? '-'}
      />

      <EducatorScoresTile
        text="Assessments completed this week"
        icon={fileAddIcon}
        value={studentCompletedInAWeek ?? '-'}
      />

      <EducatorScoresTile
        text="Unique student logins this week"
        icon={likeIcon}
        value={studentsLoggedInAWeek ?? '-'}
      />

      <EducatorScoresTile
        text="Completion rate for all current students"
        icon={statsIcon}
        value={studentsCompletionRate ?? '-'}
      />
    </div>
  );
}