import { useEffect, useRef, useState } from "react";

import { AssessmentResultsTopBar } from "./components/assessmentResultsTopBar";
import { Sidebar } from "./components/sidebar";
import { useUpdatePageIdOnScroll } from "./hooks/useUpdatePageIdOnScroll";
import { useSaveFullScreenChange } from "./hooks/useSaveFullScreenChange";

import { ScoreSummary } from "./sections/scoreSummary";
import { ScoreTiles } from "./sections/scoreTiles";
import { SectionDomains } from "./sections/sectionDomains";
import { QuestionTypeAnalysis } from "./sections/questionTypeAnalysis";
import { ModulesOverview } from "./sections/modulesOverview";
import { ScoreCharts } from "./sections/scoreCharts";
import { QuestionsTable } from "./sections/questionsTable";

import { fetchReport, orderSubjects } from "./utils";
import { assessmentStore, resultsStore } from "../../store";

export default function AssessmentResults() {
  const [zoomLevel, setZoomLevel] = useState(100);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);
  const pageListRef = useRef(null);
  const containerRef = useRef(null);

  const results = assessmentStore(state => state.results);
  const isPresentMode = resultsStore(state => state.isPresentMode);
  const isTwoPageView = resultsStore(state => state.isTwoPageView);
  const setIsPresentMode = resultsStore(state => state.setIsPresentMode);

  const sortedSubjectResults = orderSubjects(results?.subjectResults ?? []);

  useEffect(() => {
    setIsPresentMode(false);
    fetchReport()
      .then(() => {
        setIsFetching(false);
      })
      .catch((err) => {
        setIsError(true);
        console.log(err);
      });
  }, []);

  useUpdatePageIdOnScroll(containerRef);

  useSaveFullScreenChange();

  return (
    <div className="absolute h-full w-full flex flex-col">
      {!isPresentMode && (
        <AssessmentResultsTopBar
          zoomLevel={zoomLevel}
          zoomIn={() => setZoomLevel(zoomLevel + 10)}
          zoomOut={() => setZoomLevel(zoomLevel - 10)}
        />
      )}

      <div className="relative h-full w-full overflow-y-auto print:overflow-visible flex flex-1">
        <Sidebar
          pageContainerRef={pageListRef}
          isFetching={isFetching}
        />

        <div className="flex flex-1 bg-white h-full relative">
          <div ref={containerRef} className="absolute h-full w-full overflow-auto print:overflow-visible bg-slate-200">
            <div ref={pageListRef} className={`print:scale-100 ease-in duration-150 zoomWrapper mx-auto ${isTwoPageView ? 'grid grid-cols-2 gap-4 w-max origin-top' : 'w-[210mm] origin-top'}`} style={{ scale: (zoomLevel + "%") }}>
              {results ? (
                <>
                  <div className="page" id="download-page-id-1">
                    <div className="subpage">
                      <ScoreSummary
                        classname="mt-4"
                        results={results}
                      />

                      <ScoreTiles
                        className="my-12"
                        sortedSubjectResults={sortedSubjectResults}
                      />

                      <ScoreCharts
                        className="my-4"
                        subjectResults={sortedSubjectResults}
                      />

                      <ModulesOverview sectionCumulatives={results?.sectionCumulatives} />
                    </div>
                  </div>

                  <div className="page" id="download-page-id-2">
                    <div className="subpage">
                      <SectionDomains tagSections={results?.tagSections} />

                      <QuestionTypeAnalysis questionTypes={results?.questionTypes ?? []} />
                    </div>
                  </div>

                  {results?.questionsReports?.map?.((questionReport, index) => (
                    <div className="page" id={`download-page-id-${index + 3}`} key={questionReport.sectionName}>
                      <div className="subpage">
                        <QuestionsTable
                          respSection={questionReport.sectionName}
                          respAnswers={questionReport?.questionResponses}
                        />
                      </div>
                    </div>
                  ))}

                  {/*{results?.sectionCumulatives?.length !== 1 && (*/}
                  {/*  <>*/}
                  {/*    <div className="page" id="download-page-id-7">*/}
                  {/*      <div className="subpage">*/}
                  {/*        <ReadingGoals/>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}

                  {/*    <div className="page" id="download-page-id-8">*/}
                  {/*      <div className="subpage">*/}
                  {/*        <PersonalizedActionPlan/>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </>*/}
                  {/*)}*/}
                </>
              ) : (
                <div className="w-full text-center text-xl text-slate-500 mt-32">
                  {isError ? 'Error' : 'Loading...'}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
