import { useState } from "react";
import toast from "react-hot-toast";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { Modal } from "../../../components/Modal";
import { questionStore } from "../../../store";
import { reportQuestion } from "../../../actions/assessment";

export const ReportQuestion = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [reportText, setReportText] = useState('');

  const questionSelected = questionStore(state => state.questionSelected);

  const copyIdToClipboard = () => {
    if (questionSelected) {
      navigator.clipboard
        .writeText(questionSelected)
        .catch((error) => {
          console.error(error);
        });
    }
  }

  const handleConfirmReport = () => {
    if (!!questionSelected?.length && !!reportText?.length) {
      toast.promise(
        reportQuestion(questionSelected, reportText),
        {
          loading: 'Loading...',
          success: 'Report submitted successfully!',
          error: 'Error when reporting question.',
        }
      )
        .then(() => {
          setIsModalOpened(false);
          setReportText('');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <>
      <button
        className="flex items-center gap-1 self-stretch rounded border-2 border-burntRose text-sm text-burntRose py-1 px-2"
        onClick={() => setIsModalOpened(true)}
      >
        <ExclamationCircleIcon className="h-4 stroke-1 stroke-burntRose fill-white scale-125" />
        Report
      </button>

      {isModalOpened && (
        <Modal
          handleConfirm={handleConfirmReport}
          handleCloseModal={() => setIsModalOpened(false)}
          isConfirmButtonDisabled={!reportText.length}
          title={
            <div className="flex items-center">
              Report
              <div className="flex items-center gap-0.5">
                &nbsp;-&nbsp;{questionSelected}
                <span className="cursor-pointer hover:bg-gray-200 active:bg-gray-300 p-0.5 rounded" onClick={copyIdToClipboard}>
                  <ClipboardDocumentCheckIcon className="h-4" />
                </span>
              </div>
            </div>
          }
          confirmButtonText="Report"
          confirmButtonBackground="bg-burntRose"
        >
          <div className="flex flex-col gap-3">
            <p className="text-sm text-duskyHarbor">Report an issue with this question</p>

            <textarea
              rows={5}
              className="block resize-none w-full !ring-0 text-black rounded-md border border-gray-300 focus:border-gray-300 shadow-sm focus:ring-0 sm:text-sm px-4 scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-lg"
              value={reportText}
              onChange={(event) => setReportText(event.target.value)}
            />
          </div>
        </Modal>
      )}
    </>
  );
}