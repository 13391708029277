import { useState } from "react";
import { frequencies, tiers } from "./constants";
import { FrequencySwitch } from "./FrequencySwitch";
import { PlanCard } from "./PlanCard";

export const PlansSection = () => {
  const [frequency, setFrequency] = useState(frequencies[0]);

  return (
    <>
      <h2 className="text-3xl text-center font-semibold leading-7 text-indigo-600 my-8 mt-4">Pricing</h2>

      <div className="my-8 flex justify-center">
        <FrequencySwitch
          frequency={frequency}
          setFrequency={setFrequency}
        />
      </div>

      <div className="mt-10 max-w-md flex flex-wrap items-stretch justify-center gap-8 lg:max-w-none">
        {tiers.map(tier => (
          <PlanCard
            key={tier.id}
            plan={tier}
            frequency={frequency}
          />
        ))}
      </div>
    </>
  );
}