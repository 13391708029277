import { useState } from "react";
import { useNavigate } from "react-router-dom";
import student from "../../../../../../assets/studentPage/purple-clother-student.png";
import swirlingLine from "../../../../../../assets/studentPage/swirling-gray-line.png";
import { Modal } from "../../../../../../components/Modal";
import { isStudent } from "../../../../../../helpers/authentication.ts";

const InsertCodeAIDModal = ({ handleCloseModal, handleConfirm }) => {
  const [assessmentId, setAssessmentId] = useState("");

  const handleOnChangeInput = (event) => {
    setAssessmentId(event.target.value.slice(0, 8));
  };

  return (
    <Modal title="Use Assesment ID" isConfirmButtonDisabled={assessmentId.length < 8} confirmButtonText="Start assessment" cancelButtonText="Cancel" handleCloseModal={handleCloseModal} handleConfirm={handleConfirm}>
      <p className="text-sm text-duskyHarbor">You can use the asssesment ID to start an assesment. You should ask the teacher for the specific ID in order to start.</p>

      <div className="flex flex-col gap-2 irems-stretch">
        <h2 className="text-sm text-navySmoke font-medium">Assessment ID</h2>

        <div className="flex items-stretch rounded border border-whisperBlue overflow-hidden">
          <div className="flex items-center justify-center w-12 h-10 bg-lightGrey text-base text-fadedDenim border-r border-whisperBlue">AID</div>

          <input type="number" className="py-2 px-4 flex-1 text-base text-navySmoke placeholder:text-fadedDenim border-0 focus:ring-0 remove-input-arrows" placeholder="ie. 29592104" value={assessmentId} onChange={handleOnChangeInput} />
        </div>

        <span className="text-xs text-duskyHarbor">This is a eight number that starts with AID.</span>
      </div>

      <button className="text-sm font-medium text-brilliantSapphire">Can’t find the ID?</button>
    </Modal>
  );
};

export const UseCodeAID = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isUserStudent = isStudent();

  const handleOpenModal = () => {
    if (isUserStudent) {
      setIsModalOpen(true);
    } else {
      navigate("/assessiv/invite");
    }
  };

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className="flex flex-col items-center gap-4 py-4 text-gray-500 bg-white border border-softCloud rounded-lg shadow-sm">
      <div className="relative self-stretch">
        <img className="mx-auto relative z-10" src={student} alt="image" />
        <img className="absolute top-3" src={swirlingLine} alt="line" />
        <div className="absolute top-8 right-16 z-20 w-[6px] h-[6px] bg-skyBlue rounded-full" />
        <div className="absolute top-20 left-16 z-20 w-[14px] h-[14px] bg-skyBlue rounded-full" />
      </div>

      <button className="text-white py-2.5 px-10 text-sm font-medium bg-skyBlue rounded-lg" onClick={handleOpenModal}>
        {isUserStudent ? "Use code AID" : "+ Send Assignment"}
      </button>

      {isModalOpen && isUserStudent && <InsertCodeAIDModal handleConfirm={handleCloseModal} handleCloseModal={handleCloseModal} />}
    </div>
  );
};
