import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../assets/rt-arrow.svg";

export const AssessmentOverviewBreadcrumbs = ({ assessmentName, handleNavigateHome }) => {
  return (
    <div className="flex items-center gap-4 text-duskyHarbor text-sm">
      <Link to="/dashboard" className="font-medium">
        Home
      </Link>
      <Arrow className="w-4 h-4" />
      {assessmentName}
    </div>
  );
}