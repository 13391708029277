import { useCallback, useEffect, useRef } from "react";
import { resultsStore } from "../../../store";

export const useUpdatePageIdOnScroll = (containerRef) => {
  const setCurrentId = resultsStore(state => state.setCurrentId);

  const lastCalledIdRef = useRef(null);

  const handleScrollToSidebarItem = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ block: 'nearest' });
    }
  };

  const handlePageContainerOnScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;

    const visibleTop = container.scrollTop;
    const upperHalfBottom = visibleTop + (container.clientHeight / 2);

    let mostSuitableElement = null;
    let closestDistance = Number.MAX_VALUE;

    Array.from(container.firstChild.children).forEach((child) => {
      if (child.id.startsWith('download-page-id-')) {
        const childTop = child.offsetTop;
        const distance = Math.abs(childTop - visibleTop);

        if (childTop <= upperHalfBottom && distance < closestDistance) {
          closestDistance = distance;
          mostSuitableElement = child;
        }
      }
    });

    if (mostSuitableElement && mostSuitableElement.id !== lastCalledIdRef.current) {
      lastCalledIdRef.current = mostSuitableElement.id;
      setCurrentId((oldId) => {
        if (oldId !== mostSuitableElement.id) {
          handleScrollToSidebarItem(`sidebar-id-${mostSuitableElement.id.split('-')[3]}`);
          return mostSuitableElement.id;
        }

        return oldId;
      })
    }
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('scroll', handlePageContainerOnScroll);

    return () => {
      container.removeEventListener('scroll', handlePageContainerOnScroll);
    };
  }, [handlePageContainerOnScroll]);
}