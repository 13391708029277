import { Fragment, useEffect, useMemo, useState } from 'react';
import Lottie from "react-lottie";
import { Popover, Transition } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/24/solid';
import { EnvelopeOpenIcon } from '@heroicons/react/24/outline';
import * as signalR from '@microsoft/signalr';
import { Tooltip } from "./Tooltip";
import noNotificationsAnimationData from '../assets/animations/bellOutline.json';
import { ReactComponent as CloseCrossIcon } from "../assets/close-cross.svg";
import { NotifyBell } from "../pages/dashboard/components/utilityComponents";
import { fetchNotifications, markNotificationAsRead } from "../actions/notifications";
import { getAccessTokenFromLocalStorage } from "../helpers/authentication.ts";
import { formatDateWithDynamicTimeZone } from "../helpers/date.ts";
import { assessmentStore } from "../store";
import { Skeleton } from "./Skeleton";

const options = {
  loop: false,
  autoPlay: true,
  animationData: noNotificationsAnimationData,
  style: {
    width: 150,
    height: 150,
    alignSelf: 'center',
  }
};

const Notification = ({ id, message, createdDate, readNotification }) => {
  return (
    <button
      className="group/notification flex gap-3 pl-1 pr-2 py-2 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
    >
      <BellIcon className="h-8 w-8 scale-125 text-[rgb(244,191,68)] -mt-1" />
      <div className="flex flex-col gap-1">
        <div className="flex items-baseline justify-between gap-1">
          <span className="text-black font-semibold">Message</span>
          <button
            className="opacity-0 group-hover/notification:opacity-100 transition relative -top-0.5 text-red-400 bg-transparent hover:bg-red-100 rounded text-sm w-max h-6 px-1 inline-flex justify-center items-center"
            onClick={readNotification(id)}
          >
            Delete
          </button>
        </div>
        <p className="text-sm text-gray-700 text-left">
          {message}
        </p>
        <div className="flex items-baseline justify-between gap-1 text-black font-semibold">
          <span className="text-xs text-gray-400 font-normal">{formatDateWithDynamicTimeZone(createdDate)}</span>
        </div>
      </div>
    </button>
  );
}

export const Notifications = () => {
  const { notifications, setNotifications } = assessmentStore();
  const [isFetching, setIsFetching] = useState(!notifications?.length);

  const unreadNotifications = useMemo(
    () => notifications?.filter(notification => !notification.markRead) ?? [],
    [notifications]
  );

  const handleUploadNotifications = () => {
    fetchNotifications()
      .then((data) => setNotifications(data?.data ?? []))
      .finally(() => setIsFetching(false));
  }

  const deleteNotifications = (notificationsIds) =>
    markNotificationAsRead(notificationsIds)
      .then(handleUploadNotifications);

  const readNotification = (id) => () => {
    if (id) {
      deleteNotifications([id])
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const readAllNotifications = () => {
    const unreadNotificationsIds = unreadNotifications?.map(notification => notification.id);
    if (unreadNotificationsIds?.length) {
      deleteNotifications(unreadNotificationsIds)
        .catch((error) => {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    handleUploadNotifications();

    const token = getAccessTokenFromLocalStorage();

    if (token && typeof token === 'string') {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`https://dev.assessiv.com/Notification`, {
          accessTokenFactory: () => token
        })
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      connection.start()
        .then(() => console.log("Connected!"))
        .catch(err => console.error("Connection failed: ", err));

      connection.on("NOTIFICATION_EVENT", (notification) => {
        console.log("New notification:", notification);
        setNotifications(prevNotifications => [...prevNotifications, notification]);
      });

      return () => {
        connection.stop()
          .then(() => console.log("Connection stopped"))
          .catch(err => console.error("Error while stopping connection: ", err));
      };
    }
  }, []);

  return (
    <Popover className="relative">
      {({open, close }) => (
        <>
          <Popover.Button
            className={`${
              open ? '' : 'text-opacity-90'
            } text-white group rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <NotifyBell
              flagUp={!!unreadNotifications.length}
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-screen max-w-lg px-4 mt-5 transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-sm">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative grid gap-3 bg-white p-7 lg:grid-cols-1 max-h-96 overflow-y-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-lg">
                  <div className="flex items-center gap-2">
                    <h2 className="text-lg font-semibold mr-auto">
                      What's new?
                    </h2>
                    {!!unreadNotifications.length && (
                      <Tooltip tooltipPopupClassName="w-[96px]" text="Delete all">
                        <button
                          className="text-gray-400 bg-transparent hover:bg-red-100 hover:text-red-400 rounded text-sm w-6 h-6 inline-flex justify-center items-center"
                          onClick={readAllNotifications}
                        >
                          <EnvelopeOpenIcon className="h-[18px]"/>
                        </button>
                      </Tooltip>
                    )}
                    <Tooltip text="Close">
                      <button
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm w-6 h-6 inline-flex justify-center items-center"
                        data-modal-hide="staticModal"
                        onClick={close}
                      >
                        <CloseCrossIcon className="w-3 h-3"/>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </Tooltip>
                  </div>
                  {isFetching ? (
                    Array.from({ length: 3 }, (_, index) => (
                      <Skeleton key={index} className="w-full h-[124px]" />
                    ))
                  ) : (
                    <>
                      {unreadNotifications.length ? (
                        unreadNotifications.map((notification) => (
                          <Notification
                            key={notification?.id}
                            readNotification={readNotification}
                            {...notification}
                          />
                        ))
                      ) : (
                        <div className="flex flex-col items-center">
                          <div className="w-36 h-36">
                            <Lottie options={options} />
                          </div>
                          <span className="text-center text-gray-400 text-sm">
                        No updates yet!
                      </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}