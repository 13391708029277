import { useRef } from "react";
import searchIcon from "../assets/studentPage/searchIcon.svg";

export const Search = ({ searchString, onChangeSearchString, className = '', inputClassName = '' }) => {
  const inputRef = useRef(null);

  const handleFocusOnSearchInput = () => {
    inputRef.current.focus();
  };

  const handleOnChangeSearchInput = (event) => {
    onChangeSearchString?.(event.target.value);
  };

  return (
    <div
      className={`flex flex-row border border-slate-300 rounded-lg p-1 items-center cursor-text ${className}`}
      onClick={handleFocusOnSearchInput}
    >
      <img draggable={false} src={searchIcon} className="h-4 w-4 ml-2 mr-4" alt="logo" />
      <input
        ref={inputRef}
        type="text"
        className={`m-0 p-0 focus:ring-0 border-0 text-gray-600 placeholder:text-slate-400 h-6 ${inputClassName}`}
        placeholder="Search"
        value={searchString}
        onChange={handleOnChangeSearchInput}
      />
    </div>
  );
}