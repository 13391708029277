import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "./PrimaryButton";
import { StartFromBeginning } from "./StartFromBeginning";
import { assessmentStore, questionStore } from "../../../../store";
import { ASSESSMENT_STATUS } from "../../../../helpers/constants";
import {
  configureUserAssessment,
  fetchUserAssessmentList,
  postAssessmentSettings
} from "../../../../actions/assessment";

export const AssessmentInfoActions= ({ status }) => {
  const navigate = useNavigate();
  const { setIsTimerPaused, setQuestionSelected } = questionStore();
  const {
    assessmentId,
    assessmentVersionId,
    educatorId,
    studentCompletedVersions,
    accommodations,
    setAssessments,
    setEducatorId,
    setSubSectionData,
    setResultsReady,
    setSectionData,
  } = assessmentStore();

  const completedVersionsEnable = useMemo(() => {
    return !!(studentCompletedVersions?.filter((version) =>
      version?.status === ASSESSMENT_STATUS.COMPLETED) ?? []).length;
  }, [studentCompletedVersions]);

  const navigateToAssessment = () => {
    setQuestionSelected('');
    setSectionData([]);
    setSubSectionData([]);
    setResultsReady(false);
    setIsTimerPaused(false);
    localStorage.removeItem('markedQuestions');
    navigate('/dashboard/assessment');
  };

  const handleStartAssessment = (isStartNewAssessment) => () => {
    if (educatorId?.length) {
      if (isStartNewAssessment) {
        postAssessmentSettings({
          assessivId: assessmentId,
          assessivVersionId: assessmentVersionId,
          educatorId: educatorId,
          properties: {
            'Accommodations': accommodations ?? 'None'
          }
        })
          .then(() => {
            navigateToAssessment();
          })
          .catch(console.log);
      } else {
        navigateToAssessment();
      }
    } else {
      configureUserAssessment({ assessivId: assessmentId, assessivVersionId: assessmentVersionId })
        .then(() => {
          fetchUserAssessmentList()
            .then(({ data }) => {
              const newEducatorId = data?.find((assessment) =>
                assessment?.assessmentId === assessmentId?.toLowerCase() &&
                assessment?.assessmentVersionId === assessmentVersionId
              )?.educatorId;

              if (newEducatorId) {
                Promise.all([
                  setEducatorId(newEducatorId),
                  setAssessments(data),
                  ...(isStartNewAssessment ? [postAssessmentSettings({
                    assessivId: assessmentId,
                    assessivVersionId: assessmentVersionId,
                    educatorId: newEducatorId,
                    properties: {
                      'Accommodations': accommodations ?? 'None'
                    }
                  })] : []),
                ]).then(navigateToAssessment);
              }
            });
        });
    }
  }

  switch (status) {
    case ASSESSMENT_STATUS.FRESH: return (
      <PrimaryButton onClick={handleStartAssessment(true)}>
        {completedVersionsEnable ? 'Retake' : 'Start the assessment'}
      </PrimaryButton>
    );
    case ASSESSMENT_STATUS.COMPLETED:
    case ASSESSMENT_STATUS.SUSPENDED: return (
      <PrimaryButton onClick={handleStartAssessment(true)}>
        Retake
      </PrimaryButton>
    );
    default: return (
      <>
        <PrimaryButton onClick={handleStartAssessment(false)}>
          Continue
        </PrimaryButton>

        <StartFromBeginning handleStartAssessment={handleStartAssessment(true)} />
      </>
    );
  }
}