import { Select } from "./Select";

export const ASSESSIV_PLAN = {
  COMPANY_EARLY_BIRD: 'Company Early Bird',
  COMPANY_DEFAULT: 'Company Default',
};

const assessivPlanOptions = [
  ASSESSIV_PLAN.COMPANY_EARLY_BIRD,
  ASSESSIV_PLAN.COMPANY_DEFAULT,
];

export const AssessivPlanSelect = ({ assessivPlan, setAssessivPlan, }) => {
  return (
    <Select
      containerClassName="flex-1"
      title="Assessiv plan"
      selectedOption={assessivPlan}
      onSelectOption={setAssessivPlan}
      options={assessivPlanOptions}
    />
  );
}