import { useNavigate } from "react-router-dom";

export const NavigateToLandingLink = ({ className = '', children}) => {
  const navigate = useNavigate();

  const handleNavigateToLanding = () => {
    navigate('/');
  }

  return (
    <div className={`cursor-pointer ${className}`} onClick={handleNavigateToLanding}>
      {children}
    </div>
  );
}