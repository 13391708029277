import Lottie from "react-lottie";
import loaderMessage from "../../../../assets/animations/loadingRoller.json";

const lottie_loaderMSG = {
  loop: true,
  autoplay: true,
  animationData: loaderMessage,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const VerifyLoading = () => {
  return (
    <>
      <Lottie options={lottie_loaderMSG} height={200} width={200} autoplay />
      <h1 className="text-2xl font-bold text-slate-900">Checking Verification</h1>
    </>
  );
}