import { useEffect, useMemo, useState } from "react";
import { ManageUsersModal } from "./ManageUsersModal";
import { getRole } from "../../../../../../../../../helpers/authentication.ts";
import { fetchUsers, searchUser } from "../../../../../../../../../actions/user";

const defaultListSize = 10;

const debounce = (func, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

const debouncedSearchUser = debounce((newValue, setUsers, setIsLoading, pageSize) => {
  (newValue ? searchUser(newValue) : fetchUsers(pageSize, 0))
    .then((data) => {
      setUsers(data?.data ?? []);
    })
    .finally(() => setIsLoading(false));
}, 1000);

export const ManageStudents = ({ groupId, students, isModalVisible, setIsModalVisible }) => {
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageSize, setPageSIze] = useState(defaultListSize);
  const role = getRole();

  const addedUsersId = useMemo(
    () => students.map(user => user.userId),
    [students]
  );

  const filteredFetchedUsers = useMemo(() => {
    return users?.res?.filter(user => !addedUsersId.includes(user.userId)) ?? []
  }, [users, addedUsersId]);

  const filteredStudents = useMemo(() => {
    return students?.filter(student =>
      student?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
      student?.name?.toLowerCase()?.includes(search.toLowerCase())
    ) ?? [];
  }, [students, search]);

  const handleOnChangeSearch = (newValue) => {
    setSearch(newValue);
    setIsSearchLoading(true);
    debouncedSearchUser(newValue, setUsers, setIsSearchLoading, pageSize);
  }

  const openModal = () => setIsModalVisible(true);

  const closeModal = () => {
    setSearch('');
    setIsModalVisible(false);
  };

  const handleScroll = (event) => {
    const target = event.currentTarget;
    const isAtBottom = target.scrollHeight - target.scrollTop <= target.clientHeight + 10;

    if (!search.length && isAtBottom && users?.totalCount > pageSize && !isLoading) {
      setIsLoading(true);
      const newPageSize = pageSize + defaultListSize;
      setPageSIze(newPageSize);
      fetchUsers(newPageSize, 0)
        .then((data) => {
          setUsers(data?.data ?? []);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleCloseModal = () => {
    closeModal();
    setSearch('');
    setIsModalVisible(false);
    setUsers([]);
  };

  useEffect(() => {
    if (isModalVisible) {
      setIsLoading(true);
      fetchUsers(pageSize, 0)
        .then((data) => {
          setUsers(data?.data ?? []);
        })
        .finally(() => setIsLoading(false));
    }

    return () => {
      setPageSIze(defaultListSize);
    }
  }, [isModalVisible]);

  return (
    <>
      <button className="text-blue-600 text-sm text-left" onClick={openModal}>
        {role === 'SubEducator' ? 'View' : 'Manage'} students
      </button>

      {isModalVisible && (
        <ManageUsersModal
          showActions={role !== 'SubEducator'}
          title={`${role === 'SubEducator' ? 'View' : 'Manage'} students`}
          addButtonClassName="text-blue-600"
          emptyText="No students"
          fetchedUsers={filteredFetchedUsers}
          users={filteredStudents}
          search={search}
          isLoading={isLoading}
          isSearchLoading={isSearchLoading}
          groupId={groupId}
          handleScroll={handleScroll}
          handleCloseModal={handleCloseModal}
          handleOnChangeSearch={handleOnChangeSearch}
        />
      )}
    </>
  );
}