import { useMemo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { ReactComponent as TriangleIcon } from "../assets/tooltip-triangle.svg";

export const TOOL_TIP_POSITION = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
};

const ToolTipPopup = ({ isVisible, text, position, className, anchorRef }) => {
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current && anchorRef.current) {
      const { left, top, width, height } = anchorRef.current.getBoundingClientRect();
      const { width: tooltipWidth, height: tooltipHeight } = tooltipRef.current.getBoundingClientRect();

      let tooltipLeft = 0;
      let tooltipTop = 0;

      switch (position) {
        case TOOL_TIP_POSITION.TOP:
          tooltipLeft = left + width / 2 - tooltipWidth / 2;
          tooltipTop = top - tooltipHeight - 6;
          break;
        case TOOL_TIP_POSITION.RIGHT:
          tooltipLeft = left + width + 6;
          tooltipTop = top + height / 2 - tooltipHeight / 2;
          break;
        case TOOL_TIP_POSITION.BOTTOM:
          tooltipLeft = left + width / 2 - tooltipWidth / 2;
          tooltipTop = top + height + 6;
          break;
        case TOOL_TIP_POSITION.LEFT:
          tooltipLeft = left - tooltipWidth - 6;
          tooltipTop = top + height / 2 - tooltipHeight / 2;
          break;
        default:
          break;
      }

      tooltipRef.current.style.left = `${tooltipLeft}px`;
      tooltipRef.current.style.top = `${tooltipTop}px`;
    }
  }, [position, anchorRef, isVisible]);

  const triangleClassName = useMemo(() => {
    switch (position) {
      case TOOL_TIP_POSITION.TOP:
        return 'transform rotate-180 absolute -bottom-1.5 left-1/2 translate-x-[-50%]';
      case TOOL_TIP_POSITION.RIGHT:
        return 'absolute -rotate-90 -left-[9px] top-1/2 -translate-y-1/2';
      case TOOL_TIP_POSITION.BOTTOM:
        return 'absolute -top-1.5 left-1/2 translate-x-[-50%]';
      case TOOL_TIP_POSITION.LEFT:
        return 'transform rotate-90 absolute -right-[9px] top-1/2 -translate-y-1/2';
      default:
        return '';
    }
  }, [position]);

  return createPortal(
    <div
      ref={tooltipRef}
      className={`${isVisible ? 'block' : 'hidden'} transition z-[99999] bg-black text-center py-2 px-3 rounded-lg text-sm text-white font-medium w-[84px] fixed ${className}`}
    >
      {text}
      <TriangleIcon className={`fill-black ${triangleClassName}`} />
    </div>,
    document.body
  );
};

export const Tooltip = ({
  children,
  position = TOOL_TIP_POSITION.TOP,
  text,
  disabled,
  tooltipPopupClassName = '',
  wrapperClassName = '',
}) => {
  const anchorRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const toolTipWrapperClassName = [TOOL_TIP_POSITION.TOP, TOOL_TIP_POSITION.BOTTOM].includes(position)
    ? 'flex-col'
    : '';

  return (
    <div
      ref={anchorRef}
      className={`relative group/tooltip items-center flex-shrink-0 ${toolTipWrapperClassName} ${wrapperClassName}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {!disabled && (
        <ToolTipPopup isVisible={isVisible} text={text} position={position} className={tooltipPopupClassName} anchorRef={anchorRef} />
      )}
    </div>
  );
};