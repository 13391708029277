import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

const CustomNumberInputArrows = ({ seats, setSeats }) => {
  const buttonClassName = 'hover:bg-slate-200 active:bg-slate-50 transition rounded p-px';

  const onClickIncrementButton = (event) => {
    event.stopPropagation();
    setSeats(() => `${+seats + 1}`);
  }

  const onClickDecrementButton = (event) => {
    event.stopPropagation();
    const newValue = +seats - 1;
    if (newValue >= 0) {
      setSeats(() => `${newValue}`);
    }
  }

  return (
    <div className="hidden group-hover:flex flex-col items-center absolute right-16 text-black transition">
      <button
        type="button"
        className={buttonClassName}
        onClick={onClickIncrementButton}
      >
        <ChevronUpIcon className="h-3"/>
      </button>

      <button
        type="button"
        className={buttonClassName}
        onClick={onClickDecrementButton}
      >
        <ChevronDownIcon className="h-3"/>
      </button>
    </div>
  );
}

export const SeatsInput = ({seats, setSeats,}) => {

  const onChangeInput = (event) => {
    const newValue = +event.target.value
      .replaceAll('-', '')
      .replaceAll('e', '');

    if (!isNaN(newValue)) {
      setSeats(`${newValue}`);
    }
  }

  return (
    <div className="flex flex-col items-stretch gap-1.5 flex-1">
      <h3 className="text-sm font-semibold text-slate-600">
        Seats
      </h3>

      <div className="flex items-center w-full relative group">
        <input
          type="number"
          className="pr-24 w-full rounded-md text-black border border-gray-300 shadow hide-input-arrows focus:ring-0 focus:border-whisperBlue"
          value={seats}
          onChange={onChangeInput}
          pattern="\d*"
        />

        <div className="absolute right-4 text-slate-500">
          seats
        </div>

        <CustomNumberInputArrows
          seats={seats}
          setSeats={setSeats}
        />
      </div>
    </div>
  );
}