import { Input } from "../../../../components/Input";
import { loginStore } from "../../../../store";

export const EmailEdit = () => {
  const email = loginStore((state) => state.email);

  return (
    <Input
      disabled
      type="email"
      labelContent="Email"
      value={email}
    />
  );
}