import DOMPurify from "dompurify";

export function SafeHTML({ html }) {
  // Sanitize the HTML string
  const cleanHTML = DOMPurify.sanitize(html);

  // Render the sanitized HTML
  return (
    <div
      className="prev-html"
      dangerouslySetInnerHTML={{
        __html: cleanHTML,
      }}
    />
  );
}