import { RadioGroup } from "@headlessui/react";
import { ROLE } from "../../../../helpers/authentication.ts";

export const UserTypeSelector = ({ userType, setUserType }) => {
  return (
    <RadioGroup value={userType} onChange={setUserType} className="flex flex-col gap-4 w-full mt-32">
      <RadioGroup.Option
        value={ROLE.SUPER_ADMIN_EDUCATOR}
        className={({ checked }) => (checked ? "bg-blue-700 text-white" : "text-gray-900") + " flex-1 rounded-md p-4 border border-gray-300 shadow-sm cursor-pointer"}
      >
        {() => (
          <>
            <div className="flex flex-row gap-4 items-center">Company</div>
            <span className="opacity-60 text-sm">I am a business owner looking for reliable SAT digital testing tools to use with our students. Our company needs a solution that can reliably provide content I can trust and metrics I can use.</span>
          </>
        )}
      </RadioGroup.Option>

      <RadioGroup.Option
        value="student"
        className={({ checked }) => (checked ? "bg-blue-700 text-white" : "text-gray-900") + " flex-1 rounded-md p-4 border border-gray-300 shadow-sm cursor-pointer"}
      >
        {() => (
          <>
            <div className="flex flex-row gap-4 items-center">Student</div>
            <span className="opacity-60 text-sm">I am a student looking for reliable SAT digital testing tools to help me prepare for my upcoming tests. I’m ready to engage with helpful practice material, take accurate assessments, and get the feedback I need to do my best.</span>
          </>
        )}
      </RadioGroup.Option>
    </RadioGroup>
  );
};