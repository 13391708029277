import { Steps } from "intro.js-react";
import 'intro.js/introjs.css';
import { assessmentStore } from "../../../../../../store";

export const EDUCATOR_INTROJS_SELECTOR = {
  USERS: 'introjs-users-selector',
  RECENTLY_ASSIGNED: 'introjs-upcoming-recently-assigned-selector',
  TRY_ASSESSMENTS: 'introjs-try-assessments-selector',
  ASSESSMENTS: 'introjs-assessments-selector'
};

const steps = [
  {
    element: `.${EDUCATOR_INTROJS_SELECTOR.USERS}`,
    intro: 'Here, you can manage your assigned students, educators, and groups. You have the power to create, edit, and delete user profiles, as well as assign users to specific assessments and groups.',
  },
  {
    element: `.${EDUCATOR_INTROJS_SELECTOR.RECENTLY_ASSIGNED}`,
    intro: 'This is your Recently Assigned section. It provides a quick overview of the assessments you\'ve most recently assigned to your students. This feature helps you keep track of your latest assignments and ensures you\'re always up-to-date with your students\' current tasks.',
  },
  {
    element: `.${EDUCATOR_INTROJS_SELECTOR.TRY_ASSESSMENTS}`,
    intro: 'Curious about the assessments you\'re assigning? The Try Assessments feature allows you to experience the assessments firsthand. By clicking the "Try Assessment" button, you can take the assessments yourself, giving you valuable insight into what your students will encounter.',
  },
  {
    element: `.${EDUCATOR_INTROJS_SELECTOR.ASSESSMENTS}`,
    intro: 'The Assessments section is your command center for monitoring student progress. Here, you can view the status of all assigned assessments, categorized as "In Progress," "Completed," "Overdue," or "Not Started." This real-time overview allows you to identify students who may need additional support or encouragement, ensuring no one falls behind in their learning journey.',
  },
];

export const EducatorDashboardExcursion = () => {
  const { showExcursion, setShowExcursion } = assessmentStore();

  const handleOnExit = () => setShowExcursion(false);

  return (
    <Steps
      enabled={showExcursion}
      steps={steps}
      initialStep={0}
      onExit={handleOnExit}
    />
  );
}