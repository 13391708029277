import { useEffect, useMemo, useState } from "react";
import { useNavigate} from "react-router-dom";
import { ReactComponent as Calendar } from '../../../assets/studentPage/Calendar.svg';
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import {
  configureUserAssessment,
  fetchUserAnswerVersions,
  fetchUserAssessmentList,
  getResult
} from "../../../actions/assessment";
import { assessmentStore, loginStore } from "../../../store";
import { formatToUSTime } from "../../../helpers/date.ts";
import { ASSESSMENT_STATUS } from "../../../helpers/constants";

export const HistoryOfResults = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const {
    assessmentId,
    assessmentVersionId,
    educatorId,
    assessments,
    studentCompletedVersions,
    resetResults,
    setAssessments,
    setStudentAnswerVersionResult,
    setStudentEmailResult,
    setEducatorId,
    setIsLoadingAssessmentOverview,
    setStudentCompletedVersions
  } = assessmentStore();
  const { email } = loginStore();

  const completedVersions = useMemo(() => {
    return studentCompletedVersions
      ?.filter((version) => version?.status === ASSESSMENT_STATUS.COMPLETED)
      ?.sort((firstVersion, secondVersion) => new Date(secondVersion.createdDate) - new Date(firstVersion.createdDate)) ?? [];
  }, [studentCompletedVersions]);

  const fetchVersions = (assessivId, assessivVersionId, educatorId) => {
    setIsLoadingAssessmentOverview(true);
    fetchUserAnswerVersions({
      assessivId,
      assessivVersionId,
      educatorId
    })
      .then((data) => {
        if (Array.isArray(data?.data)) {
          setStudentCompletedVersions(data.data);
          setEducatorId(educatorId);
        }
      })
      .finally(() => setIsLoadingAssessmentOverview(false));
  }

  useEffect(() => {
    let apiEducatorId = educatorId;

    if (!apiEducatorId) {
      apiEducatorId = assessments?.find(assessment =>
        assessment.assessmentId === assessmentId?.toLowerCase() &&
        assessment?.assessmentVersionId === assessmentVersionId)?.educatorId ?? '';
    }

    if (apiEducatorId) {
      fetchVersions(assessmentId, assessmentVersionId, apiEducatorId);
    } else {
      configureUserAssessment({
        assessivId: assessmentId,
        assessivVersionId: assessmentVersionId
      }).then(() => {
        fetchUserAssessmentList()
          .then(({ data }) => {
            const newEducatorId = data?.find((assessment) =>
              assessment?.assessmentId === assessmentId?.toLowerCase() &&
              assessment?.assessmentVersionId === assessmentVersionId
            )?.educatorId;

            if (newEducatorId) {
              setAssessments(data);
              fetchVersions(assessmentId, assessmentVersionId, newEducatorId);
            }
          });
      });
    }

    return () => {
      setStudentCompletedVersions([]);
    }
  }, []);

  const handleOnClickVersion = (versionId, reportId) => () => {
    if (versionId !== undefined) {
      Promise.all([
        setStudentAnswerVersionResult(versionId),
        setStudentEmailResult(email),
        resetResults()
      ])
        .then(() => {
          if (!reportId || reportId === '00000000-0000-0000-0000-000000000000') {
            getResult({
              assessivId: assessmentId,
              assessivVersionId: assessmentVersionId,
              educatorId: educatorId,
              studentEmail: email,
              answerVersion: versionId
            }).then((res) => {
              if (res?.data?.resultId) {
                navigate(`/dashboard/assessment/results?reportId=${res?.data?.resultId}`);
              }
            })
          } else {
            const queryParams = new URLSearchParams({ reportId: reportId }).toString();
            navigate(`/dashboard/assessment/results?${queryParams}`);
          }
        });
    }
  }

  return (
    <div className="flex flex-col items-stretch gap-2">
      <button
        className={`flex items-center gap-3 bg-white border border-softCloud rounded-lg py-4 px-8 text-sm font-medium shadow-sm`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Calendar />
        History of Results
        <div className="w-5 h-5 rounded-full bg-red-400 text-white text-center ml-auto">
          {completedVersions?.length ?? 0}
        </div>
        <ChevronDownIcon className={`h-5 w-5 ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        completedVersions?.length ? (
          <div className="flex flex-col items-stretch bg-white border border-softCloud rounded-lg text-sm py-1.5 px-8">
            {completedVersions.map((version, index) => {
              const createdDate = new Date(version.createdDate);
              return (
                <button
                  key={version.versionId}
                  className={`flex justify-between items-center py-1.5 ${index === 0 ? '' : 'border-t border-softCloud'}`}
                  onClick={handleOnClickVersion(version.versionId, version.reportId)}
                >
                  <span className="text-sm font-medium">
                    Version {version.versionId}
                  </span>
                  <div className="flex flex-col items-end">
                    <span className="text-xs text-gray-400">
                      {formatToUSTime(createdDate)}
                    </span>
                      <span className="text-xs text-gray-400">
                      {createdDate.toDateString()}
                    </span>
                  </div>
                </button>
              )
            })}
          </div>
        ) : (
          <div className="px-8 py-2 text-center text-fadedDenim bg-white border border-softCloud rounded-lg">
            No results
          </div>
        )
      )}
    </div>
  );
}