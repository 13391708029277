import { Fragment } from "react";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { AnimIn } from "../../../components/mountAnim";
import BellAnim from "../../../assets/animations/bellIcon.json";

import { Abbreviator } from "../../../helpers/index.ts";
import { isOwnerEducator, isStudent } from "../../../helpers/authentication.ts";

function Pill({ text }) {
  return <span className="bg-green-200 text-green-800 px-2 py-1 text-sm rounded-full mx-2">{text}</span>;
}

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: BellAnim,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function MenuBar({
  fullName,
  email,
  btnSignOut,
}) {
  return (
    <Menu>
      <Menu.Button className="flex flex-row text-black">
        <div className="flex h-10 w-10 text-black font-medium text-md items-center justify-center rounded-full mr-4 bg-yellow-500">
          {Abbreviator(fullName)}
        </div>
        <div className="flex flex-col text-start">
          <span className="text-sm text-gray-800">{fullName}</span>
          <span className="text-xs text-gray-500">{email}</span>
        </div>
      </Menu.Button>

      <Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1">
        <Menu.Items className="absolute right-0 top-14 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {!isStudent() && (
            <Menu.Item>
              <Link to="/assessiv/invite" className="bg-gray-100 block px-4 py-2 text-sm text-gray-700">
                Send Assignment
              </Link>
            </Menu.Item>
          )}
          {isOwnerEducator() && (
            <>
              <Menu.Item>
                <Link to="/plan" className="bg-gray-100 block px-4 py-2 text-sm text-gray-700">
                  Manage plan
                </Link>
              </Menu.Item>

            </>
          )}
          {!isStudent() && (
            <Menu.Item>
              <Link to="/organization" className="bg-gray-100 block px-4 py-2 text-sm text-gray-700">
                Organization
              </Link>
            </Menu.Item>
          )}
          <Menu.Item>
            <Link to="/settings" className="bg-gray-100 block px-4 py-2 text-sm text-gray-700">
              Settings
            </Link>
          </Menu.Item>
          <Menu.Item>
            <button className="w-full bg-gray-100 block px-4 py-2 text-sm text-left text-gray-700" onClick={btnSignOut}>
              Sign out
            </button>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function MenuCard({ className, animInContainerClassName = '', children }) {
  return (
    <div className="w-full sm:w-4/5 mx-auto p-0 md:p-11">
      <div className={`rounded-2xl border-gray-300 shadow-md bg-white h-full w-full ${animInContainerClassName}`}>
        <AnimIn isVisible className={className}>
          {children}
        </AnimIn>
      </div>
    </div>
  );
}

function NotifyBell({ onClick, className, flagUp }) {
  return (
    <div className="flex items-center justify-center h-10 w-10 rounded-full relative" onClick={onClick}>
      {flagUp && (
        <div className="absolute top-2 right-2 h-2 w-2">
          <div className="h-2 w-2 bg-red-600 absolute rounded-full"></div>
          <div className="h-2 w-2 bg-red-600 absolute rounded-full animate-ping"></div>
        </div>
      )}

      <Lottie isStopped options={defaultOptions} height={25} width={25} className={className} />
    </div>
  );
}



export { Pill, MenuBar, MenuCard, NotifyBell };
