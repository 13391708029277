import React from 'react';

const getCoordinatesForPercent = (percent) => {
  const x = Math.cos(2 * Math.PI * percent - Math.PI / 2);
  const y = Math.sin(2 * Math.PI * percent - Math.PI / 2);
  return [x, y];
};

export const SectionPieChart = ({ percent }) => {
  const outerRadius = 7.25;
  const innerRadius = outerRadius - 2.5;
  const normalizedPercent = percent / 100;
  const [startX, startY] = getCoordinatesForPercent(0);
  const [endX, endY] = getCoordinatesForPercent(normalizedPercent);
  const largeArcFlag = normalizedPercent > 0.5 ? 1 : 0;

  const d = normalizedPercent < 1 ? [
    `M ${startX * innerRadius + 8} ${startY * innerRadius + 8}`,
    `A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 1 ${endX * innerRadius + 8} ${endY * innerRadius + 8}`,
    `L 8 8`,
    `Z`
  ].join(' ') : `M 8 8 m -${innerRadius}, 0 a ${innerRadius},${innerRadius} 0 1,0 ${innerRadius * 2},0 a ${innerRadius},${innerRadius} 0 1,0 -${innerRadius * 2},0`;

  const strokeDasharray = normalizedPercent < 1 ? "2 2" : "none";

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r={outerRadius} stroke="#117FFF" strokeWidth="1.5" strokeDasharray={strokeDasharray} />
      <path d={d} fill="#117FFF" />
    </svg>
  );
};
