import { useRef, useState } from "react";
import { ReactComponent as Arrow } from "../../../assets/rt-arrow.svg";
import { useClickOutside } from "../../../helpers/hooks/useClickOutside.ts";

export const Select = ({
  disable,
  containerClassName,
  options,
  title,
  selectedOption,
  onSelectOption,
  placeholder,
  getDisplayName = (value) => value,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  const closeDropdown = () => setIsOpen(false);

  const handleSelectSubject = (option) => (event) => {
    event.stopPropagation();
    onSelectOption(option);
    closeDropdown();
  }

  useClickOutside(
    dropdownRef,
    closeDropdown
  );

  return (
    <div className={`flex flex-col items-stretch gap-1.5 ${disable ? 'pointer-events-none' : ''} ${containerClassName}`}>
      {!!title?.length && (
        <h3 className={`text-sm font-semibold ${disable ? 'text-slate-400' : 'text-slate-600'}`}>
          {title}
        </h3>
      )}

      <div
        className={`flex flex-col relative rounded-md text-sm ${disable ? 'bg-gray-200' : 'bg-white'}`}
        ref={dropdownRef}
      >
        <div
          className={`flex justify-between gap-1 items-center shadow rounded-md border cursor-pointer h-10 py-2.5 px-4 ${
            disable ? 'text-slate-400' : selectedOption?.length ? 'text-black' : 'text-slate-600'} ${
            isOpen ? 'border-blue-400' : 'border-gray-300'
          }`}
          onClick={toggleDropdown}
        >
          <span>{selectedOption?.length ? getDisplayName(selectedOption) : (placeholder ?? 'Select option')}</span>
          <span className="rotate-90">
            <Arrow classname="w-4 h-4" />
          </span>
        </div>

        {isOpen && (
          <div className="flex flex-col items-stretch px-3 py-1 shadow-md absolute top-11 left-0 z-10 border border-whisperBlue rounded-md bg-white w-full max-h-[200px] overflow-auto text-black scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md">
            {options?.length ? (
              options.map((option, index) => (
                <div
                  key={option}
                  className={`cursor-pointer py-2 border-t ${selectedOption === option ? 'bg-skyBlue text-white px-3.5 -mx-3' : 'px-0.5'} ${index === 0 ? 'border-transparent' : 'border-whisperBlue'}`}
                  onClick={handleSelectSubject(option)}
                >
                  {getDisplayName(option)}
                </div>
              ))
            ) : (
              <div className="w-full text-center text-slate-400 sm:text-sm px-0.5 py-1 border-t border-transparent">No options</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}