import { api } from "../../helpers/apiHelper";
import { ACTIVE_URL } from "../constants";

export const fetchNotifications = () => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${ACTIVE_URL}/api/notifications`,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const markNotificationAsRead = (notificationIds) => {
  return new Promise((resolve, reject) => {
    api
      .patch(
        `${ACTIVE_URL}/api/notifications/markread`,
        notificationIds,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};