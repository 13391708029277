import { useNavigate } from "react-router-dom";

export const PlanCard = ({ plan, frequency }) => {
  const navigate = useNavigate();

  const navigateToRegister = () => {
    if (plan.registrationRole) {
      navigate(`/register?role=${plan.registrationRole}`);
    }
  }

  return (
    <div className="!w-[375px] overflow-hidden group relative transition-all ease-in-out duration-500 flex flex-col rounded-3xl p-8 xl:p-10 ring-1 ring-gray-200 hover:ring-2 hover:ring-indigo-600">
      <div className="flex items-center justify-between gap-x-4">
        <h3
          className="text-lg font-semibold leading-8 text-gray-900 group-hover:text-indigo-600 transition-all ease-in-out duration-500"
        >
          {plan.name}
        </h3>
        {!!plan.isEarlyBirdPromotion && (
          <span className="text-xs inline-flex items-center justify-center rounded-md min-w-max bg-indigo-600 px-1 py-0.5 font-semibold text-white transition">
            Early Bird Promotion
          </span>
        )}
      </div>
      <p className="mt-4 text-sm leading-6 text-gray-600">{plan.description}</p>
      <div className="flex flex-col justify-end flex-1">
        {!!plan.isSale && (
          <p className="mt-6 flex items-center justify-between gap-x-1 relative">
            <span className="text-xl font-medium tracking-tight line-through text-gray-500">
              ${frequency.value === 'monthly' ? '150' : '1500'}
            </span>
          </p>
        )}
        <p className="mt-1 flex items-baseline gap-x-1">
          <span className="text-4xl font-bold tracking-tight text-gray-900">
            {plan.price[frequency.value]}
          </span>
          <span className="text-sm font-semibold leading-6 text-gray-600">
            per seat
          </span>
          <span className="text-sm font-semibold leading-6 text-gray-600">
            {frequency.priceSuffix}
          </span>
        </p>
        <p className="mt-1 text-sm leading-6 text-gray-600">{plan.setUpInfo}</p>
        <button
          className="w-full text-indigo-600 ring-1 ring-inset ring-indigo-200 active:bg-indigo-600 group-hover:text-white group-hover:bg-indigo-500 shadow-sm mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all ease-in-out duration-500"
          onClick={navigateToRegister}
        >
          Get Started
        </button>
      </div>
    </div>
  );
}