import { Steps } from "intro.js-react";
import 'intro.js/introjs.css';
import { assessmentStore } from "../../../../../../store";

export const INTROJS_SELECTOR = {
  ASSESSMENTS: 'introjs-assessments-selector',
  UPCOMING_ACTIVITY: 'introjs-upcoming-activity-selector',
  AREAS_FOR_IMPROVEMENT: 'introjs-areas-for-improvement-selector',
  BADGES: 'introjs-badges-selector'
};

const steps = [
  {
    element: `.${INTROJS_SELECTOR.ASSESSMENTS}`,
    intro: 'Explore the Available and Practice Assessments section. The available assessments are all adaptive SAT sections, but you can hone specific skills using practice assessments. Use both wisely so you\'re prepared for the format, timing, and content you\'ll see on test day.',
  },
  {
    element: `.${INTROJS_SELECTOR.UPCOMING_ACTIVITY}`,
    intro: 'This is your Upcoming Activity section. Here, you\'ll find all the particular available assessments that have been assigned to you by your instructor.',
  },
  {
    element: `.${INTROJS_SELECTOR.AREAS_FOR_IMPROVEMENT}`,
    intro: 'The Area for Improvement is tailored just for you and provides insights into topics you might be struggling with based on your Assessive performance thus far. Remember, every expert was once a beginner!',
  },
  {
    element: `.${INTROJS_SELECTOR.BADGES}`,
    intro: 'Welcome to the Badges showcase! Every badge here represents a milestone or achievement in your learning journey. From course completions to outstanding performances, every badge tells a story of your hard work and dedication. Aim high, and fill this space with your accomplishments!',
  },
];

export const StudentDashboardExcursion = () => {
  const { showExcursion, setShowExcursion } = assessmentStore();

  const handleOnExit = () => setShowExcursion(false);

  return (
    <Steps
      enabled={showExcursion}
      steps={steps}
      initialStep={0}
      onExit={handleOnExit}
    />
  );
}