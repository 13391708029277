import { Select } from "./Select";

export const BILLING_PLAN = {
  MONTHLY: 'Monthly Plan',
  ANNUAL: 'Annual Plan',
};

const billingPlanOptions = [
  BILLING_PLAN.MONTHLY,
  BILLING_PLAN.ANNUAL,
];

export const BillingPlanSelect = ({ billingPlan, setBillingPlan, }) => {
  return (
    <Select
      containerClassName="flex-1"
      title="Billing plan"
      selectedOption={billingPlan}
      onSelectOption={setBillingPlan}
      options={billingPlanOptions}
    />
  );
}