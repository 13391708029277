export const Circle = ({ value, totalValue }) => {
  const RADIUS = 27;
  const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
  const strokeDashOffset = ((totalValue - value) / totalValue) * CIRCUMFERENCE;

  return (
    <svg
      width="56"
      height="56"
      className="mx-auto transform -rotate-90"
    >
      <circle
        cx="28"
        cy="28"
        r={RADIUS}
        strokeWidth="2"
        className="stroke-current text-[#FBBF24]/70"
        strokeDasharray={CIRCUMFERENCE}
        strokeDashoffset="0"
        fill="none"
      />
      <circle
        cx="28"
        cy="28"
        r={RADIUS}
        strokeWidth="2"
        strokeDashoffset={strokeDashOffset}
        strokeDasharray={CIRCUMFERENCE}
        className="stroke-current text-[#047857]"
        fill="none"
      />
    </svg>
  );
}