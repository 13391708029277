import Datepicker from "react-tailwindcss-datepicker";

export const DatePicker = ({ dueDate, setDueDate, }) => {

  const handleChangeDate = (newDate) => {
    if (Date.now() < new Date(newDate.endDate).getTime()) {
      setDueDate(newDate);
    }
  };

  return (
    <Datepicker
      asSingle
      useRange={false}
      placeholder="Expected by"
      minDate={new Date(Date.now())}
      popoverDirection="down"
      value={dueDate}
      onChange={handleChangeDate}
    />
  );
}