import { useEffect, useMemo, useState } from "react";
import { DropDownContainer } from "../../../components/DropDownContainer";
import { fetchPublishedAssessmentList } from "../../../actions/assessment";
import { fetchStudentsResults } from "../../../actions/user";
import { assessmentStore } from "../../../store";

const NONE_ASSIGNED_OPTION = {
  name: 'None Assigned',
  id: 'None Assigned',
  value: 'None Assigned',
};

const ALL_ASSIGNED_OPTION = {
  name: 'All Assigned',
  id: 'All Assigned',
  value: 'All Assigned',
};

const systemOptions = [NONE_ASSIGNED_OPTION, ALL_ASSIGNED_OPTION];

const parseAssessivsToOptions = (assessivs) => {
  return assessivs.map((assessiv) => ({
    ...assessiv,
    name: assessiv.assessivName,
    id: assessiv.assessivId + assessiv.assessivVersionId,
    value: assessiv?.assessivId
  }));
}

export const fetchInvitedAssessments = () => {
  const { setStudentsResults } = assessmentStore.getState();
  fetchStudentsResults()
    .then((data) => setStudentsResults(data?.data ?? []))
    .catch(console.log);
}

export const AssessivsSelect = ({ selectedAssessivs, setSelectedAssessivs, email }) => {
  const [assessivs, setAssessivs] = useState([]);
  const { studentsResults } = assessmentStore();

  const invitedAssessivsId = useMemo(() => {
    return studentsResults
      .filter((invitedAssessiv) => invitedAssessiv.studentEmail === email)
      .map((invitedAssessiv) => invitedAssessiv.assessivId);
  }, [studentsResults, email]);

  const assessivOptions = useMemo(
    () => parseAssessivsToOptions(assessivs),
    [assessivs]
  );

  const filteredAssessivOptions = useMemo(() => {
    const selectedAssessivSid = selectedAssessivs.map((assessiv) => assessiv.assessivId);

    return assessivOptions.filter((assessiv) =>
      !selectedAssessivSid.includes(assessiv.assessivId) &&
      !invitedAssessivsId.includes(assessiv.assessivId)
    );
  }, [assessivOptions, selectedAssessivs, invitedAssessivsId]);

  const selectedOptions = useMemo(
    () => parseAssessivsToOptions(selectedAssessivs.filter(
      (assessiv) => !invitedAssessivsId.includes(assessiv.assessivId))),
    [selectedAssessivs, invitedAssessivsId]
  );

  const options = useMemo(
    () => {
      if (!filteredAssessivOptions?.length && !selectedOptions?.length) {
        return [];
      }

      if (!filteredAssessivOptions?.length) {
        return [NONE_ASSIGNED_OPTION];
      }

      if (!selectedOptions?.length) {
        return [ALL_ASSIGNED_OPTION, ...filteredAssessivOptions];
      }

      return [...systemOptions, ...filteredAssessivOptions];
    },
    [selectedOptions?.length, filteredAssessivOptions]
  );

  const handleSelectAssessiv = (option) => {
    if (option.id === NONE_ASSIGNED_OPTION.id) {
      setSelectedAssessivs([]);
    } else if (option.id === ALL_ASSIGNED_OPTION.id) {
      setSelectedAssessivs(assessivOptions);
    } else {
      const isAssessivSelected = !!selectedAssessivs.find(
        (selectedAssessiv) => selectedAssessiv.assessivId === option.assessivId);

      if (!isAssessivSelected) {
        setSelectedAssessivs([...selectedAssessivs, option]);
      }
    }
  }

  const handleUnselectAssessiv = (assessivId) => {
    setSelectedAssessivs(
      selectedAssessivs.filter((selectedAssessiv) => selectedAssessiv.assessivId !== assessivId)
    );
  }

  const fetchAssessment = () => {
    fetchPublishedAssessmentList().then(({ data }) => setAssessivs(data));
  }

  useEffect(() => {
    fetchInvitedAssessments();
    fetchAssessment();
  }, []);

  return (
    <DropDownContainer
      noOptionsText="No Assessivs Available"
      defaultDisplayName={NONE_ASSIGNED_OPTION.name}
      selectedOptions={selectedOptions}
      options={options}
      onSelectOption={handleSelectAssessiv}
      onOpenDropDown={fetchAssessment}
      onUnselectOption={handleUnselectAssessiv}
    />
  );
}