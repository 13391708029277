import React, { useMemo } from "react";
import { motion } from "framer-motion";
import { QuestionItem } from "./QuestionItem";
import { SectionPieChart } from "./SectionPieChart";
import { assessmentStore, questionStore } from "../../../../store";
import { ReactComponent as CompletedIcon } from "../../../../assets/assessmentPlatform/section-completed-icon.svg";
import { ReactComponent as NotStartedIcon } from "../../../../assets/assessmentPlatform/section-not-started-icon.svg";
import { logTime } from "../../../../store/helpers";

const listAnimation = {
  hidden: {
    display: "none",
    opacity: 1,
    scale: 0,
  },
  visible: {
    display: "flex",
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.1,
      staggerChildren: 0.0145,
    },
  },
};

const listItemAnimation = {
  hidden: {
    y: 20,
    opacity: 0,
    display: "none",
  },
  visible: {
    y: 0,
    opacity: 1,
    display: "block",
    transition: {
      duration: 0.145,
    },
  },
};

export const SectionItem = ({ section, sectionOrder }) => {
  const { subSectionData, answeredQuestions } = assessmentStore();
  const { sectionId, setNewQuestion } = questionStore();

  const isSelectedSection = section.sectionId === sectionId;

  const icon = useMemo(() => {
    if (section.sectionId === sectionId) {
      const answeredQuestionsCount = subSectionData?.filter?.(
        (question) => answeredQuestions.includes(question.questionId))?.length ?? 0;

      const percent = typeof subSectionData?.length === 'number' && subSectionData?.length !== 0
        ? ((answeredQuestionsCount / subSectionData.length) * 100)
        : 0;

      return <SectionPieChart percent={percent} />;
    }

    if (section.isComplete) {
      return <CompletedIcon />;
    }

    return <NotStartedIcon />;
  }, [section.isComplete, isSelectedSection, subSectionData, answeredQuestions]);

  const handleSelectQuestion = (questionId, questionOrder)  => {
    logTime()
      .then(() => {
        setNewQuestion({
          questionSelected: questionId,
          sectionName: section.name,
          sectionId: section.sectionId,
          sectionOrder,
          questionOrder
        });
      });
  }

  return (
    <div className={`flex flex-col rounded-lg px-3 py-1.5 ${isSelectedSection ? 'bg-lightGrey' : 'bg-transparent'}`}>
      <div className="flex flex-row items-center text-gray-600 gap-2 cursor-pointer group">
        <span className={`flex justify-center items-center text-xs h-6 w-6 rounded border bg-white z-10 ${isSelectedSection ? 'border-navySmoke' : 'border-whisperBlue'}`}>
          {sectionOrder}
        </span>
        {icon}
        <span className={`text-sm ${isSelectedSection ? 'text-deepSpaceBlue' : 'text-duskyHarbor'}`}>{section.name}</span>
      </div>

      <div className="flex flex-row relative">
        <div className="flex flex-col w-full pt-1">
          <motion.ul
            className="flex flex-col gap-1"
            variants={listAnimation}
            initial="hidden"
            animate={section.isExpanded && !section?.isComplete ? "visible" : "hidden"}
          >
            {subSectionData?.map((question, index) => (
              <motion.li
                key={question?.questionId}
                className={`item ${index === 0 ? 'mt-3' : ''}`}
                variants={listItemAnimation}
              >
                <QuestionItem
                  isAnswered={isSelectedSection ? answeredQuestions.includes(question.questionId) : !!question?.userSelectedValue?.length}
                  isFlagged={question.isFlagged}
                  isQuestionSectionSelected={isSelectedSection}
                  questionId={question.questionId}
                  sectionId={section.sectionId}
                  questionOrder={index + 1}
                  handleSelectQuestion={() => handleSelectQuestion(question.questionId, index + 1)}
                />
              </motion.li>
            ))}
          </motion.ul>
        </div>
      </div>
    </div>
  );
}