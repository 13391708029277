import pencilRocketClipArt from "../../../assets/sammyLine.png";

export const LearningAssessivs = () => {
  return (
    <div className="flex md:flex-row flex-col container mx-auto bg-blueYonder rounded-xl md:px-14 md:py-9 max-w-xs md:max-w-4xl">
      <div className="flex-1 py-5 pl-4">
        <span className="font-medium text-md mb-4 opacity-75">Learning</span>
        <h1 className="font-normal text-3xl mb-4">Assessments</h1>
        <span className="font-normal text-md opacity-75">
          Detailed score reporting allows you to focus on the highest-yield content, minimizing the time you need to prepare.
        </span>
      </div>
      <div className="flex-1">
        <img src={pencilRocketClipArt} alt="" className=""/>
      </div>
    </div>
  );
}