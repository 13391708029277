export const Input = ({
  type = 'text',
  className = '',
  labelContent = '',
  labelClassName = '',
  wrapperClassName = '',
  ...inputProps
}) => (
  <label className={`flex flex-col items-start gap-1 ${wrapperClassName}`}>
    {labelContent && (
      <h2 className={`font-semibold text-center text-sm text-gray-700 ${labelClassName}`}>
        {labelContent}
      </h2>
    )}

    <input
      {...inputProps}
      className={`flex h-9 w-full rounded-md border border-slate-300 bg-transparent px-3 py-1 text-sm shadow-sm placeholder:text-slate-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 ${className}`}
      type={type}
    />
  </label>
);
