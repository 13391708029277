import { useEffect, useMemo, useRef, useState } from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { fetchPublishedAssessmentList } from "../actions/assessment";
import { fetchStudentsResults } from "../actions/user";
import searchIcon from "../assets/studentPage/searchIcon.svg";
import { useClickOutside } from "../helpers/hooks/useClickOutside.ts";
import { assessmentStore } from "../store";

export const MultipleInput = ({
  closeDropDownOnSelect,
  defaultDisplayName,
  options,
  selectedOptions,
  onSelectOption,
  onUnselectOption,
  onOpenDropDown,
  noOptionsText = 'No options available',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [top, setTop] = useState(44);
  const dropdownRef = useRef(null);
  const ref = useRef(null);
  const [search, setSearch] = useState('');

  const filteredOptions = useMemo(() => {
    return options.filter(option => option.name.toLowerCase().includes(search.toLowerCase().trim()));
  }, [options, search]);

  const handleOpenDropdown = (event) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();
    setIsOpen(true);
    onOpenDropDown?.();
  };

  const handleOnInputSearch = (event) => {
    setSearch(event.currentTarget.textContent);
    handleOpenDropdown();
  }

  const handleCloseDropdown = () => setIsOpen(false);

  const handleSelectOption = (option) => (event) => {
    event.stopPropagation();
    onSelectOption(option);

    if (closeDropDownOnSelect) {
      handleCloseDropdown();
    }
  }

  const handleFocusInput = () => {
    ref.current?.scrollIntoView();
    ref.current?.focus();
    setIsOpen(true);
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(ref.current);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  const handleUnselectOption = (optionId) => (event) => {
    event.stopPropagation();
    onUnselectOption(optionId);
  }

  useEffect(() => {
    if (dropdownRef.current) {
      setTop((dropdownRef.current.clientHeight ?? 40) + 4);
    }
  }, [selectedOptions, search]);

  useClickOutside(
    dropdownRef,
    handleCloseDropdown
  );

  return (
    <div
      className={`flex flex-col relative border rounded-md bg-white text-sm min-h-[40px] ${isOpen ? 'border-blue-400' : 'border-gray-300'}`}
      ref={dropdownRef}
    >
      <div className="flex gap-2 items-start pl-3 py-2 cursor-pointer" onClick={handleFocusInput}>
        <div
          className="flex flex-wrap items-center gap-2 pr-11 max-h-[160px] flex-1 text-slate-600 overflow-auto scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md">
          {selectedOptions.map(selectedOption => (
            <span key={selectedOption.id}
                  className="flex flex-row gap-1 items-center bg-slate-100 hover:bg-slate-200 border border-gray-200 text-sm px-2 py-1 rounded-lg text-black"
                  onClick={handleUnselectOption(selectedOption.id)}>
              {selectedOption.name}
              <XCircleIcon
                className="h-4 w-4 flex-shrink-0 text-gray-400 hover:text-gray-600 hover:cursor-pointer"></XCircleIcon>
            </span>
          ))}
          <div className={`relative z-10 ${selectedOptions.length ? '' : 'w-full'}`}>
            <span
              contentEditable
              suppressContentEditableWarning
              ref={ref}
              onInput={handleOnInputSearch}
              className="relative inline-block z-[9] min-w-[145px] w-full text-black outline-0"
            >
            </span>
            {!search.length && (
              <span className="absolute top-0 left-0 z-[8] text-gray-500 font-normal">
                Type assessiv name
              </span>
            )}
          </div>
        </div>

        <img
          draggable={false}
          src={searchIcon}
          className="absolute top-2.5 right-4 h-4 w-4"
          alt="logo"
        />
      </div>

      {isOpen && (
        <div
          className="flex flex-col items-stretch px-3 py-1 absolute top-11 left-0 z-10 border border-blue-400 rounded-md bg-white w-full max-h-[200px] overflow-auto text-black scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md"
          style={{ top }}
        >
          {filteredOptions?.length ? (
            filteredOptions.map((option, index) => (
              <div
                key={option?.id}
                className={`flex items-center justify-between gap-4 cursor-pointer px-0.5 py-1.5 border-t ${index === 0 ? 'border-transparent' : 'border-blue-400'}`}
                onClick={handleSelectOption(option)}
              >
                {option?.name}
                {!!option?.additionalText?.length && (
                  <span className="text-xs text-gray-400">{option?.additionalText}</span>
                )}
              </div>
            ))
          ) : (
            <div className="w-full text-center text-slate-400 sm:text-sm px-0.5 py-1 border-t border-transparent">{noOptionsText}</div>
          )}
        </div>
      )}
    </div>
  );
}

const NONE_ASSIGNED_OPTION = {
  name: 'None Assigned',
  id: 'None Assigned',
  value: 'None Assigned',
};

const ALL_ASSIGNED_OPTION = {
  name: 'All Assigned',
  id: 'All Assigned',
  value: 'All Assigned',
};

const systemOptions = [NONE_ASSIGNED_OPTION, ALL_ASSIGNED_OPTION];

const parseAssessivsToOptions = (assessivs) => {
  return assessivs.map((assessiv) => ({
    ...assessiv,
    name: assessiv.assessivName,
    id: assessiv.assessivId + assessiv.assessivVersionId,
    value: assessiv?.assessivId
  }));
}

export const fetchInvitedAssessments = () => {
  const { setStudentsResults } = assessmentStore.getState();
  fetchStudentsResults()
    .then((data) => setStudentsResults(data?.data ?? []))
    .catch(console.log);
}

export const AssessivSearchMultipleInput = ({ selectedAssessivs, setSelectedAssessivs, email }) => {
  const [assessivs, setAssessivs] = useState([]);
  const { studentsResults } = assessmentStore();

  const invitedAssessivsId = useMemo(() => {
    return studentsResults
      .filter((invitedAssessiv) => invitedAssessiv.studentEmail === email)
      .map((invitedAssessiv) => invitedAssessiv.assessivId);
  }, [studentsResults, email]);

  const assessivOptions = useMemo(
    () => parseAssessivsToOptions(assessivs),
    [assessivs]
  );

  const filteredAssessivOptions = useMemo(() => {
    const selectedAssessivSid = selectedAssessivs.map((assessiv) => assessiv.assessivId);

    return assessivOptions.filter((assessiv) =>
      !selectedAssessivSid.includes(assessiv.assessivId) &&
      !invitedAssessivsId.includes(assessiv.assessivId)
    )
      .map(option => ({
        ...option,
        additionalText: option?.type === 'Practice' ? 'Practice' : 'Assessiv'
      }));
  }, [assessivOptions, selectedAssessivs, invitedAssessivsId]);

  const selectedOptions = useMemo(
    () => parseAssessivsToOptions(selectedAssessivs.filter(
      (assessiv) => !invitedAssessivsId.includes(assessiv.assessivId))),
    [selectedAssessivs, invitedAssessivsId]
  );

  const options = useMemo(
    () => {
      if (!filteredAssessivOptions?.length && !selectedOptions?.length) {
        return [];
      }

      if (!filteredAssessivOptions?.length) {
        return [NONE_ASSIGNED_OPTION];
      }

      if (!selectedOptions?.length) {
        return [ALL_ASSIGNED_OPTION, ...filteredAssessivOptions];
      }

      return [...systemOptions, ...filteredAssessivOptions];
    },
    [selectedOptions?.length, filteredAssessivOptions]
  );

  const handleSelectAssessiv = (option) => {
    if (option.id === NONE_ASSIGNED_OPTION.id) {
      setSelectedAssessivs([]);
    } else if (option.id === ALL_ASSIGNED_OPTION.id) {
      setSelectedAssessivs(assessivOptions);
    } else {
      const isAssessivSelected = !!selectedAssessivs.find(
        (selectedAssessiv) => selectedAssessiv.assessivId === option.assessivId);

      if (!isAssessivSelected) {
        setSelectedAssessivs([...selectedAssessivs, option]);
      }
    }
  }

  const handleUnselectAssessiv = (assessivId) => {
    setSelectedAssessivs(
      selectedAssessivs.filter((selectedAssessiv) => `${selectedAssessiv.assessivId}${selectedAssessiv.assessivVersionId}` !== assessivId)
    );
  }

  const fetchAssessment = () => {
    fetchPublishedAssessmentList().then(({ data }) => setAssessivs(data));
  }

  useEffect(() => {
    fetchInvitedAssessments();
    fetchAssessment();
  }, []);

  return (
    <MultipleInput
      defaultDisplayName={NONE_ASSIGNED_OPTION.name}
      selectedOptions={selectedOptions}
      options={options}
      onSelectOption={handleSelectAssessiv}
      onOpenDropDown={fetchAssessment}
      onUnselectOption={handleUnselectAssessiv}
    />
  );
}