import { useEffect, useState } from "react";
import { Skeleton } from "./Skeleton";
import defaultLogo from "../assets/logo.svg";
import { fetchCompanyImage } from "../actions/registration";
import { loginStore } from "../store";

export const PlaceholderLogo = () => {
  const { companyLogo, setCompanyLogo } = loginStore();
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(companyLogo);

  const handleImageError = () => {
    setCompanyLogo(defaultLogo);
  };

  useEffect(() => {
    setImageSrc(companyLogo);
  }, [companyLogo]);

  useEffect(() => {
    setIsLoading(true);
    fetchCompanyImage()
      .then((data) => {
        setCompanyLogo(data?.data);
      })
      .catch((error) => {
        setCompanyLogo(defaultLogo);
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading && !imageSrc) {
    return (
      <Skeleton className="w-[124px] h-8" />
    );
  }

  return (
    <img
      src={imageSrc ? imageSrc : defaultLogo}
      className="h-auto max-w-full"
      alt="logo"
      onError={handleImageError}
    />
  );
}