import { INTROJS_SELECTOR } from "./StudentDashboardExcursion";
import badge1 from "../../../../../../assets/studentPage/badge1.png";
import badge2 from "../../../../../../assets/studentPage/badge2.png";

const Badge = ({ imgSrc, description }) => {
  return (
    <div className="group w-12 h-12 rounded-full border border-gray-300 flex items-center justify-center relative">
      <img className="h-6 w-6" src={imgSrc} alt="badge"/>

      <div className="absolute bottom-14 flex flex-col items-center transition-all ease-in-out duration-200 opacity-0 group-hover:opacity-100 rounded p-1 bg-skyBlue text-white text-center text-sm">
        {description}
        <div className="absolute -bottom-1 rotate-45 w-2 h-2 bg-skyBlue" />
      </div>
    </div>
  );
}

export const Badges = ({ className = '' }) => {
  return (
    <div className={`flex flex-col p-6 bg-white border border-softCloud rounded-lg shadow-sm ${className} ${INTROJS_SELECTOR.BADGES}`}>
      <h2 className="text-base font-medium mb-6">Badges</h2>

      <div className="flex flex-wrap gap-4">
        <Badge imgSrc={badge1} description="Test Trailblazer" />

        <Badge imgSrc={badge2} description="Consistent Learner" />
      </div>
    </div>
  );
}