import { useNavigate } from 'react-router-dom';
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import { DownloadButton } from "./DownloadButton";
import { Options } from "./Options";
import { ReactComponent as PrinterIcon } from "../../../../assets/printer.svg";
import { ReactComponent as BackIcon } from "../../../../assets/assessmentPlatform/backBtn.svg";

export const AssessmentResultsTopBar = ({ zoomLevel, zoomIn, zoomOut }) => {
  const navigate = useNavigate();

  const handleGoBack = () => navigate('/dashboard');

  const handlePrint = () => window.print();

  return (
    <div className="flex items-center h-14 bg-nightfallGrey justify-between px-6 print:hidden">
      <div className="flex flex-row gap-5 items-center">
        <button className="active:opacity-75 transition duration-75 ease-in-out cursor-pointer" onClick={handleGoBack}>
          <BackIcon className="h-9" />
        </button>
      </div>
      <div className="text-white text-sm flex flex-row items-center gap-4">
        <PlusIcon
          className="h-4 text-white opacity-40 cursor-pointer hover:opacity-75"
          onClick={zoomIn}
        />
        {zoomLevel}%
        <MinusIcon
          className="h-4 text-white opacity-40 cursor-pointer hover:opacity-75"
          onClick={zoomOut}
        />
      </div>
      <div className="flex flex-row gap-5 items-center">
        <DownloadButton />

        <PrinterIcon
          className="h-4 cursor-pointer active:scale-95"
          onClick={handlePrint}
        />

        <Options />
      </div>
    </div>
  );
};