import { TryAssessment } from "../../admin/component/TryAssessments";
import { EducatorScores } from "./components/educator/EducatorScores";
import { MyStudents } from "./components/educator/myStudents";
import { StudentsManagement } from "./components/educator/studentsManagement";
import { ExpandButtonWrapper, ExpandedSection } from "./components/general/ExpandedSection";
import { RecentlyAssigned } from "./components/educator/recentlyAssigned";
import { UseCodeAID } from "./components/general/UseCodeAID";
import { EducatorDashboardExcursion } from "./components/educator/EducatorDashboardExcursion";
import { isAdminEducator, isEducator } from "../../../../helpers/authentication.ts";

export const EducatorDashboard = ({
  handleRetract,
  renderExpandedContent,
  recentlyAssignedId,
  handleExpandRecentlyAssigned,
  selectedId
}) => {
  return (
    <div className="pb-10">
      <h1 className="mb-2 text-2xl text-black font-medium">Home</h1>

      <h2 className="mb-8 text-sm text-neutral-500">Tutor Dashboard</h2>

      <div className="flex flex-col gap-6">
        <EducatorScores />

        <div className="flex flex-col lg:flex-row gap-6">
          <div className="lg:w-[calc(75%+48px)] flex flex-col items-stretch gap-6">
            <MyStudents />

            <StudentsManagement />
          </div>

          <div className="lg:w-1/4 flex flex-col items-stretch gap-6">
            <ExpandButtonWrapper
              layoutId={recentlyAssignedId}
              onClickExpand={handleExpandRecentlyAssigned}
            >
              <RecentlyAssigned />
            </ExpandButtonWrapper>

            {isAdminEducator() && <UseCodeAID />}

            {(isEducator() || isAdminEducator()) && <TryAssessment />}
          </div>
        </div>
      </div>

      <ExpandedSection
        selectedId={selectedId}
        handleRetract={handleRetract}
        renderContent={renderExpandedContent}
      />

      <EducatorDashboardExcursion />
    </div>
  );
}