import { QuestionsTableRow } from "./questionTableRow";

export const QuestionsTable = ({ respSection, respAnswers }) => {
  return (
    <table className="min-w-full">
      <thead className=" rounded-tl-xl w-full bg-gray-100 border border-gray-200 text-left text-sm font-light">
      <tr>
        <th scope="col" className="font-medium text-gray-500 px-4 py-1 w-[92px]">
          Questions
        </th>
        <th scope="col" className="font-medium text-gray-500 px-3 py-1 w-[92px]">
          Correct
        </th>
        <th scope="col" className="font-medium text-gray-500 px-3 py-1 w-[92px]">
          You
        </th>
        <th scope="col" className="font-medium text-gray-500 px-4 py-1">
          Skill
        </th>
        <th scope="col" className="font-medium text-gray-500 px-3 py-1">
          Difficulty
        </th>
        <th scope="col" className="font-medium text-gray-500 px-4 py-1 w-[96px]">
          Time
        </th>
        <th scope="col" className="font-medium text-gray-500 px-2 py-1 w-[48px]">
          Annotated
        </th>
        <th scope="col" className="px-2 py-1 w-[48px]"/>
      </tr>
      </thead>

      <tbody className="w-full">
      <tr className="border-b bg-white border-l border-r">
        <td className="text-sm text-gray-500 pl-4 py-1">{respSection}</td>
        <td className="text-sm text-gray-500 px-3 py-1"></td>
        <td className="text-sm text-gray-500 px-3 py-1"></td>
        <td className="px-4 py-1"></td>
        <td className="px-4 py-1"></td>
        <td className="px-4 py-1"></td>
        <td className="px-4 py-1"></td>
        <td className="text-gray-500 py-1 flex justify-end pr-4">
          <div className="flex flex-row items-center gap-2">
            <div className="rounded-full bg-gray-100 px-2 py-1 text-xs">1 to {respAnswers.length}</div>
          </div>
        </td>
      </tr>

      {respAnswers.map((answer, index) => (
          <QuestionsTableRow
            key={index}
            index={index}
            answer={answer}
          />
        ))}
      </tbody>
    </table>
  );
}
