export const featuresList = [
  'Access to a digital testing tool that mimics the official app used on test day.',
  'Accurate test questions written by full-time tutors from industry-leading companies',
  'Comprehensive and instructive score reporting that helps students identify strengths and areas for improvement',
  'A library of practice questions for students to refine their skills between tests',
  'A white-labeled testing tool, which can be personalized with your company\'s name and logo.',
  'A full-featured administrator dashboard that can track, aggregate, and display results for all your students',
  'The ability to assign specific tests or problem sets to individual students',
  'Administrator and tutor level accounts so the right information is available to the right person',
  'An expanded administrator dashboard that can aggregate data by tutor or as a whole',
];

export const frequencies = [
  {
    value: 'monthly',
    label: 'Monthly',
    priceSuffix: '/month'
  },
  {
    value: 'annually',
    label: 'Annually',
    priceSuffix: '/year'
  },
];

export const tiers = [
  {
    name: 'Student',
    id: 'tier-student',
    price: {
      monthly: '$35',
      annually: '$350'
    },
    registrationRole: 'student',
    setUpInfo: 'No Setup fee',
    description: 'A fundamental package tailored for students, offering a digital test simulation, accurate questions, comprehensive score reports, and a library of practice questions.',
  },
  {
    name: 'Company',
    id: 'tier-company-large',
    isEarlyBirdPromotion: true,
    isSale: true,
    setupFee: '$500',
    price: {
      monthly: '$125',
      annually: '$1200'
    },
    registrationRole: 'company',
    setUpInfo: '+ $500 Setup fee',
    description: 'Our default company plan gives you everything you need in one package. It includes a customizable testing tool and an easy-to-use admin dashboard. You can assign tests to students and use different account levels. Plus, the expanded dashboard offers more features to help you manage and monitor your testing process easily.',
  },
];