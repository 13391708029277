import { useState } from "react";
import toast from "react-hot-toast";
import { Input } from "../../../../components/Input";
import { updateName } from "../../../../actions/registration";
import { loginStore } from "../../../../store";

const removeWhiteSpace = (string) => string.replaceAll(' ', '');

export const NameEdit = () => {
  const { fullName, setFullName, } = loginStore();
  const [defaultFirstName = '', defaultLastName = ''] = fullName?.split(' ') ?? [];
  const [firstName, setFirstName] = useState(defaultFirstName);
  const [lastName, setLastName] = useState(defaultLastName);

  const handleUpdateName = (event) => {
    event.preventDefault();
    if (!!firstName.length && !!lastName.length) {
      toast.promise(
        updateName(firstName, lastName),
        {
          loading: 'Loading...',
          success: 'The name was successfully updated.',
          error: 'Error when updating name.',
        }
      )
        .then((data) => {
          setFullName(`${firstName} ${lastName}`);
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.error('Name input cannot be empty.');
    }
  }

  return (
    <form className="flex items-end gap-2" onSubmit={handleUpdateName}>
      <Input
        wrapperClassName="flex-1"
        labelContent="First name"
        value={firstName}
        onChange={(event) => setFirstName(removeWhiteSpace(event.target.value))}
      />

      <Input
        wrapperClassName="flex-1"
        labelContent="Last name"
        value={lastName}
        onChange={(event) => setLastName(removeWhiteSpace(event.target.value))}
      />

      <button
        type="submit"
        disabled={defaultFirstName === firstName && defaultLastName === lastName}
        className="w-max inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition disabled:pointer-events-none disabled:opacity-50 bg-blue-500 hover:bg-blue-600 text-white disabled:text-gray-500 disabled:bg-gray-300 h-9 px-4 py-2"
      >
        Update
      </button>
    </form>
  );
}