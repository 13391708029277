import { RadioGroup } from "@headlessui/react";
import { frequencies } from "./constants";
import { classNames } from "./utils";

export const FrequencySwitch = ({ frequency, setFrequency }) => {
  return (
    <RadioGroup
      value={frequency}
      onChange={setFrequency}
      className="grid grid-cols-2 relative gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
    >
      <div className={`absolute top-1 w-[calc(50%-4px)] h-[calc(100%-8px)] bg-indigo-600 rounded-full transition-all duration-200 ${frequency.value === frequencies[0].value ? 'left-1' : 'left-1/2'}`}/>
      {frequencies.map((option) => (
        <RadioGroup.Option
          key={option.value}
          value={option}
          className={({checked}) =>
            classNames(
              frequency.value === option.value ? 'text-white' : 'text-gray-500',
              'cursor-pointer rounded-full px-2.5 py-1 z-10'
            )
          }
        >
          <span>{option.label}</span>
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
}