import { useSearchParams } from "react-router-dom";
import Lottie from "react-lottie";
import newScene from "../../../../assets/animations/newScene.json";
import { login } from "../../../../helpers/authentication.ts";

const lottie_successMSG = {
  loop: false,
  autoplay: true,
  animationData: newScene,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const VerifySuccess = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const password = searchParams.get("password");

  const handleLogin = () => {
    login({ email, password })
      .catch((error) => console.log(error));
  }

  return (
    <>
      <Lottie options={lottie_successMSG} height={200} width={200} autoplay />
      <h1 className="text-2xl font-bold text-slate-900">Verification</h1>
      <p className="text-slate-700">Your account has been verified</p>
      <button
        className="bg-green-500 text-white px-4 py-2 rounded-md mt-4"
        onClick={handleLogin}
      >
        Continue
      </button>
    </>
  );
}