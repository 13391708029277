import { useMemo } from "react";
import { AssessmentInfoDescription } from "./AssessmentInfoDescription";
import { AssessmentInfoSpecs } from "./AssessmentInfoSpecs";
import { AssessmentInfoActions } from "./AssessmentInfoActions";
import { assessmentStore } from "../../../../store";

export const AssessmentInfo = () => {
  const { isLoadingAssessmentOverview, studentCompletedVersions } = assessmentStore();

  const status = useMemo(() => {
    return studentCompletedVersions?.reduce((latest, current) => {
      const latestDate = latest.createdDate ? new Date(latest.createdDate) : new Date(0);
      const currentDate = current.createdDate ? new Date(current.createdDate) : new Date(0);

      if (!latest.createdDate && !current.createdDate) {
        return latest;
      } else if (!latest.createdDate) {
        return current;
      } else if (!current.createdDate) {
        return latest;
      }

      return currentDate > latestDate ? current : latest;
    }, {})?.status;
  }, [studentCompletedVersions]);

  return (
    <div className="bg-white border border-softCloud rounded-lg p-8 shadow-sm">
      <h2 className="text-base font-medium mb-4">Start the assessment</h2>

      <p className="text-sm text-slateBlue inline-block mb-6">
        Please follow the rules mentioned below, and we wish all the best with the assessment.
      </p>

      <AssessmentInfoSpecs />

      {isLoadingAssessmentOverview ? (
        <div className="text-xl text-center text-slate-500 my-6">
          Loading...
        </div>
      ) : (
        <div className="flex flex-col gap-4 my-6">
          <AssessmentInfoActions status={status} />
        </div>
      )}

      <AssessmentInfoDescription />
    </div>
  );
}