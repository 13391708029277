import { NavLink } from "react-router-dom";

export const TakeFirstAssessiv = () => {
  return (
    <div
      className="flex justify-center items-center h-[531px] md:bg-32% bg-mustard"
      style={{backgroundImage: `url('/hexaPattern.png')`}}
    >
      <div className="text-center max-w-2xl mx-4">
        <h1 className="text-4xl text-black mb-11 font-semibold">Take Your First Assessiv Today</h1>
        <NavLink
          to="/register"
          className="text-md items-center justify-center text-white text-md font-medium border border-black rounded-lg py-4 px-6 bg-blueYonder"
        >
          Get Started Now
        </NavLink>
      </div>
    </div>
  );
}