import { useMemo, useState } from "react";
import { MultipleInput } from "./MultipleSelect";
import { SingleTypeSelect } from "./SingleTypeSelect";
import { SearchAssessivTable } from "./SearchAssessivTable";
import { searchPracticeAssessivsToAssign, searchRegularAssessivsToAssign } from "../../../actions/assessment";

const ASSESSIV_TYPE = {
  PRACTICE: 'Practice Assessments',
  ADAPTIVE: 'Adaptive Tests',
};

const assessivTypeOptions = [
  { id: ASSESSIV_TYPE.PRACTICE, name: ASSESSIV_TYPE.PRACTICE, value: ASSESSIV_TYPE.PRACTICE, },
  { id: ASSESSIV_TYPE.ADAPTIVE, name: ASSESSIV_TYPE.ADAPTIVE, value: ASSESSIV_TYPE.ADAPTIVE, },
];

const SECTION = {
  READING_AND_WRITING: 'Reading and Writing',
  MATH: 'Math',
};

const sectionOptions = [
  { id: SECTION.READING_AND_WRITING, name: SECTION.READING_AND_WRITING, value: SECTION.READING_AND_WRITING, },
  { id: SECTION.MATH, name: SECTION.MATH, value: SECTION.MATH, },
];

const MATH_DOMAIN = {
  ALGEBRA: 'Algebra',
  ADVANCED_MATH: 'Advanced math',
  PROBLEM_SOLVING_AND_DATA_ANALYSIS: 'Problem-Solving and Data Analysis',
  GEOMETRY_AND_TRIGONOMETRY: 'Geometry and Trigonometry',
};

const mathDomainOptions = [
  { id: MATH_DOMAIN.ALGEBRA, name: MATH_DOMAIN.ALGEBRA, value: MATH_DOMAIN.ALGEBRA, },
  { id: MATH_DOMAIN.ADVANCED_MATH, name: MATH_DOMAIN.ADVANCED_MATH, value: MATH_DOMAIN.ADVANCED_MATH, },
  { id: MATH_DOMAIN.PROBLEM_SOLVING_AND_DATA_ANALYSIS, name: MATH_DOMAIN.PROBLEM_SOLVING_AND_DATA_ANALYSIS, value: MATH_DOMAIN.PROBLEM_SOLVING_AND_DATA_ANALYSIS, },
  { id: MATH_DOMAIN.GEOMETRY_AND_TRIGONOMETRY, name: MATH_DOMAIN.GEOMETRY_AND_TRIGONOMETRY, value: MATH_DOMAIN.GEOMETRY_AND_TRIGONOMETRY, },
];

const READING_AND_WRITING_DOMAIN = {
  INFORMATION_AND_IDEAS: 'Information of Ideas',
  CRAFT_AND_STRUCTURE: 'Craft and Structure',
  EXPRESSION_OF_IDEAS: 'Expression of Ideas',
  STANDARD_ENGLISH_CONVENTIONS: 'Standard English Conventions',
};

const readingAndWritingDomainOptions = [
  { id: READING_AND_WRITING_DOMAIN.INFORMATION_AND_IDEAS, name: READING_AND_WRITING_DOMAIN.INFORMATION_AND_IDEAS, value: READING_AND_WRITING_DOMAIN.INFORMATION_AND_IDEAS, },
  { id: READING_AND_WRITING_DOMAIN.CRAFT_AND_STRUCTURE, name: READING_AND_WRITING_DOMAIN.CRAFT_AND_STRUCTURE, value: READING_AND_WRITING_DOMAIN.CRAFT_AND_STRUCTURE, },
  { id: READING_AND_WRITING_DOMAIN.EXPRESSION_OF_IDEAS, name: READING_AND_WRITING_DOMAIN.EXPRESSION_OF_IDEAS, value: READING_AND_WRITING_DOMAIN.EXPRESSION_OF_IDEAS, },
  { id: READING_AND_WRITING_DOMAIN.STANDARD_ENGLISH_CONVENTIONS, name: READING_AND_WRITING_DOMAIN.STANDARD_ENGLISH_CONVENTIONS, value: READING_AND_WRITING_DOMAIN.STANDARD_ENGLISH_CONVENTIONS, },
];

export const ExtendedSearchSection = ({ selectedAssessivs, setSelectedAssessivs, hideTitle, }) => {
  const [assessivType, setAssessivType] = useState('');
  const [section, setSection] = useState('');
  const [domains, setDomains] = useState([]);
  const [isTableVisible, setIsTableVsiible] = useState(false);
  const [regularAssessivs, setRegularAssessvis] = useState([]);
  const [practiceAssessivs, setPracticeAssessivs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const domainOptions = section === SECTION.MATH ? mathDomainOptions : readingAndWritingDomainOptions;
  const isPossibleToLoadPracticeAssessivs = !!section && !!domains?.length;

  const filteredDomainOptions = useMemo(() => {
    const selectedDomainValues = domains.map(domainOption => domainOption.value);
    return domainOptions.filter(domainOption => !selectedDomainValues.includes(domainOption.value));
  }, [domainOptions, domains]);

  const loadRegularAssessivs = () => {
    setIsLoading(true);

    searchRegularAssessivsToAssign()
      .then((data) => setRegularAssessvis(data?.data ?? []))
      .catch(console.log)
      .finally(() => setIsLoading(false));
  }

  const loadPracticeAssessivs = () => {
    if (isPossibleToLoadPracticeAssessivs) {
      setIsLoading(true);

      searchPracticeAssessivsToAssign({ section, domains: domains.map(domain => domain.value) })
        .then((data) => setPracticeAssessivs(data?.data ?? []))
        .catch(console.log)
        .finally(() => setIsLoading(false));
    }
  }

  const loadAssessivs = () => {
    if (assessivType === ASSESSIV_TYPE.ADAPTIVE) {
      loadRegularAssessivs();
    } else {
      loadPracticeAssessivs();
    }

    setIsTableVsiible(true);
  }

  const handleSelectAssessivType = (option) => {
    if (option?.value) {
      setDomains([]);
      setSection('');
      setIsTableVsiible(false);
      setAssessivType(option.value);
    }
  }

  const handleSelectSection = (option) => {
    if (option?.value) {
      setDomains([]);
      setSection(option.value);
    }
  }

  const handleSelectDomain = (option) => {
    if (option?.value) {
      setDomains([
        ...domains,
        option,
      ]);
    }
  }

  const handleUnselectDomain = (optionId) => {
    if (optionId) {
      setDomains(domains.filter(domain => domain.value !== optionId));
    }
  }

  return (
    <>
      <div className="flex flex-col items-center gap-4 pl-4 ml-4 border-l">
        {!hideTitle && (
          <h3 className="text-black font-semibold text-center text-2xl mb-4">Extended Search</h3>
        )}

        <SingleTypeSelect
          selectedOptionText={assessivType}
          onSelectOption={handleSelectAssessivType}
          options={assessivTypeOptions}
          placeholder="Select category name"
        />

        {assessivType === ASSESSIV_TYPE.PRACTICE && (
          <>
            <SingleTypeSelect
              selectedOptionText={sectionOptions.find(item => item.value === section)?.name ?? section}
              onSelectOption={handleSelectSection}
              options={sectionOptions}
              placeholder="Select section name"
            />

            <MultipleInput
              noOptionsText={section?.length ? undefined : 'Select section name'}
              options={section?.length ? filteredDomainOptions : []}
              selectedOptions={domains}
              defaultDisplayName="Select domain name"
              className="max-w-[400px]"
              onSelectOption={handleSelectDomain}
              onUnselectOption={handleUnselectDomain}
            />
          </>
        )}

        <button
          type="button"
          disabled={assessivType === ASSESSIV_TYPE.ADAPTIVE ? false : !isPossibleToLoadPracticeAssessivs}
          className="bg-blue-600 hover:bg-blue-700 disabled:bg-gray-200 text-white font-bold py-2 px-4 mt-1 rounded-lg w-[400px] disabled:text-gray-400 disabled:font-normal"
          onClick={loadAssessivs}
        >
          <span className="py-2 px-4 text-md">Search</span>
        </button>
      </div>

      {isTableVisible && (
        <SearchAssessivTable
          isPractice={assessivType === ASSESSIV_TYPE.PRACTICE}
          isLoading={isLoading}
          selectedAssessivs={selectedAssessivs}
          setSelectedAssessivs={setSelectedAssessivs}
          regularAssessivs={regularAssessivs}
          practiceAssessivs={practiceAssessivs}
        />
      )}
    </>
  );
}