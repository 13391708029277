import React, { useState, useEffect } from 'react';

export const OTPInput = ({ value = '', getValue, disabled, slotClassName = '', debounceTime = 0 }) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (internalValue !== value) {
      const handler = setTimeout(() => {
        getValue?.(internalValue);
      }, debounceTime);

      return () => clearTimeout(handler);
    }
  }, [internalValue, value, debounceTime, getValue]);

  const otpArray = Array.from(
    { length: 6 },
    (_, index) => internalValue[index] ?? ''
  );

  const handleOnChange = (index) => (event) => {
    const newValue = event.target.value;
    if (/[0-9/.-]/.test(newValue) || newValue === '') {
      const newOtpArray = [...otpArray];
      newOtpArray[index] = newValue;
      setInternalValue(newOtpArray.join(''));

      if (newValue && event.target.nextSibling) {
        event.target.nextSibling.focus();
      }
    }
  };

  const handleOnKeyDown = (index) => (event) => {
    if (event.key === 'Backspace' && otpArray[index] === '' && index > 0) {
      const prevSibling = event.target.previousSibling;
      if (prevSibling) {
        prevSibling.focus();
      }
    }
  };

  const handleOnFocus = (event) => event.target.select();

  return (
    <div className="flex items-center gap-2">
      {otpArray.map((character, index) => (
        <input
          key={index}
          disabled={disabled}
          className={`w-8 h-8 text-center border border-slate-300 rounded px-0 ${slotClassName}`}
          type="text"
          maxLength="1"
          value={character}
          onChange={handleOnChange(index)}
          onKeyDown={handleOnKeyDown(index)}
          onFocus={handleOnFocus}
        />
      ))}
    </div>
  );
};
