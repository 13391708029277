import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../pages/landing/components/Footer";
import { LandingHeader } from "../pages/landing/components/LandingHeader";

function injectScript() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = "https://app.termly.io/embed-policy.min.js";
  script.id = "termly-jssdk";
  document.body.appendChild(script);
  return script;
}

export const TermlyDoc = ({ dataId }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const script = injectScript();

    return () => {
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="App-header bg-white flex flex-col">
      <LandingHeader />

      <div className="flex-1">
        <div className="max-w-3xl px-10 my-10 mx-auto">
          <div name="termly-embed" data-id={dataId}/>
        </div>
      </div>

      <Footer/>
    </div>
  );
};