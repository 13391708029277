import { useEffect, useMemo, useRef, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { FILTER_OPTION, FilterStatusComboBox } from "./FilterByStatusCombobox";
import { assessmentStore, tablesStore } from "../../../../../../../store";
import Pagination from "../../../../../../../components/Pagination";
import { Abbreviator } from "../../../../../../../helpers/index.ts";
import { StatusLabel } from "../../general/StatusLabel";
import { ASSESSMENT_STATUS_BY_INDEX } from "../../../../../../../helpers/constants";
import { createSortingByLatestFirstCallback } from "../../../../../../../helpers/date.ts";

const RESULTS_PER_PAGE = 10;
const noDueDateValue = '9999-12-31T23:59:59.9999999';

function toUsFormatDate(dateString) {
  const date = new Date(dateString);

  return date.toLocaleString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
}

const isAssessmentOverdue = (assessment) => {
  if (!assessment?.dueDate) {
    return true;
  }

  const dueDate = new Date(assessment.dueDate);
  const currentDateUTC = new Date();
  const offset = currentDateUTC.getTimezoneOffset();
  const currentDateUSA = new Date(currentDateUTC.getTime() - offset * 60 * 1000);

  return currentDateUSA > dueDate;
}

const AssignedAssessmentTableRow = ({ assessment }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  const toggleExpanded = () => {
    setIsExpanded(prev => !prev)
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isExpanded ? contentRef.current.scrollHeight : 0);
    }
  }, [contentRef.current, isExpanded]);

  return (
    <>
      <tr onClick={toggleExpanded} className={`cursor-pointer transition-all duration-300 border-t ${isExpanded ? 'border-fadedDenim/50 bg-lightGrey' : 'border-alabaster'} group hover:bg-lightGrey text-center`}>
        <td className={`transition-all duration-300 flex items-center gap-4 font-medium text-left py-[14px] pl-6 pr-2 sticky left-0 group-hover:bg-lightGrey max-w-[75%] ${isExpanded ? 'bg-lightGrey' : 'bg-white'}`}>
          <div className="flex flex-shrink-0 h-6 w-6 text-white font-medium text-[10px] leading-[16px] items-center justify-center rounded-full bg-blue-500">
            {Abbreviator(assessment?.name?.toUpperCase() ?? '')}
          </div>
          {assessment?.name}
        </td>

        <td className="text-left px-2">
          {assessment?.studentEmail}
        </td>

        <td className="pl-16 pr-6 text-right font-medium">
          <button>
            <ChevronRightIcon className={`h-5 transition-all duration-300 ${isExpanded ? 'rotate-90' : ''}`} />
          </button>
        </td>
      </tr>

      <tr>
        <td
          className={`transition-all duration-300 ${isExpanded ? 'border-b border-t border-t-alabaster border-b-fadedDenim/50 ' : ''}`} colSpan={3}>
          <div style={{ height: isExpanded ? `${contentHeight}px` : '0px', overflow: 'hidden', transition: 'height 300ms ease' }} ref={contentRef}>
            <table className="w-full h-[10px] my-2">
              <thead>
              <tr className="text-sm text-left">
                <th className="w-5/12 text-fadedDenim font-medium pl-6 pr-2 p-2">Assessment</th>
                <th className="w-4/12 text-fadedDenim font-medium p-2">Due date</th>
                <th className="w-3/12 text-fadedDenim font-medium p-2">Status</th>
              </tr>
              </thead>
              <tbody>
              {assessment.revisions.map((revision, index) => {
                const isOverdue = isAssessmentOverdue(revision);
                const status = ASSESSMENT_STATUS_BY_INDEX[revision?.assessivStatus ?? ''];

                return (
                  <tr key={index} className="text-left">
                    <td className="break-all border-t border-alabaster p-2 pl-6 py-4">{revision?.assessivName}</td>
                    <td className="break-all border-t border-alabaster p-2 py-4">
                      {(!!revision?.dueDate && revision?.dueDate !== noDueDateValue) ? toUsFormatDate(revision.dueDate) : 'No Due Date'}
                    </td>
                    <td className="break-all border-t border-alabaster px-2 py-4">
                      {isOverdue ? (
                        <div className="flex items-center justify-center gap-2 w-max bg-whisperWhite rounded-lg px-2 py-1 text-xs">
                          <div className="w-1 h-1 rounded-full flex-shrink-0 bg-red-600"/>
                          Overdue
                        </div>
                      ) : (
                        status ? <StatusLabel className="!ml-0" status={status} /> : '-'
                      )}
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </>
  );
}

export const EducatorAssignedAssessments = ({searchParam}) => {
  const [filteredResults, setFilteredResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { studentsResults } = assessmentStore();
  const { educatorsAssessmentFilterType } = tablesStore();

  const pendingAssessments = useMemo(
    () => {
      const pendingAssessivs = studentsResults?.filter(result => {
        if (result?.isComplete) {
          return false;
        }

        if (educatorsAssessmentFilterType === FILTER_OPTION.NONE) {
          return true;
        }

        const isResultOverdue = isAssessmentOverdue(result);
        const isResultInProgress = !isResultOverdue;

        return educatorsAssessmentFilterType === FILTER_OPTION.OVERDUE ? isResultOverdue : isResultInProgress;
      }) ?? [];

      return Array
        .from(new Set(pendingAssessivs.map(result => result.studentEmail)))
        .map((studentEmail) => {
          const revisions = pendingAssessivs
            .filter((result) => result.studentEmail === studentEmail)
            .toSorted(createSortingByLatestFirstCallback((revision) => revision?.invitedDate));
          return {
            ...revisions[0],
            revisions: revisions
          };
        })
        .toSorted(createSortingByLatestFirstCallback((student) => student?.revisions?.[0]?.invitedDate));
    },
    [studentsResults, educatorsAssessmentFilterType]
  );

  const resultsPerPage = useMemo(() => {
    const firstPageIndex  = RESULTS_PER_PAGE * (currentPage - 1);
    const lastPageIndex  = RESULTS_PER_PAGE * currentPage - 1;
    return pendingAssessments?.filter(
      (_, index) => index >= firstPageIndex && index <= lastPageIndex) ?? [];
  }, [pendingAssessments, currentPage]);

  const totalPages = useMemo(() => {
    const pagesNumber = Math.ceil((filteredResults?.length ?? 0) / RESULTS_PER_PAGE);
    return pagesNumber > 0 ? pagesNumber : 1;
  }, [filteredResults?.length]);

  useEffect(() => {
    const newFilteredResults = pendingAssessments.filter((result) => result.studentEmail.toLowerCase().includes(searchParam.toLowerCase()));

    if (newFilteredResults.length !== pendingAssessments.length) {
      setCurrentPage(1);
    }

    setFilteredResults((prev) => {
      if (newFilteredResults.length !== prev.length) {
        setCurrentPage(1);
      }

      return newFilteredResults;
    });
  }, [searchParam, pendingAssessments]);

  return (
    <>
      {resultsPerPage.length ? (
        <div
          className="overflow-auto relative scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md w-full mb-6">
          <table className="min-w-full">
            <thead>
            <tr className="text-center text-fadedDenim pb-2">
              <th className="w-5/12 text-left text-xs pl-6 py-[14px] pr-2 sticky left-0 bg-white font-normal">
                Name
              </th>
              <th className="w-4/12 px-2 font-normal text-center lg:text-left text-xs">
                Email address
              </th>
              <th className="w-3/12 pr-6 pl-2 font-normal text-center text-xs py-[14px]"/>
            </tr>
            </thead>

            <tbody className="text-sm text-right text-black">
            {resultsPerPage.map((assessment) => (
              <AssignedAssessmentTableRow key={assessment?.studentEmail} assessment={assessment}/>
            ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-xl text-slate-500 text-center p-4 md:p-6 w-full mb-7">
          No data
        </div>
      )}

      <div className="flex items-center justify-between gap-1">
        <FilterStatusComboBox className="opacity-0 pointer-events-none"/>

        <Pagination
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          totalPages={totalPages}
        />

        <FilterStatusComboBox/>
      </div>
    </>
  );
}