import { PlusIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/solid";

const AssignButton = ({ selectedAssessivs, setSelectedAssessivs, assessiv, isPractice }) => {

  const isAdded = selectedAssessivs.find(selectedAssessiv => selectedAssessiv.assessivId === assessiv.assessivId && selectedAssessiv.assessivVersionId === assessiv.assessivVersionId);

  const handleOnClick = () => {
    if (isAdded) {
      setSelectedAssessivs(
        selectedAssessivs.filter(selectedAssessiv => !(selectedAssessiv.assessivId === assessiv.assessivId && selectedAssessiv.assessivVersionId === assessiv.assessivVersionId))
      );
    } else {
      setSelectedAssessivs([
        ...selectedAssessivs,
        {
          assessivId: assessiv.assessivId,
          assessivVersionId: assessiv.assessivVersionId,
          assessivName: assessiv.name,
          id: `${assessiv.assessivId}${assessiv.assessivVersionId}`,
          value: assessiv.assessivId,
          additionalText: isPractice ? 'Practice' : 'Assessiv',
          type: isPractice ? 'Practice' : 'TestQuestion',
        }
      ]);
    }
  }

  return (
    <button
      type="button"
      onClick={handleOnClick}
      className={`mx-auto w-5 h-5 flex items-center justify-center rounded-full transition-all ${isAdded ? 'hover:bg-red-500/10 active:bg-red-500/20' : 'hover:bg-black/10 active:bg-black/20'}`}
    >
      {isAdded ? (
        <TrashIcon className="h-4 text-red-500"/>
      ) : (
        <PlusIcon className="h-4 text-black"/>
      )}
    </button>
  );
}

const AdaptiveSearchAssessivTable = ({ assessivs, isLoading, selectedAssessivs, setSelectedAssessivs, }) => {

  return (
    <div className="w-full overflow-y-auto max-h-[200px] rounded-xl scrollbar-thin border col-span-2 my-8 relative">
      <table className="w-full">
        <thead>
        <tr>
          <td className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[60%] sticky top-0">Name</td>
          <td className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[20%] text-center sticky top-0">Sections</td>
          <td className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[20%] text-center sticky top-0">Assign</td>
        </tr>
        </thead>
        <tbody>
        {isLoading ? (
          <tr>
            <td colSpan="3" className="p-3 text-base text-gray-400 text-center">
              Loading...
            </td>
          </tr>
        ) : !assessivs?.length ? (
          <tr>
            <td colSpan="3" className="p-3 text-base text-gray-400 text-center">
              No assessivs
            </td>
          </tr>
        ) : (
          assessivs.map((assessiv) => (
            <tr key={assessiv.assessivId}>
              <td className="px-3 py-1.5 text-xs text-black border-t">{assessiv.name}</td>
              <td className="px-3 py-1.5 text-xs text-black text-center border-t">{assessiv.sectionCount}</td>
              <td className="px-3 py-1.5 text-xs text-black text-center border-t">
                <AssignButton
                  assessiv={assessiv}
                  selectedAssessivs={selectedAssessivs}
                  setSelectedAssessivs={setSelectedAssessivs}
                />
              </td>
            </tr>
          ))
        )}
        </tbody>
      </table>
    </div>
  );
}

const PracticeSearchAssessivTable = ({ assessivs, isLoading, selectedAssessivs, setSelectedAssessivs, }) => {

  return (
    <div className="w-full overflow-y-auto max-h-[200px] rounded-xl scrollbar-thin border col-span-2 my-8 relative">
      <table className="w-full">
        <thead>
        <tr>
          <td className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[35%] sticky top-0">Name</td>
          <td className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[35%] sticky top-0">Topic</td>
          <td className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[10%] text-center sticky top-0">Difficulty</td>
          <td className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[10%] text-center sticky top-0">Questions</td>
          <td className="text-xs text-slate-500 bg-slate-200 px-3 py-2 w-[10%] text-center sticky top-0">Assign</td>
        </tr>
        </thead>
        <tbody>
        {isLoading ? (
          <tr>
            <td colSpan="5" className="p-3 text-base text-gray-400 text-center">
              Loading...
            </td>
          </tr>
        ) : !assessivs?.length ? (
          <tr>
            <td colSpan="5" className="p-3 text-base text-gray-400 text-center">
              No assessivs
            </td>
          </tr>
        ) : (
          assessivs.map((assessiv) => (
            <tr key={assessiv.assessivId}>
              <td className="px-3 py-1.5 text-xs text-black border-t">{assessiv.name}</td>
              <td className="px-3 py-1.5 text-xs text-black border-t">{assessiv.topic}</td>
              <td className="px-3 py-1.5 text-xs text-black text-center border-t">{assessiv.difficulty}</td>
              <td className="px-3 py-1.5 text-xs text-black text-center border-t">{assessiv.questionCount}</td>
              <td className="px-3 py-1.5 text-xs text-black text-center border-t">
                <AssignButton
                  isPractice
                  assessiv={assessiv}
                  selectedAssessivs={selectedAssessivs}
                  setSelectedAssessivs={setSelectedAssessivs}
                />
              </td>
            </tr>
          ))
        )}
        </tbody>
      </table>
    </div>
  );
}

export const SearchAssessivTable = ({
  isPractice,
  isLoading,
  selectedAssessivs,
  setSelectedAssessivs,
  regularAssessivs,
  practiceAssessivs,
}) => {

  if (isPractice) {
    return (
      <PracticeSearchAssessivTable
        isLoading={isLoading}
        selectedAssessivs={selectedAssessivs}
        setSelectedAssessivs={setSelectedAssessivs}
        assessivs={practiceAssessivs}
      />
    );
  }

  return (
    <AdaptiveSearchAssessivTable
      isLoading={isLoading}
      selectedAssessivs={selectedAssessivs}
      setSelectedAssessivs={setSelectedAssessivs}
      assessivs={regularAssessivs}
    />
  );
}