import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { LessonsScore } from "./components/student/LessonsScore";
import { UpcomingActivity, UpcomingActivityExpanded } from "./components/student/upcomingActivity";
import { Analytics, AnalyticsContent } from "./components/student/analytics";
import { Badges } from "./components/student/Badges";
import { ExpandButtonWrapper, ExpandedSection } from "./components/general/ExpandedSection";
import { StudentAssessments } from "./components/student/StudentAssessments";
import { StudentDashboardExcursion } from "./components/student/StudentDashboardExcursion";
import { AreasForImprovement } from "./components/student/areasForImprovement";
import { RecentlyAssignedExpanded } from "./components/educator/recentlyAssigned";
import { EducatorDashboard } from "./EducatorDashboard";
import { isStudent } from "../../../../helpers/authentication.ts";

const LAYOUT_ID = {
  AVAILABLE_ASSESSMENTS: 'AvailableAssessments',
  UPCOMING_ACTIVITY: 'UpcomingActivity',
  RECENTLY_ASSIGNED: 'RecentlyAssigned',
  STUDENT_COMPLETED_ASSESSMENTS: 'StudentCompletedAssessments',
  AREAS_FOR_IMPROVEMENT: 'AreasForImprovement',
};

export const Home = () => {
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState(null);

  const handleExpandComponent = (layoutId) => () => {
    setSelectedId(layoutId);
  }

  const navigateToSectionOverview = () => navigate('/assessment/start');

  const renderExpandedContent = () => {
    switch (selectedId) {
      case LAYOUT_ID.AVAILABLE_ASSESSMENTS: return <StudentAssessments onClickAssessment={navigateToSectionOverview} />;
      case LAYOUT_ID.UPCOMING_ACTIVITY: return <UpcomingActivityExpanded onClickAssessment={navigateToSectionOverview} />;
      case LAYOUT_ID.STUDENT_COMPLETED_ASSESSMENTS: return <AnalyticsContent />;
      case LAYOUT_ID.RECENTLY_ASSIGNED: return <RecentlyAssignedExpanded />;
      case LAYOUT_ID.AREAS_FOR_IMPROVEMENT: return <AreasForImprovement />;
      default: return null;
    }
  }

  if (!isStudent()) {
    return (
      <EducatorDashboard
        selectedId={selectedId}
        handleExpandRecentlyAssigned={handleExpandComponent(LAYOUT_ID.RECENTLY_ASSIGNED)}
        handleRetract={() => setSelectedId(null)}
        recentlyAssignedId={LAYOUT_ID.RECENTLY_ASSIGNED}
        renderExpandedContent={renderExpandedContent}
      />
    );
  }

  return (
    <div>
      <h1 className="mb-2 text-2xl text-black font-medium">Home</h1>

      <h2 className="mb-8 text-sm text-neutral-500">Student Dashboard</h2>

      <div className="flex flex-col gap-6">
        <LessonsScore />

        <div className="flex flex-col lg:flex-row gap-6">
          <div className="lg:w-[calc(75%+48px)] flex flex-col items-stretch gap-6">
            <ExpandButtonWrapper
              layoutId={LAYOUT_ID.AVAILABLE_ASSESSMENTS}
              onClickExpand={handleExpandComponent(LAYOUT_ID.AVAILABLE_ASSESSMENTS)}
            >
              <StudentAssessments onClickAssessment={navigateToSectionOverview} />
            </ExpandButtonWrapper>
          </div>

          <div className="lg:w-1/4 flex flex-col items-stretch gap-6">
            <ExpandButtonWrapper
              layoutId={LAYOUT_ID.UPCOMING_ACTIVITY}
              onClickExpand={handleExpandComponent(LAYOUT_ID.UPCOMING_ACTIVITY)}
            >
              <UpcomingActivity onClickAssessment={navigateToSectionOverview} />
            </ExpandButtonWrapper>

            <Badges className="flex-1" />
          </div>
        </div>

        <Analytics
          layoutId={LAYOUT_ID.STUDENT_COMPLETED_ASSESSMENTS}
          onClickExpand={handleExpandComponent(LAYOUT_ID.STUDENT_COMPLETED_ASSESSMENTS)}
        />

        <ExpandButtonWrapper
          layoutId={LAYOUT_ID.AREAS_FOR_IMPROVEMENT}
          onClickExpand={handleExpandComponent(LAYOUT_ID.AREAS_FOR_IMPROVEMENT)}
        >
          <AreasForImprovement/>
        </ExpandButtonWrapper>
      </div>

      <ExpandedSection
        selectedId={selectedId}
        handleRetract={() => setSelectedId(null)}
        renderContent={renderExpandedContent}
      />
      <StudentDashboardExcursion />
    </div>
  );
}