import { SectionItem } from "./SectionItem";
import { assessmentStore } from "../../../../store";

export const OverviewTab = () => {
  const { sectionData } = assessmentStore();

  return (
    <div className="flex flex-1 flex-col relative bg-white">
      <div className="absolute h-full w-full flex flex-col gap-3 overflow-y-auto pt-3 px-3 scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md scrollbar-rounded-md">
        {sectionData?.length ? (
          sectionData
            .filter((section) => !section.isHidden)
            .map((section, index) => (
              <SectionItem
                key={section.sectionId ?? index}
                section={section}
                sectionOrder={index + 1}
              />
            ))
        ) : (
          <div className="mt-10 text-center text-slate-400">Loading sections...</div>
        )}
      </div>
    </div>
  );
}