import { useEffect } from "react";
import { LandingHeader } from "./components/LandingHeader";
import { StartNow } from "./components/StartNow";
import { Answers } from "./components/Answers";
import { WhyAssessivs } from "./components/WhyAssessivs";
import { LearningAssessivs } from "./components/LearningAssessivs";
import { Guide } from "./components/Guide";
import { TakeFirstAssessiv } from "./components/TakeFirstAssessiv";
import { Footer } from "./components/Footer";
import { mx } from "../../helpers/mixpanel.ts";

export default function Landing() {

  useEffect(() => {
    mx.track('Landing Page Viewed');
  }, []);

  return (
    <div className="App-header bg-white">
      <LandingHeader/>

      <StartNow/>
      <Answers/>
      <WhyAssessivs/>
      <LearningAssessivs/>
      <Guide/>
      <TakeFirstAssessiv/>

      <Footer/>
    </div>
  );
}
