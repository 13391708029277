import React, { useMemo } from "react";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from "@heroicons/react/20/solid/index.js";


const DOUBLE_ARROW_UPDATE_VALUE = 10;

export default function Pagination({
  className,
  currentPage,
  disabled,
  totalPages,
  onPageChange
}) {
  const pageList = useMemo(() => {
    const pagesToShow = Math.min(7, totalPages);
    const medianPageList = Math.floor(pagesToShow / 2);

    let firstPageInList;

    if (currentPage <= medianPageList) {
      firstPageInList = 1;
    } else if (currentPage >= totalPages - medianPageList) {
      firstPageInList = totalPages - pagesToShow + 1;
    } else {
      firstPageInList = currentPage - medianPageList;
    }

    const lastPageInList = Math.min(firstPageInList + pagesToShow - 1, totalPages);

    return Array.from(
      { length: lastPageInList - firstPageInList + 1 },
      (_, index) => firstPageInList + index
    );
  }, [currentPage, totalPages]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  return (
    <div className={`flex items-center justify-center gap-1 relative ${className}`}>
      <button
        onClick={() => handlePageChange(currentPage - DOUBLE_ARROW_UPDATE_VALUE)}
        disabled={disabled || currentPage <= DOUBLE_ARROW_UPDATE_VALUE}
        className={`flex items-center justify-center p-1 rounded-full h-8 w-8 ${
          currentPage <= DOUBLE_ARROW_UPDATE_VALUE ? 'text-gray-400 cursor-not-allowed' : 'hover:bg-gray-200'
        }`}
      >
        <ChevronDoubleLeftIcon className="h-5" />
      </button>

      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={disabled || currentPage <= 1}
        className={`flex items-center justify-center p-1 rounded-full h-8 w-8 ${
          currentPage === 1 ? 'text-gray-400 cursor-not-allowed' : 'hover:bg-gray-200'
        }`}
      >
        <ChevronLeftIcon className="h-5" />
      </button>

      {pageList.map((page) => (
        <button
          key={page}
          disabled={disabled}
          onClick={() => handlePageChange(page)}
          className={`flex items-center justify-center p-1 h-8 w-8 rounded-full font-medium ${
            page === currentPage ? 'bg-blue-500 text-white' : 'bg-transparent hover:bg-gray-200 disabled:text-gray-500 transition-all duration-300'
          }`}
        >
          {page}
        </button>
      ))}

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={disabled || currentPage >= totalPages}
        className={`flex items-center justify-center p-1 rounded-full h-8 w-8 ${
          currentPage === totalPages ? 'text-gray-400 cursor-not-allowed' : 'hover:bg-gray-200'
        }`}
      >
        <ChevronRightIcon className="h-5" />
      </button>

      <button
        onClick={() => handlePageChange(currentPage + DOUBLE_ARROW_UPDATE_VALUE)}
        disabled={disabled || currentPage >= totalPages - DOUBLE_ARROW_UPDATE_VALUE}
        className={`flex items-center justify-center p-1 rounded-full h-8 w-8 ${
          currentPage >= totalPages - DOUBLE_ARROW_UPDATE_VALUE ? 'text-gray-400 cursor-not-allowed' : 'hover:bg-gray-200'
        }`}
      >
        <ChevronDoubleRightIcon className="h-5" />
      </button>
    </div>
  );
};