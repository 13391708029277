import { useNavigate } from "react-router-dom";
import { QNav } from "./QuestionsNav";
import { ReactComponent as BackIcon } from "../../../../assets/assessmentPlatform/backBtn.svg";
import { assessmentStore } from "../../../../store";
import { logTime } from "../../../../store/helpers";

export const AssessmentHeader = () => {
  const navigate = useNavigate();
  const { assessmentId, resultsReady }  = assessmentStore();

  const handleGoBack = () => {
    if (!resultsReady) {
      logTime();
    }
    navigate('/dashboard');
  }

  return (
    <header className="flex px-3 py-2 shadow justify-between items-center bg-deepSpaceBlue">
      <div className="flex flex-row flex-wrap items-center justify-start gap-x-4 gap-y-2 mx-auto container">
        <button className="active:opacity-75 transition duration-75 ease-in-out cursor-pointer" onClick={handleGoBack}>
          <BackIcon className="h-9" />
        </button>

        <div className="flex flex-col mr-auto">
          <div className="text-md text-white font-semibold">Student Digital Assessment</div>
          <div className="text-sm flex flex-row items-center -mt-1 text-fadedDenim">
            Assessiv In Progress: {assessmentId}
          </div>
        </div>

        <QNav />
      </div>
    </header>
  );
}