import { useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useClickOutside } from "../../../helpers/hooks/useClickOutside.ts";

export const SingleTypeSelect = ({
  placeholder,
  options,
  selectedOptionText,
  onSelectOption,
  noOptionsText = 'No options'
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOpenDropdown = () => {
    setIsOpen(true);
  }

  const handleCloseDropdown = () => {
    setIsOpen(false);
  }

  const handleSelectOption = (option) => (event) => {
    event.stopPropagation();
    onSelectOption(option);
    handleCloseDropdown();
  }

  useClickOutside(
    dropdownRef,
    handleCloseDropdown
  );

  return (
    <div
      ref={dropdownRef}
      className={`w-[400px] flex flex-col relative border rounded-md bg-white text-sm min-h-[40px] shadow-sm ${isOpen ? 'border-blue-400' : 'border-gray-300'}`}

    >
      <div className="flex gap-2 items-center px-3 py-2 cursor-pointer" onClick={handleOpenDropdown}>
        <span className={`inline-block text-sm border-0 p-0 focus:ring-0 pr-12 w-full ${selectedOptionText?.length ? 'text-black' : 'text-gray-500'}`}>
          {selectedOptionText?.length ? selectedOptionText : placeholder}
        </span>
        <ChevronDownIcon className={`h-5 text-[#94A3B8] ${isOpen ? 'rotate-180' : ''}`} />
      </div>

      {isOpen && (
        <div className="flex flex-col items-stretch px-3 py-1 absolute top-11 left-0 z-10 border border-blue-400 rounded-md bg-white w-full max-h-[200px] overflow-auto text-black scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md">
          {options?.length ? (
            options.map((option, index) => (
              <div
                key={option?.id}
                className={`flex items-center justify-between gap-4 cursor-pointer px-0.5 py-1.5 border-t ${index === 0 ? 'border-transparent' : 'border-blue-400'}`}
                onClick={handleSelectOption(option)}
              >
                {option?.name}
                {!!option?.additionalText?.length && (
                  <span className="text-xs text-gray-400">{option?.additionalText}</span>
                )}
              </div>
            ))
          ) : (
            <div className="w-full text-center text-slate-400 sm:text-sm px-0.5 py-1 border-t border-transparent">{noOptionsText}</div>
          )}
        </div>
      )}
    </div>
  );
}