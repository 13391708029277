import { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import { resultsStore } from "../../../../store";

export const Sidebar = ({ pageContainerRef, isFetching }) => {
  const { isSidebarOpened, isPresentMode, currentId, setCurrentId } = resultsStore();
  const [imagesData, setImagesData] = useState([]);
  const pageCount = useRef(100);

  const selectNewPage = (pageElementId) => () => {
    setCurrentId(pageElementId);
    const element = document.getElementById(pageElementId);
    if (element) {
      element.scrollIntoView({ block: 'start' });
    }
  }

  const createHiddenIframe = () => {
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '1px';
    iframe.style.height = '1px';
    iframe.style.opacity = '0';
    iframe.style.pointerEvents = 'none';
    iframe.style.top = '-9999px';
    document.body.appendChild(iframe);
    return iframe;
  };

  const cloneContentToIframe = (content, iframe) => {
    const clonedContent = content.cloneNode(true);

    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');

    const styleSheets = document.head.querySelectorAll('style');
    styleSheets.forEach(styleSheet => {
      iframe.contentDocument.head.appendChild(styleSheet.cloneNode(true));
    });

    const linkElements = document.head.querySelectorAll('link[rel="stylesheet"]');
    linkElements.forEach(link => {
      const clonedLink = link.cloneNode(true);
      iframe.contentDocument.head.appendChild(clonedLink);
    });

    iframe.contentDocument.body.appendChild(clonedContent);
  };

  const captureIframeContent = async (iframe) => {
    const canvas = await html2canvas(iframe.contentDocument.body);
    return canvas.toDataURL();
  };

  const removeIframe = (iframe) => {
    document.body.removeChild(iframe);
  };

  const generatePagesImagePreview = async (childrenArray) => {
    for (const child of childrenArray) {
      const iframe = createHiddenIframe();
      cloneContentToIframe(child, iframe);

      try {
        const dataUrl = await captureIframeContent(iframe);
        setImagesData((prevImagesData) => [...prevImagesData, dataUrl]);
      } catch (error) {
        console.error('Error capturing content:', error);
      } finally {
        removeIframe(iframe);
      }

      await new Promise((resolve) => setTimeout(resolve, 0));
    }
  };


  useEffect(() => {
    if (!isFetching) {
      const childrenArray = Array.from(pageContainerRef.current.children);
      pageCount.current = childrenArray.length;

      setTimeout(() => {
        generatePagesImagePreview(childrenArray)
          .catch((error) => {
            console.error(error);
          });
      }, 1000);
    }
  }, [isFetching]);


  return (
    <div className={`flex flex-col items-stretch gap-6 bg-nightfallGrey/90 py-9 box-border overflow-y-scroll transition-all ${isSidebarOpened && !isPresentMode ? 'max-w-screen-2xl px-16' : 'max-w-0'}`}>
      {imagesData.length === pageCount.current ? imagesData.map((dataUrl, index) => {
        const order = index + 1;
        const pageId = `download-page-id-${order}`;
        const sideBarItemId = `sidebar-id-${order}`;

        return (
          <div
            key={sideBarItemId}
            id={sideBarItemId}
            className="flex flex-col items-center gap-1"
            onClick={selectNewPage(pageId)}
          >
            <div
              className={`border-4 cursor-pointer h-[200px] w-[145px] object-cover ${
                currentId === pageId ? 'border-[rgb(38,152,255)]' : 'border-transparent'
              }`}
            >
              <img
                src={dataUrl}
                alt={`Preview ${order}`}
                className="h-full w-full object-cover"
              />
            </div>
            <span className="text-white text-sm">{order}</span>
          </div>
        )
      }) : (
        <div className="w-[145px] text-center text-white text-sm">
          Loading preview
          <span className="loading-dot">.</span>
          <span className="loading-dot">.</span>
          <span className="loading-dot">.</span>
        </div>
      )}
    </div>
  );
}