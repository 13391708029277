import { useNavigate } from "react-router-dom";

export const StartNow = () => {
  const navigate = useNavigate();

  const onClickStartNow = () => {
    navigate('/register')
  }

  return (
    <div
      className="flex justify-center items-center h-[531px] w-full md:bg-32% bg-blueYonder"
      style={{ backgroundImage: `url('/hexaPattern.png')` }}
    >
      <div className="text-center max-w-2xl mx-4">
        <h1 className="text-4xl text-white mb-4 font-semibold">Your Best Digital Resource for the SAT</h1>
        <p className="mb-4">Find an ever increasing list of practice tests curated by industry leading tutors. You can track your progress, identify room for improvement and confidently take your SAT with no hassle.</p>
        <button
          className="text-md items-center justify-center text-black text-md font-medium border border-black rounded-lg py-2 px-4 bg-goldenSun"
          onClick={onClickStartNow}
        >
          Start now
        </button>
      </div>
    </div>
  );
};