import { NameEdit } from "./components/nameEdit";
import { EmailEdit } from "./components/emailEdit";
import { PasswordEdit } from "./components/passwordEdit";
import { EmailNotifications } from "./components/emailNotifications";
import { UploadCompanyLogo } from "./components/uploadCompanyLogo";
import { Header } from "../../components/Header";
import { MenuCard } from "../dashboard/components/utilityComponents";
import { isOwnerEducator } from "../../helpers/authentication.ts";

export const Settings = () => {
  return (
    <div className="App-header flex flex-col bg-slate-200">
      <Header/>

      <div className="flex flex-1 bg-slate-200 z-0">
        <MenuCard className="flex flex-col gap-6 items-stretch text-black flex-1 p-6 w-[800px] max-w-full mx-auto">
          <h1 className="font-semibold text-center text-2xl">Settings</h1>

          <NameEdit />

          <EmailEdit />

          <PasswordEdit />

          <EmailNotifications />

          {isOwnerEducator() && <UploadCompanyLogo />}
        </MenuCard>
      </div>
    </div>
  );
}