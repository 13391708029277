import { useEffect } from 'react';

export const useArrowKeyNavigation = ({ onLeftArrow, onRightArrow }) => {
  useEffect(() => {
    function handleKeyPress(event) {
      switch (event.key) {
        case 'ArrowRight':
          onRightArrow && onRightArrow();
          break;
        case 'ArrowLeft':
          onLeftArrow && onLeftArrow();
          break;
        default:
          break;
      }
    }

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [onLeftArrow, onRightArrow]);
}