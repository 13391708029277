import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Lottie from "react-lottie";

import newScene from "../../assets/animations/newScene.json";
import errorMessage from "../../assets/animations/errorMessage.json";
import loaderMessage from "../../assets/animations/loadingRoller.json";
import { verifyUser } from "../../actions/registration";

const lottie_loaderMSG = {
  loop: true,
  autoplay: true,
  animationData: loaderMessage,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const lottie_successMSG = {
  loop: false,
  autoplay: true,
  animationData: newScene,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const lottie_errorMSG = {
  loop: false,
  autoplay: true,
  animationData: errorMessage,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function Verify() {
  let navigate = useNavigate();

  const routeChange = (path) => {
    navigate(path);
  };

  let { id } = useParams();

  const [verificationState, setVerificationState] = useState(0);

  useEffect(() => {
    verifyUser(id)
      .then((res) => {
        setVerificationState(1);
      })
      .catch((err) => {
        setVerificationState(2);
      });
  }, [id]);

  return (
    <>
      <div className="flex flex-1 flex-col h-screen  w-screen items-center justify-center overflow-hidden">
        {verificationState === 0 ? (
          <>
            <Lottie options={lottie_loaderMSG} height={200} width={200} autoplay />
            <h1 className="text-2xl font-bold text-slate-900">Checking Verification</h1>
          </>
        ) : verificationState === 1 ? (
          <>
            <Lottie options={lottie_successMSG} height={200} width={200} autoplay />
            <h1 className="text-2xl font-bold text-slate-900">Verification</h1>
            <p className="text-slate-700">Your account has been verified</p>
            <button className="bg-green-500 text-white px-4 py-2 rounded-md mt-4" onClick={() => routeChange("/login")}>
              Continue
            </button>
          </>
        ) : verificationState === 2 ? (
          <>
            <Lottie options={lottie_errorMSG} height={200} width={200} autoplay />
            <h1 className="text-2xl font-bold text-slate-900">Verification ERROR</h1>
            <p className="text-slate-700 text-center">
              You may have already verified this account. <br /> Please try logging in or contact support at: <br />
              <div className="my-4">
                <a className="px-2 py-2 bg-gray-100 rounded-md" href="mailto:support@accessiv.com?subject=ISSUE%3A%20Verify%20Account%20Link&body=I'm%20having%20issues%20when%20I%20click%20on%20the%20account%20link%20that%20was%20sent%20to%20my%20email.">
                  support@assessiv.com
                </a>
              </div>
            </p>
            <button className="bg-green-500 text-white px-4 py-2 rounded-md mt-4" onClick={() => routeChange("/login")}>
              Login
            </button>
          </>
        ) : null}
      </div>
    </>
  );
}
