import { Fragment, useMemo, useState } from "react";
import { OverviewTab } from "./OverviewTab";
import { ReferenceTab } from "./ReferenceTab";
import { assessmentStore, questionStore } from "../../../../store";
import { FloatingCalculator } from "../../../../components/FloatingDesmosCalculator";

const mathSubjectId = '481b502f-4506-4bde-881d-1c686de88335';

const SIDEBAR_TABS = {
  OVERVIEW: 'Overview',
  REFERENCE: 'Reference',
  CALCULATOR: 'Calculator',
};

const tabsContent = {
  [SIDEBAR_TABS.OVERVIEW]: OverviewTab,
  [SIDEBAR_TABS.REFERENCE]: ReferenceTab,
  [SIDEBAR_TABS.CALCULATOR]: FloatingCalculator,
};

export const Sidebar = () => {
  const { sectionData } = assessmentStore();
  const { sectionId } = questionStore();

  const [currentTab, setCurrentTab] = useState(SIDEBAR_TABS.OVERVIEW);

  const isCurrentSectionMath = useMemo(() => {
    const currentSubject = sectionData.find(section => section.sectionId === sectionId);

    return currentSubject?.subjectId === mathSubjectId;
  }, [sectionData, sectionId]);

  const TabContent = tabsContent[currentTab] ?? Fragment;

  return (
    <aside className="flex flex-col w-96 border-r border-l border-softCloud bg-moonbeam">
      <div className="flex flex-row text-sm font-medium px-6 h-14 gap-4 border-b border-softCloud">
        {Object.values(SIDEBAR_TABS)
          .filter(tab => isCurrentSectionMath ? true : ![SIDEBAR_TABS.CALCULATOR, SIDEBAR_TABS.REFERENCE].includes(tab))
          .map((tab) => (
            <span
              key={tab}
              className={`cursor-pointer border-b-2 h-full flex items-center ${currentTab === tab ? 'text-deepSpaceBlue border-blue-500' : 'border-transparent hover:border-gray-200 text-duskyHarbor'}`}
              onClick={() => setCurrentTab(tab)}
            >
              {tab}
            </span>
          ))}
      </div>

      <TabContent isCurrentSectionMath={isCurrentSectionMath} />
    </aside>
  );
}