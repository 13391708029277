import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { INTROJS_SELECTOR } from "./StudentDashboardExcursion";
import { StatusLabel } from "../general/StatusLabel";
import { assessmentStore, tablesStore } from "../../../../../../store";
import { fetchPublishedAssessmentListByStudent } from "../../../../../../actions/assessment";
import Pagination from "../../../../../../components/Pagination";
import { Tabs } from "../../../../../../components/Tabs";
import { Table } from "../../../../../../components/Table";
import { SortedComboBox, SORTING } from "../../../../../../components/SortedCombobox";
import { Tooltip } from "../../../../../../components/Tooltip";
import { Skeleton } from "../../../../../../components/Skeleton";
import { ASSESSMENT_STATUS } from "../../../../../../helpers/constants";
import { formatToUSDate } from "../../../../../../helpers/date.ts";
import {
  attachAssignInfoToPublishedAssessments,
  dividePracticeAndNotPracticeAssessments,
  isPaidAssessment,
  sortAssessmentsByNameAlphabetically,
  sortAssessmentsByNewest,
  sortAssessmentsByStatus,
} from "../../../../../../helpers/assessment";
import { filterItemsByPage } from "../../../../../../helpers/functions.ts";

const TAB = {
  AVAILABLE: 'Assessivs',
  PRACTICE: 'Practice',
};

const ASSESSMENTS_PER_PAGE = 10;

const tableColumns = [
  { content: 'Name', id: 'Name', className: 'pl-0 py-[14px] font-normal w-[35%]' },
  { content: 'Date', id: 'Date', className: '!text-center lg:text-center py-[14px] font-normal' },
  { content: 'Math Score', id: 'Math Score', className: 'text-center lg:text-right py-[14px] font-normal' },
  { content: 'RW Score', id: 'RW Score', className: 'text-center lg:text-right py-[14px] font-normal' },
  { content: 'Total Score', id: 'Total Score', className: 'text-center lg:text-right py-[14px] font-normal' },
  { content: 'Assigned by', id: 'Assigned by', className: 'text-right pr-0 py-[14px] font-normal' },
  { content: 'Status', id: 'Status', className: 'text-center lg:text-right pr-0 py-[14px] font-normal' },
];

export const StudentAssessments = ({ containerClassName = '' }) => {
  const navigate = useNavigate();
  const { publishedAssessments, assessments: assignedAssessments, setPublishedAssessments, setAssessmentId, setAssessmentVersionId, setEducatorId, resetResults, setResultsReady } = assessmentStore();
  const { studentsTableSortingType, availableAssessmentTablePage, assessmentTableTab, practiceAssessmentTablePage, setStudentsTableSortingType, setPracticeAssessmentTablePage, setAvailableAssessmentTablePage, setAssessmentTableTab,  } = tablesStore();
  const [currentTab, setCurrentTab] = useState(assessmentTableTab ?? TAB.PRACTICE);
  const [isFetching, setIsFetching] = useState(!publishedAssessments?.length);

  const handleOnClickTab = (tab) => {
    setCurrentTab(tab);
    setAssessmentTableTab(tab);
  };

  const [availableAssessments, practiceAssessments] = useMemo(() => {
      const assessmentsWithStatus = attachAssignInfoToPublishedAssessments(publishedAssessments, assignedAssessments);

      return dividePracticeAndNotPracticeAssessments(assessmentsWithStatus);
    },
    [publishedAssessments, assignedAssessments]
  );

  const assessments = useMemo(
    () => currentTab === TAB.AVAILABLE ? availableAssessments : practiceAssessments,
    [currentTab, availableAssessments, practiceAssessments]
  );

  const sortedAssessments = useMemo(() => {
    switch (studentsTableSortingType) {
      case SORTING.ALPHABETICAL: return sortAssessmentsByNameAlphabetically(assessments);
      case SORTING.NEWEST: return sortAssessmentsByNewest(assessments);
      default: return sortAssessmentsByStatus(assessments);
    }
  }, [assessments, studentsTableSortingType]);

  const filteredAssessments = useMemo(() => {
    const currentPage = currentTab === TAB.AVAILABLE ? availableAssessmentTablePage : practiceAssessmentTablePage;
    return filterItemsByPage(sortedAssessments, ASSESSMENTS_PER_PAGE, currentPage);
  }, [currentTab, availableAssessmentTablePage, practiceAssessmentTablePage, sortedAssessments]);

  const tabs = useMemo(() => {
    return [
      {
        id: TAB.PRACTICE,
        content: `${TAB.PRACTICE} ${isFetching ? '' : `(${practiceAssessments?.length ?? 0})`}`
      },
      {
        id: TAB.AVAILABLE,
        content: `${TAB.AVAILABLE} ${isFetching ? '' : `(${availableAssessments?.length ?? 0})`}`
      },
    ];
  }, [availableAssessments, practiceAssessments, isFetching]);

  const tableData = useMemo(() => {
    const handleSelectAssessment = (assessment) => () => {
      return Promise.all([
        setAssessmentId(assessment?.assessmentId),
        setAssessmentVersionId(assessment?.assessmentVersionId ?? assessment?.assessivVersionId ?? ''),
        setEducatorId(assessment?.educatorId ?? ''),
        resetResults(),
        setResultsReady(false)
      ])
        .then(() => navigate('/assessment/start'))
        .catch((error) => console.log(error));
    }

    return filteredAssessments?.map(assessment => (
      {
        id: `${assessment.assessmentId}${assessment.assessmentVersionId}${assessment.educatorId}`,
        onClick: isPaidAssessment(assessment) ? undefined : handleSelectAssessment(assessment),
        cells: [
          {
            className: 'pl-0 py-[14px]',
            id: assessment.assessmentId + 'Name',
            content: (
              <div className="flex items-center gap-3">
                {isPaidAssessment(assessment) && (
                  <Tooltip wrapperClassName="-mr-1.5" tooltipPopupClassName="!w-[156px]" text="Paid Assessiv. Payment required to access.">
                    <LockClosedIcon className="h-4" />
                  </Tooltip>
                )}
                {assessment.name}
                {(assessment?.status === ASSESSMENT_STATUS.FRESH || !assessment?.status) && (
                  <StatusLabel className="relative top-px" status={assessment?.status} />
                )}
              </div>
            )
          },
          {
            id: assessment.assessmentId + 'Date',
            className: 'text-center',
            content: formatToUSDate(assessment?.publishedDate ?? '') ?? '-'
          },
          {
            id: assessment.assessmentId + 'Math Score',
            className: 'text-center',
            content: '-'
          },
          {
            id: assessment.assessmentId + 'RW Score',
            className: 'text-center',
            content: '-'
          },
          {
            id: assessment.assessmentId + 'Total Score',
            className: 'text-center',
            content: '-'
          },
          {
            id: assessment.assessmentId + 'Assign',
            className: 'text-xs text-gray-500',
            content: typeof assessment?.isSelfAssign === 'boolean' ? (assessment?.isSelfAssign ? 'Self-assigned' : 'Educator') : '-'
          },
          {
            className: 'pr-0 font-normal',
            id: assessment.assessmentId + 'Status Score',
            content: (
              (assessment?.status === ASSESSMENT_STATUS.FRESH || !assessment?.status) ? (
                <span className="text-xs text-gray-500">Not Started</span>
              ) : (
                <StatusLabel status={assessment?.status} />
              )
            )
          },
        ]
      }
    )) ?? [];
  }, [filteredAssessments]);

  useEffect(() => {
    fetchPublishedAssessmentListByStudent()
      .then((data) => setPublishedAssessments(data?.data ?? []))
      .finally(() => setIsFetching(false));
  }, []);

  useEffect(() => {
    if (assessmentTableTab !== currentTab) {
      setCurrentTab(assessmentTableTab);
    }
  }, [assessmentTableTab, currentTab]);

  return (
    <div className={`flex flex-col p-8 md:p-6 text-gray-500 bg-white border border-softCloud rounded-lg shadow-sm ${containerClassName} ${INTROJS_SELECTOR.ASSESSMENTS}`}>
      <Tabs currentTabId={currentTab} onChangeTab={handleOnClickTab} tabs={tabs} />

      {isFetching ? (
        <div className="flex flex-col gap-1 py-[14px]">
          {Array.from({ length: 6 }, (_, index) => (
            <Skeleton key={index} className="h-8 w-full" />
          ))}
        </div>
      ) : assessments.length ? (
        <>
          <Table columns={tableColumns} rows={tableData} containerClassName="mb-2" columnRowClassName="text-xs" />

          <div className="flex items-center justify-between mt-2">
            <div className="opacity-0 pointer-events-none"><SortedComboBox /></div>
            <Pagination
              currentPage={currentTab === TAB.AVAILABLE ? availableAssessmentTablePage : practiceAssessmentTablePage}
              onPageChange={currentTab === TAB.AVAILABLE ? setAvailableAssessmentTablePage : setPracticeAssessmentTablePage}
              totalPages={Math.ceil((assessments?.length ?? 0) / 10)}
            />
            <SortedComboBox />
          </div>
        </>
      ) : (
        <div className="text-xl text-slate-500 text-center p-4 md:p-6 pt-8 md:pt-12">
          No assessments
        </div>
      )}
    </div>
  );
}