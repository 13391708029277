export const WhyAssessivs = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="max-w-2xl text-black text-center py-14 px-6">
        <span className="font-medium text-md">Why Assessiv?</span>
        <h1 className="font-bold text-4xl">Preparation Is Key</h1>
        <p className="text-gray-600 text-md mt-2">
          The Blue Book app is a great start—Assessiv is a great finish. Take your preparation to the next level with
          our practice testing and analysis. Learn where you need to improve so you can save time on test prep and focus
          on what you truly enjoy.
        </p>
      </div>
    </div>
  );
}