import { fetchSectionQuestions, logQuestionTime } from "../actions/assessment";
import { assessmentStore, questionStore, timerStore } from "./index";

export const setupSectionAndFirstTask = (firstSection, assessivId, assessivVersionId, setSubSectionData, setNewQuestion, educatorId, addAnsweredQuestion) => {
    return fetchSectionQuestions(assessivId, assessivVersionId, educatorId, firstSection?.sectionId, firstSection?.subjectId)
      .then((response) => {
        const responseData = response?.data ?? [].for;
        const firstQuestion = responseData?.[0];

        setSubSectionData(responseData ?? null).then(() => {
          responseData?.forEach?.(question => {
            if (question.userSelectedValue) {
              addAnsweredQuestion(question.questionId);
            }
          });
          if (firstQuestion) {
            return setNewQuestion({
              questionSelected: firstQuestion?.questionId ?? '',
              questionOrder: 1,
              sectionOrder: firstSection.sectionIndex + 1,
              sectionName: firstSection.name ?? '',
              sectionId: firstSection?.sectionId ?? ''
            });
          } else {
            return Promise.reject({ message: 'No questions' })
          }
        });
      });
}

export const logTime = () => {
  const { timeSpent } = timerStore.getState();
  const { sectionId, questionSelected } = questionStore.getState();
  const { assessmentId, assessmentVersionId, educatorId } = assessmentStore.getState();

  return logQuestionTime({
    sectionId,
    educatorId,
    time: timeSpent,
    questionId: questionSelected,
    assessivId: assessmentId,
    assessivVersionId: assessmentVersionId
  });
}