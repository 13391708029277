import React, {useState, useRef, useEffect} from 'react';
import { createPortal } from 'react-dom';
import Desmos from 'desmos';
import {
  DndContext,
  useDraggable, useDroppable,
} from '@dnd-kit/core';
import { Resizable } from 'react-resizable';

const Draggable = ({ isInitial, expression, setExpression, styles, id, toggleFloating }) => {
  const [disabled, setDisabled] = useState(false);
  const [size, setSize] = useState({ width: 380, height: 500 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const desmosContainer = useRef(null);
  const desmos = useRef(null);
  const { isDragging, attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    disabled,
  });

  const style = {
    transform: transform ? `translate3d(${position.x + transform.x}px, ${position.y + transform.y}px, 0)` : `translate3d(${position.x}px, ${position.y}px, 0)`,
    width: size.width,
    height: size.height,
    ...styles,
  };

  useEffect(() => {
    if (desmosContainer.current && !desmos.current) {
      desmos.current = Desmos.GraphingCalculator(desmosContainer.current);

      if (expression) {
        expression?.forEach(item => {
          desmos.current.setExpression(item);
        })
      }

      desmos.current.observeEvent('change', function() {
        const updatedExpression = desmos.current?.getExpressions?.();
        if (updatedExpression) {
          setExpression(updatedExpression);
        }
      });
    }

    return () => {
      desmos.current?.destroy?.();
    }
  }, []);

  const handleResize = (event, { size: resizeSize, handle }) => {
    const newPosition = { ...position };

    if (handle.includes('w')) {
      newPosition.x += position.x - (position.x + resizeSize.width - size.width);
    }
    if (handle.includes('n')) {
      newPosition.y += position.y - (position.y + resizeSize.height - size.height);
    }

    setSize(resizeSize);
    setPosition(newPosition);
  };

  const getHandlerClassName = (direction) => {
    switch (direction) {
      case 'nw': return '-top-1 -left-1 h-4 w-4';
      case 'n': return '-top-1 left-[calc(50%-24px)] h-4 w-12';
      case 'ne': return '-top-1 -right-1 h-4 w-4';
      case 'e': return 'top-[calc(50%-24px)] -right-1 h-12 w-4';
      case 'se': return '-bottom-1 -right-1 h-4 w-4';
      case 's': return '-bottom-1 right-[calc(50%-24px)] h-4 w-12';
      case 'sw': return '-bottom-1 -left-1 h-4 w-4';
      case 'w': return 'bottom-[calc(50%-24px)] -left-1 h-12 w-4';
      default: return '';
    }
  }

  return (
    <Resizable
      width={size.width}
      height={size.height}
      onResize={handleResize}
      resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
      handle={(handleAxis, ref) => {
        return (
          <div
            ref={ref}
            className={`bg-transparent absolute ${getHandlerClassName(handleAxis)} ${isInitial ? 'hidden' : ''}`}
            style={{
              cursor: `${handleAxis}-resize`
            }}
            onMouseOver={() => setDisabled(true)}
            onMouseLeave={() => () => setDisabled(false)}
          />
        )
      }}
    >
      <div
        ref={setNodeRef}
        style={{ ...styles, ...style, width: size.width, height: size.height }}
        {...listeners}
        {...attributes}
        className={`${isInitial ? '' : `bg-white p-4 border z-[11] shadow-lg ${isDragging ? 'cursor-grabbing' : 'cursor-grab'}`}`}
      >
        <div
          className="cursor-auto"
          ref={desmosContainer}
          style={{width: '100%', height: '100%'}}
          onMouseOver={() => setDisabled(true)}
          onMouseLeave={() => setDisabled(false)}
        />
        <button
          onClick={toggleFloating}
          onMouseOver={() => setDisabled(true)}
          className={`flex items-center justify-center text-black absolute ${isInitial ? 'top-12 right-1.5' : 'top-16 right-[22px]'} bg-[#ededed] w-[37px] h-[37px] border border-[rgba(0,0,0,0.1)] rounded-[5px] shadow-[0_0_5px_rgba(0,0,0,0.15)]`}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
               className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
          </svg>
        </button>
      </div>
    </Resizable>
  );
}

const DroppableArea = ({children}) => {
  const { setNodeRef } = useDroppable({
    id: 'droppable',
  });

  return (
    <div ref={setNodeRef}>
      {children}
    </div>
  );
};

const DesmosCalculator = ({toggleFloating, expression, setExpression}) => {
  const [position, setPosition] = useState({x: 36, y: 96});

  function handleDragEnd(ev) {
    setPosition({
      x: position.x + ev.delta.x,
      y: position.y + ev.delta.y,
    });
  }

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <DroppableArea>
        <Draggable
          expression={expression}
          setExpression={setExpression}
          toggleFloating={toggleFloating}
          id="floating-calculator"
          styles={{
            position: "absolute",
            left: `${position.x}px`,
            top: `${position.y}px`
          }}
        />
      </DroppableArea>
    </DndContext>
  );
};

export const FloatingCalculator = () => {
  const [expression, setExpression] = useState([]);
  const [isFloating, setIsFloating] = useState(false);

  if (isFloating) {
    return createPortal(
      <DesmosCalculator
        expression={expression}
        setExpression={setExpression}
        toggleFloating={() => {
          setIsFloating(false);
        }}
      />,
      document.body
    );
  }

  return (
    <Draggable
      isInitial
      expression={expression}
      setExpression={setExpression}
      toggleFloating={() => setIsFloating(true)}
      id="floating-calculator"
      styles={{ position: 'relative'}}
    />
  );
};