import { useMemo } from "react";
import { TagIcon } from "@heroicons/react/24/outline";
import { ASSESSIV_PLAN } from "./AssessivPlanSelect";
import { BILLING_PLAN } from "./BillingPlanSelect";

const PricingSummaryBlock = ({ title, value, billingPlan }) => {
  return (
    <div className="flex flex-col gap-0.5">
      <h3 className="text-sm font-bold text-neutral-500">
        {title}
      </h3>
      <p className="text-black font-semibold text-xl">
        ${value}
        <span className="text-sm">{billingPlan === BILLING_PLAN.MONTHLY ? '/mo' : '/year'}</span>
      </p>
    </div>
  );
}

const SALES_MONTHLY_PER_SEAT_PRICE = 125;
const SALES_ANNUALLY_PER_SEAT_PRICE = 1200;
const DEFAULT_MONTHLY_PER_SEAT_PRICE = 150;
const DEFAULT_ANNUALLY_PER_SEAT_PRICE = 1500;

export const PricingSummary = ({
  billingPlan,
  seats,
  assessivPlan,
}) => {
  const perSeatPrice = useMemo(() => {
    if (assessivPlan === ASSESSIV_PLAN.COMPANY_EARLY_BIRD) {
      return billingPlan === BILLING_PLAN.MONTHLY ? SALES_MONTHLY_PER_SEAT_PRICE : SALES_ANNUALLY_PER_SEAT_PRICE;
    }

    return billingPlan === BILLING_PLAN.MONTHLY ? DEFAULT_MONTHLY_PER_SEAT_PRICE : DEFAULT_ANNUALLY_PER_SEAT_PRICE;
  }, [assessivPlan, billingPlan]);

  return (
    <div className="flex flex-col gap-4 rounded-md p-4 shadow border border-neutral-300 bg-neutral-100 w-80">
      <PricingSummaryBlock
        title="Seat"
        value={perSeatPrice}
        billingPlan={billingPlan}
      />

      <PricingSummaryBlock
        title="Total (USD)"
        value={perSeatPrice * (+seats)}
        billingPlan={billingPlan}
      />

      <div className="flex gap-2 text-sm font-semibold border-t border-neutral-300 px-4 pt-2 -mx-4">
        <TagIcon className="h-6 text-neutral-400"/>
        <span className="relative top-1 text-black">
          Save 18% with an annual plan
        </span>
      </div>
    </div>
  );
}