import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { SafeHTML } from "../../../../../helpers/safeHtml";

const Alphabets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

const isUserAnswerEmpty = (answer) => {
  if (answer?.isGridIn) {
    return answer?.userSelectedValue?.[0] === "";
  }

  return answer?.userSelectedValueIndex === null;
}

const getAnswerContent = (answer) => {
  if (answer?.isGridIn) {
    return answer?.userSelectedValue?.[0];
  }

  return Alphabets[answer?.userSelectedValueIndex ?? 0];
}

const getClass = (isLastCell, isAnswerCorrect) => {
  if (!isLastCell) {
    return 'border-dashed border-gray-300';
  }

  return isAnswerCorrect ?
    'bg-green-100 text-green-600 border-green-100' :
    'bg-red-100 text-red-600 border-red-100';
}

export const SelectedAnswer = ({ answer }) => {
  const [lastChoicesVisible, setLastChoicesVisible] = useState(false);

  if (isUserAnswerEmpty(answer)) {
    return (
      <div className="border-2 border-red-100 border-dashed text-red-600 px-4 m-1 rounded">
        Omitted
      </div>
    );
  }

  const lastChoices = answer?.userPreviousSelectedValues?.split(',') ?? [];
  const isLastChoicesModalEnabled = lastChoices.length > 1;
  const answerContent = getAnswerContent(answer);

  const openModal = () => {
    if (isLastChoicesModalEnabled) {
      setLastChoicesVisible(true);
    }
  }

  const closeModal = () => {
    if (isLastChoicesModalEnabled) {
      setLastChoicesVisible(false);
    }
  }

  return (
    <div
      className={`m-1 rounded relative ${answer.isCorrect ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}`}
      onMouseOver={openModal}
      onMouseLeave={closeModal}
    >
      <div className="flex items-center justify-between gap-2 px-4 py-1 w-full h-full">
        <SafeHTML html={answerContent} />

        {isLastChoicesModalEnabled && (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"/>
          </svg>
        )}
      </div>

      <Transition
        show={isLastChoicesModalEnabled && lastChoicesVisible}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div className="absolute top-7 left-0 z-20 h-0 w-full flex justify-center overflow-visible cursor-auto" onClick={(event) => event.stopPropagation()}>
          <div className="border border-gray-300 rounded shadow-md text-gray-500 w-full min-w-max h-max">
            <div className="text-center p-2 border-b rounded-t bg-gray-100">Last selections</div>
            <div className="flex flex-col items-stretch gap-2 p-2 bg-white rounded-b">
              {[...lastChoices]
                .slice(-3)
                .map((choice, index, array) => (
                  <div
                    key={index}
                    className={`px-4 py-1 rounded border-2 ${getClass((index + 1) === array.length, answer.isCorrect)}`}
                  >
                    {answer.isGridIn ? choice : Alphabets[Number(choice) - 1]}
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}