import { TermlyDoc } from "../../components/TermlyDoc";

export const Privacy = () => {
  return (
    <TermlyDoc
      dataId="03b275b9-5c41-40ae-86c6-21fa25809972"
    />
  );
}

export const Terms = () => {
  return (
    <TermlyDoc
      dataId="0843083d-17fc-420d-90b9-9b875ecabb9b"
    />
  );
}

export const EULA = () => {
  return (
    <TermlyDoc
      dataId="531b9f5a-6fa6-457e-9537-37b4e1e39a73"
    />
  );
}

export const CookiesPolicy = () => {
  return (
    <TermlyDoc
      dataId="59e04cef-61f1-4940-8e93-b5c5016df4c2"
    />
  );
}
