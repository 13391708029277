import { useState } from "react";
import Dropzone from "react-dropzone";
import toast from "react-hot-toast";
import { ArrowUpTrayIcon } from "@heroicons/react/20/solid";
import { EditLogo } from "./EditLogo";
import defaultLogo from "../../../../assets/logo.svg";
import { loginStore } from "../../../../store";
import { Abbreviator } from "../../../../helpers/index.ts";
import { fetchCompanyImage, uploadCompanyImage } from "../../../../actions/registration";

const loadCompanyImage = () => {
  const { setCompanyLogo } = loginStore.getState();
  fetchCompanyImage()
    .then((data) => {
      setCompanyLogo(data?.data)
    })
    .catch(console.log);
}

function base64ToBlob(base64String, mimeType = 'image/png') {
  const base64WithoutPrefix = base64String.split(',')[1];
  const binaryString = atob(base64WithoutPrefix);
  const arrayBuffer = new ArrayBuffer(binaryString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }
  return new Blob([uint8Array], { type: mimeType });
}

export const UploadCompanyLogo = () => {
  const { fullName, companyLogo, setCompanyLogo } = loginStore();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const handleOnDropImage = (files) => {
    const file = files[0];

    if (file.size > 2 * 1024 * 1024) {
      toast.error('File size exceeds 2MB limit.', { duration: 3000 });
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const formData = new FormData();
        formData.append('formFile', file);
        uploadCompanyImage(formData)
          .then(() => {
            loadCompanyImage();
            setIsEditModalVisible(true);
            setCompanyLogo(reader.result);
          })
          .catch(console.log);
      };
      reader.readAsDataURL(file);
    }
  }

  const handleUpdateImage = (newCompanyBase64Image) => {
    if (!newCompanyBase64Image) {
      console.error('No image data provided.');
      return;
    }

    const blob = base64ToBlob(newCompanyBase64Image);
    const file = new File([blob], "companyLogo.png", { type: blob.type });
    const formData = new FormData();
    formData.append('formFile', file);

    const oldImgValue = companyLogo;
    setCompanyLogo(newCompanyBase64Image);
    uploadCompanyImage(formData)
      .then(() => {
        loadCompanyImage();
      })
      .catch((error) => {
        setCompanyLogo(oldImgValue);
        console.log(error);
      });
  }

  const handleRemoveLogo = async () => {
    try {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        canvas.toBlob(async (blob) => {
          const file = new File([blob], "defaultLogo.png", { type: 'image/png' });
          const formData = new FormData();
          formData.append('formFile', file);

          try {
            await uploadCompanyImage(formData);
            loadCompanyImage();
          } catch (error) {
            console.error('Error uploading default logo:', error);
          }
        }, 'image/png');
      };

      img.onerror = () => {
        console.error('Error loading default logo SVG');
      };

      img.src = defaultLogo;
    } catch (error) {
      console.error('Error in handleRemoveLogo:', error);
    }
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-2">
        <h2 className="font-semibold text-sm text-gray-700 self-start">
          Upload Company Logo
        </h2>
        <span className="text-xs text-gray-300">
          (max size: 2mb)
        </span>
      </div>
      <div className="flex items-end gap-2">
        <div
          className={`flex items-center justify-center w-16 h-16 rounded-md overflow-hidden text-black text-xl font-medium border ${companyLogo ? 'bg-transparent' : 'bg-yellow-500 '}`}>
          {companyLogo ? (
            <img
              className="max-w-full h-auto"
              src={companyLogo}
              alt="logo"
              onError={() => setCompanyLogo('')}
            />
          ) : (
            Abbreviator(fullName)
          )}
        </div>
        <Dropzone
          noClick
          noKeyboard
          className="w-16 h-16"
          onDrop={handleOnDropImage}
        >
          {({getRootProps, getInputProps}) => (
            <label
              {...getRootProps()}
              className="text-sm flex items-center gap-2 px-3 py-1 border border-grayLight rounded-md cursor-pointer h-9"
            >
              <ArrowUpTrayIcon className="w-4 h-4"/>
              Upload
              <input {...getInputProps()} accept="image/jpeg, image/png"/>
            </label>
          )}
        </Dropzone>
        {!!companyLogo && (
          <>
            <EditLogo
              image={companyLogo}
              setImage={handleUpdateImage}
              isEditModalVisible={isEditModalVisible}
              setIsEditModalVisible={setIsEditModalVisible}
            />
            <button
              className="text-sm text-white flex items-center gap-2 px-3 py-1 bg-red-500 hover:bg-red-600 rounded-md cursor-pointer h-9"
              onClick={handleRemoveLogo}
            >
              Remove logo
            </button>
          </>
        )}
      </div>
    </div>
  );
}