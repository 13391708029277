import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FeaturesSection } from "./FeaturesSection";
import { PlansSection } from "./PlansSection";

export const PricingModal = ({ isOpen, setOpen }) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen h-screen flex">
          <div className="flex items-end justify-center w-full p-4 text-center sm:items-center sm:p-0 my-6">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative max-h-full transform overflow-auto rounded-lg bg-white p-10 text-left shadow-xl transition-all sm:w-full sm:max-w-7xl scrollbar-thumb-gray-400 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md">
                <PlansSection />
                <FeaturesSection />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}