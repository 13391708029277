export const subSections = [
  {
    sID: 1,
    secOrder: 1,
    secType: "BASE",
    secTitle: "English Section 1",
    isExpanded: true,
    isHidden: false,
    questions: [
      {
        qID: "UOWAJ8GN51",
        qOrder: 1,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "695GZ470MU",
        qOrder: 2,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "HTO6PXJS5L",
        qOrder: 3,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "LQIJPWY7UE",
        qOrder: 4,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "XZKZ4GHNCT",
        qOrder: 5,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "ZGWBDHTWGZ",
        qOrder: 6,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "6Q620SWVBD",
        qOrder: 7,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "KLNSRMNVMQ",
        qOrder: 8,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "VHON05D0AM",
        qOrder: 9,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "N216IX876G",
        qOrder: 10,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "F7X5RBGQII",
        qOrder: 11,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
    ],
  },
  {
    sID: 2,
    secOrder: 2,
    secType: "2E",
    secTitle: "English Section 2",
    isExpanded: false,
    isHidden: false,
    questions: [
      {
        qID: "6UCGD7COJQ",
        qOrder: 1,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "B3BUA6WEWI",
        qOrder: 2,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "SQ38V14KT7",
        qOrder: 3,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "L296KN0AQP",
        qOrder: 4,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "H7739RGH4D",
        qOrder: 5,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "NQ6RHWB603",
        qOrder: 6,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "ZMDK7XRD7W",
        qOrder: 7,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "YRJS3R3NM1",
        qOrder: 8,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "57OQ4NG3UF",
        qOrder: 9,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "6FVMJI7PAQ",
        qOrder: 10,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "R628SKVONB",
        qOrder: 11,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
    ],
  },
  {
    sID: 3,
    secOrder: 2,
    secType: "2H",
    secTitle: "English Section 2",
    isExpanded: false,
    isHidden: true,
    questions: [
      {
        qID: "KMWNCUZUNU",
        qOrder: 1,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "Z26W9FAEMZ",
        qOrder: 2,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "67J6X3AM6R",
        qOrder: 3,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "X7N7KVN0NB",
        qOrder: 4,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "MILIAWQFXA",
        qOrder: 5,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "VFVEJGO5FF",
        qOrder: 6,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "75MKS6NV2C",
        qOrder: 7,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "IFT8CTLA20",
        qOrder: 8,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "IOYRVGFUSJ",
        qOrder: 9,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "F292LNG2WK",
        qOrder: 10,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "MY478ITC05",
        qOrder: 11,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
    ],
  },
  {
    sID: 4,
    secOrder: 3,
    secType: "BASE",
    secTitle: "Math Section 1",
    isExpanded: false,
    isHidden: false,
    questions: [
      {
        qID: "6U6IP17LPG",
        qOrder: 1,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "NXEHCI6L5S",
        qOrder: 2,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "7FS8I793VD",
        qOrder: 3,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "RS0RBJXUDQ",
        qOrder: 4,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "LIZU967BWD",
        qOrder: 5,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "6YP8VIDUJP",
        qOrder: 6,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "38W1Z8GKUV",
        qOrder: 7,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "3N912RL2XU",
        qOrder: 8,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "JETWT4VXF6",
        qOrder: 9,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
    ],
  },
  {
    sID: 5,
    secOrder: 4,
    secType: "2E",
    secTitle: "Math Section 2",
    isExpanded: false,
    isHidden: false,
    questions: [
      {
        qID: "3B4WJCPDDN",
        qOrder: 1,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "X3FR6ZFVOC",
        qOrder: 2,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "XZVRSPNMOH",
        qOrder: 3,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "GNMZD18VWM",
        qOrder: 4,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "FDQJPUCNLS",
        qOrder: 5,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "BQMOZ5CST4",
        qOrder: 6,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "Z4QO9NDEHW",
        qOrder: 7,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "PO3QA2PYLL",
        qOrder: 8,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "WAF7BX2M8S",
        qOrder: 9,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
    ],
  },
  {
    sID: 6,
    secOrder: 4,
    secType: "2H",
    secTitle: "Math Section 2",
    isExpanded: false,
    isHidden: true,
    questions: [
      {
        qID: "K0OQI6WG31",
        qOrder: 1,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "VGYR4LIGOF",
        qOrder: 2,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "N9T3WXI0QK",
        qOrder: 3,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "16JW3ZH427",
        qOrder: 4,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "UOY52PT226",
        qOrder: 5,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "0XT6YRG25W",
        qOrder: 6,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "6ETLIDJIVV",
        qOrder: 7,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "5OB33SG8AZ",
        qOrder: 8,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
      {
        qID: "7HE0NWGRYE",
        qOrder: 9,
        qChoice: null,
        timeSpent: 0,
        flagged: false,
      },
    ],
  },
];