import { useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as RetractIcon } from "../../../../../../assets/studentPage/retract.svg";
import { ReactComponent as ExpandIcon } from "../../../../../../assets/studentPage/expand.svg";
import { useClickOutside } from "../../../../../../helpers/hooks/useClickOutside.ts";

const backdropVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const backdropTransition = {
  duration: 0.3
};

export const ExpandedSection = ({
  className = '',
  buttonClassName = '',
  renderContent,
  handleRetract,
  selectedId
}) => {

  const ref = useRef(null);

  useClickOutside(ref, handleRetract);

  return (
    <AnimatePresence>
      {!!selectedId && (
        <motion.div
          className={`fixed top-0 left-0 right-0 bottom-0 z-50 max-h-screen flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 ${className}`}
        >
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={backdropTransition}
            variants={backdropVariants} className="absolute inset-0 bg-black bg-opacity-20 z-[1]"
          />

          <motion.div
            ref={ref}
            layoutId={selectedId}
            className="flex-1 lg:mx-[12%] relative z-[2] max-w-full max-h-full overflow-auto scrollbar-thumb-gray-300 scrollbar-track-gray-200 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md"
          >
            {renderContent()}

            <button className={`absolute top-9 right-8 ${buttonClassName}`} onClick={handleRetract}>
              <ExpandIcon className="h-4 w-4" />
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export const ExpandButtonWrapper = ({ children, layoutId, onClickExpand, buttonClassName, containerClassName = '', stopPropagationMouseUp }) => {
  const handleStopPropagation = (event) => {
    event.stopPropagation();
  }

  return (
    <motion.div layoutId={layoutId} className={`relative ${containerClassName}`}>
      {children}

      <button
        className={buttonClassName ?? 'absolute top-7 right-8'}
        onClick={onClickExpand}
        onMouseUp={stopPropagationMouseUp ? handleStopPropagation : undefined}
      >
        <RetractIcon className="h-4 w-4" />
      </button>
    </motion.div>
  );
}