import { useState } from "react";
import { Modal } from "./Modal";
import { ReactComponent as Check } from '../assets/Check.svg';

export const COLOR_VARIANTS = {
  RED: 'red',
  BLUE: 'blue',
  ORANGE: 'orange',
};

const getColorClassNames = (variant) => {
  const classNameVariants = {
    [COLOR_VARIANTS.BLUE]: {
      backgroundClassName: 'bg-skyBlue',
      borderClassName: 'border-skyBlue',
      ringClassName: 'ring-skyBlue',
    },
    [COLOR_VARIANTS.RED]: {
      backgroundClassName: 'bg-red-500',
      borderClassName: 'border-red-500',
      ringClassName: '!ring-red-300',
    },
    [COLOR_VARIANTS.ORANGE]: {
      backgroundClassName: 'bg-orange-400',
      borderClassName: 'border-orange-400',
      ringClassName: '!ring-orange-200',
    },
  };

  return classNameVariants[variant] ?? classNameVariants[COLOR_VARIANTS.BLUE];
}

export const ConfirmationModal = ({
  colorVariant = COLOR_VARIANTS.BLUE,
  cancelButtonText = 'Cancel',
  checkBoxText = 'I agree',
  confirmButtonText = 'Confirm',
  description,
  title = 'Confirm',
  handleCloseModal,
  handleConfirm,
}) => {
  const [confirmCheckboxValue, setConfirmCheckboxValue] = useState(false);

  const { backgroundClassName, borderClassName, ringClassName } = getColorClassNames(colorVariant);

  return (
    <Modal
      confirmButtonBackground={`${backgroundClassName} ${ringClassName}`}
      cancelButtonText={cancelButtonText}
      confirmButtonText={confirmButtonText}
      title={title}
      isConfirmButtonDisabled={!confirmCheckboxValue}
      handleCloseModal={handleCloseModal}
      handleConfirm={handleConfirm}
    >
      <p className="text-sm text-left text-duskyHarbor">{description}</p>

      <label className="flex items-center gap-2 text-sm text-navySmoke relative cursor-pointer select-none">
        <input
          className="absolute w-0 h-0 opacity-0"
          type="checkbox"
          checked={confirmCheckboxValue}
          onChange={() => setConfirmCheckboxValue(!confirmCheckboxValue)}
        />

        <div className={`flex items-center justify-center w-5 h-5 border rounded ${confirmCheckboxValue ? `${backgroundClassName} ${borderClassName}` : 'border-whisperBlue bg-transparent'}`}>
          {confirmCheckboxValue && <Check />}
        </div>

        {checkBoxText}
      </label>
    </Modal>
  );
}