import { ImagePlaceholder } from "../../../../components/ImagePlaceholder";
import { formatDateWithDynamicTimeZone } from "../../../../helpers/date.ts";
import { formatAssessivSettings } from "../../utils";

export const ScoreSummary = ({ results, classname = '' }) => {

  const settings = formatAssessivSettings(results?.assessivSettings ?? {});

  return (
    <div className={`flex flex-row w-full items-start justify-between gap-6 ${classname}`}>
      <div className="flex gap-6 flex-col w-40">
        <div className="flex-1 flex-col gap-1 rounded-xl flex justify-center items-center">
          <ImagePlaceholder src={results?.imageLogo ?? ''}/>
          <span className="text-sm font-medium">Score Summary</span>
        </div>
        <div className="flex-1 rounded-xl"></div>
      </div>

      <div className="text-right space-y-1 text-sm">
        <div className="font-semibold">
          Student name: <span className="font-normal">{results?.studentName ?? '-'}</span>
        </div>
        <div className="font-semibold">
          Date of completion: <span
          className="font-normal">{formatDateWithDynamicTimeZone(results?.completedDate) ?? '-'}</span>
        </div>
        <div className="font-semibold">
          Assessiv name: <span className="font-normal">{results?.assessmentName ?? '-'}</span>
        </div>
        <div className="font-semibold">
          Assigned by: <span
          className="font-normal">{(results?.isSelfAssign ? 'Self-assigned' : results?.educatorName) ?? '-'}</span>
        </div>
        {settings?.map(setting => (
          <div key={setting.name + setting.value} className="font-semibold">
            {setting.name}: <span className="font-normal">{setting.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}