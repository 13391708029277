import { ReactComponent as AnswersImage } from "../../../assets/qAnswers.svg";

export const Answers = () => {
  return (
    <div
      className="flex justify-center md:justify-end bg-slate-500 h-658px] w-full bg-center md:bg-cover py-14 md:pr-8"
      style={{
        backgroundImage: `url('/smilingStudentLaptop.png')`,
        backgroundPositionY: '-6em'
      }}
    >
      <AnswersImage className="h-[26em]"/>
    </div>
  );
}