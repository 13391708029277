import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo_white.svg";

export const Footer = () => {
  const scrollTop = () => {
    document?.body?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <footer className="text-white px-10 py-5 bg-black">
      <div className="flex justify-between items-start max-w-6xl mx-auto">
        <div className="flex flex-col items-start justify-center gap-2">
          <Logo
            className="mb-1 cursor-pointer"
            onClick={scrollTop}
          />

          {/*<p className="text-sm">+1 123 456 789</p>*/}
          <p className="text-sm">support@assessiv.com</p>
        </div>

        <div className="flex flex-col gap-2 text-sm">
          <h2 className="text-lg font-semibold">
            Policies
          </h2>
          <NavLink to="/privacy">
            Privacy Policy
          </NavLink>
          <NavLink to="/terms">
            Terms Of Service
          </NavLink>
          <NavLink to="/EULA">
            EULA
          </NavLink>
          <NavLink to="/cookies-policy">
            Cookies Policy
          </NavLink>
        </div>

        <div className="flex flex-col gap-2 text-sm text-left">
          <h3 className="text-lg font-semibold">
            Latest News
          </h3>
          {/*<button className="w-max">*/}
          {/*  Blog*/}
          {/*</button>*/}
          <button className="w-max">
            About As
          </button>
          {/*<button className="w-max">*/}
          {/*  The History of Development*/}
          {/*</button>*/}
        </div>
      </div>
    </footer>
  );
};