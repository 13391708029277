import { useEffect, useRef } from "react";

// Quill dependency
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";

// KaTeX dependency
import katex from "katex";
import "katex/dist/katex.css";

// MathQuill dependency
import '../helpers/jquerry';
import mathquill4quill from "mathquill4quill";
import "@edtr-io/mathquill/build/mathquill.js";
import "@edtr-io/mathquill/build/mathquill.css";

window.katex = katex;

Quill.register("modules/imageResize", ImageResize);

const options = {
  operators: [
    ["\\pm", "\\pm"],
    ["\\sqrt{x}", "\\sqrt"],
    ["\\sqrt[3]{x}", "\\sqrt[3]{}"],
    ["\\sqrt[n]{x}", "\\nthroot"],
    ["\\frac{x}{y}", "\\frac"],
    ["\\sum^{s}_{x}{d}", "\\sum"],
    ["\\prod^{s}_{x}{d}", "\\prod"],
    ["\\coprod^{s}_{x}{d}", "\\coprod"],
    ["\\int^{s}_{x}{d}", "\\int"],
    ["\\binom{n}{k}", "\\binom"],
  ],
  displayHistory: true,
  displayDeleteButtonOnHistory: true
};

const Editor = ({ value, getHTMLout }) => {
  const reactQuill = useRef();

  useEffect(() => {
    const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex });
    enableMathQuillFormulaAuthoring(reactQuill.current.editor, options);
  }, []);

  return (
    <ReactQuill
      ref={reactQuill}
      modules={{
        formula: true,
        toolbar: [["bold", "italic", "underline", "link", "image", "formula", "clean"]],
        imageResize: {
          parchment: Quill.import("parchment"),
          modules: ["Resize", "DisplaySize"],
        },
      }}
      placeholder="Type text here, or click on the formula button to enter math."
      theme="snow"
      value={value}
      onChange={getHTMLout}
    />
  );
};

export default Editor;