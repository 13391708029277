export const SectionDomain = ({ tagSection }) => {
  return (
    <div className="border flex flex-col border-gray-300 w-full rounded-xl mt-4 p-4 gap-4">
      {!!tagSection?.sectionName && (
        <span
          className="text-gray-600 text-sm pb-2 border-b border-gray-200">{tagSection?.sectionName}</span>
      )}

      <table className="text-sm -mt-1">
        <thead>
        <tr>
          <th className="w-[30%]"/>
          <th className="w-[10%] text-center font-medium pb-3">Right</th>
          <th className="w-[10%] text-center font-medium pb-3">Wrong</th>
          <th className="w-[10%] text-center font-medium pb-3">Blank</th>
          <th className="w-[10%] text-center font-medium pb-3">Total</th>
          <th className="w-[30%]"/>
        </tr>
        </thead>
        <tbody>

        {tagSection.tagDomains.map(({tagValue, correctAnswer, inCorrect, omitted, totalQuestion}) => {
          const correctPercentage = totalQuestion === 0 ? 0 : correctAnswer / totalQuestion * 100;
          const incorrectPercentage = totalQuestion === 0 ? 0 : inCorrect / totalQuestion * 100;
          const omittedPercentage = totalQuestion === 0 ? 0 : omitted / totalQuestion * 100;
          const showFirstSeparateLine = (correctPercentage > 0) && (incorrectPercentage > 0 || omittedPercentage > 0);
          const showSecondSeparateLine = (omittedPercentage > 0) && (incorrectPercentage > 0 || correctPercentage > 0);

          return (
            <tr key={tagValue}>
              <td className="border-t py-3">{tagValue}</td>
              <td className="border-t py-3 text-center">{correctAnswer}</td>
              <td className="border-t py-3 text-center">{inCorrect}</td>
              <td className="border-t py-3 text-center">{omitted}</td>
              <td className="border-t py-3 text-center">{totalQuestion}</td>
              <td className="border-t py-3 pl-2">
                <div className="flex items-stretch border border-fadedDenim w-full h-5 rounded-full overflow-hidden">
                  <div style={{width: `${correctPercentage}%`}} className="bg-emeraldGreen"/>

                  {showFirstSeparateLine && <div className="border-l border-black"/>}

                  <div style={{width: `${incorrectPercentage}%`}} className="bg-persimmon"/>

                  {showSecondSeparateLine && <div className="border-l border-black"/>}

                  <div style={{width: `${omittedPercentage}%`}} className="bg-pumpkinOrange"/>
                </div>
              </td>
            </tr>
          )
        })}
        </tbody>
      </table>
    </div>
  );
}