import { useState, Fragment } from "react";
import { PricingModal } from "./PricingModal";

export const Pricing = ({ buttonClassName = '' }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <>
      <button
        className={`items-center justify-center text-white text-md font-medium ${buttonClassName}`}
        onClick={() => setIsModalOpened(true)}
      >
        Pricing
      </button>

      {isModalOpened && (
        <PricingModal
          isOpen={isModalOpened}
          setOpen={setIsModalOpened}
        />
      )}
    </>
  );
}