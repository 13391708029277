import { useEffect, useState } from "react";
import { AreaForImprovementTile } from "./AreaForImprovementTile";
import { fetchImprovement } from "../../../../../../../actions/dashboard";
import { assessmentStore } from "../../../../../../../store";
import { Skeleton } from "../../../../../../../components/Skeleton";

export const AreasForImprovement = () => {
  const { areasForImprovement, setAreasForImprovement } = assessmentStore();
  const areas = Object.keys(areasForImprovement);
  const [isFetching, setIsFetching] = useState(!areas?.length);

  useEffect(() => {
    fetchImprovement()
      .then((data) => {
        setAreasForImprovement(data?.data ?? {});
      })
      .catch((error) => {
        setAreasForImprovement({});
        console.log(error);
      })
      .finally(() => setIsFetching(false));
  }, []);

  return (
    <div
      className="flex flex-col p-8 md:p-6 gap-6 text-gray-500 bg-white border border-softCloud rounded-lg relative shadow-sm">
      <h2 className="text-base text-black font-medium pb-3 cursor-pointer border-b-2 min-w-max border-transparent">
        Areas For Improvement
      </h2>

      {isFetching ? (
        <div className="grid grid-cols-4 lg:grid-cols-5 gap-6">
          {Array.from({ length: 5 }, (_, index) => (
            <Skeleton key={index} className="h-[154px] w-full" />
          ))}
        </div>
      ) : (
        <>
          {areas.length ? (
            <div className="grid grid-cols-4 lg:grid-cols-5 gap-6">
              {areas.map((areaName, index) => {
                const { Correct, InCorrect } = areasForImprovement[areaName] ?? {};
                return (
                  <AreaForImprovementTile
                    key={areaName}
                    name={areaName}
                    correct={Correct}
                    inCorrect={InCorrect}
                    index={index}
                  />
                );
              })}
            </div>
          ) : (
            <div className="text-xl text-slate-500 text-center p-4 pt-0 md:p-6 md:pt-0 w-full">
              No data
            </div>
          )}
        </>
      )}
    </div>
  );
}