import { SpinLoader } from "./SpinLoader";

export const InputLoaderWrapper = ({ children, isLoaderVisible, className = '' }) => {
  return (
    <div className={`flex items-center relative ${className}`}>
      {children}

      {isLoaderVisible && (
        <SpinLoader className="absolute right-4"/>
      )}
    </div>
  );
}