import { RefObject, useEffect } from "react";

export const useClickOutside = (elementRef: RefObject<HTMLElement>, callback: () => unknown) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (elementRef.current && !elementRef.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('mouseup', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, []);
}
