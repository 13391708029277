import { useMemo } from "react";
import { ResultsTable } from "./ResultTable";
import { isAssessmentPractice } from "./utils";
import { createSortingByLatestFirstCallback } from "../../helpers/date.ts";

export const CompletedResultsTable = ({ results, containerRef, noContentContainerRef }) => {
  const filteredResults = useMemo(() => {
    return results
      ?.filter(result => !!result?.revisions?.length)
      ?.map(result => ({
        ...result,
        result: result?.revisions?.toSorted(createSortingByLatestFirstCallback((revision) => revision?.completedDate))
      }))
      ?.toSorted(createSortingByLatestFirstCallback((assessiv) => assessiv?.revisions?.[0]?.completedDate));
  }, [results]);

  const [regularResults, practiceResults] = useMemo(() => {
    const regular = [];
    const practice = [];

    filteredResults?.forEach(result => {
      const resultArray = isAssessmentPractice(result) ? practice : regular;
      resultArray.push(result);
    });

    return [
      regular,
      practice
    ];
  }, [filteredResults]);

  if (!filteredResults?.length) {
    return (
      <div ref={noContentContainerRef} className="text-sm text-slate-500 text-center p-4 md:p-6 w-full">
        No completed assessments
      </div>
    );
  }

  return (
    <div ref={containerRef} className="w-full h-[10px]">
      {!!regularResults.length && (
        <ResultsTable results={regularResults}/>
      )}

      {!!practiceResults.length && (
        <ResultsTable
          isPractice
          results={practiceResults}
        />
      )}
    </div>
  );
}