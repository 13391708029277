import { useEffect, useState } from "react";
import { ReactComponent as CompletedIcon } from "../../../../assets/assessmentPlatform/section-completed-icon.svg";
import { ReactComponent as NotStartedIcon } from "../../../../assets/assessmentPlatform/section-not-started-icon.svg";
import { ReactComponent as FlagActive } from "../../../../assets/assessmentPlatform/flag-active.svg";
import { ReactComponent as FlagInActive } from "../../../../assets/assessmentPlatform/flag-inactive.svg";
import { flagQuestion } from "../../../../actions/assessment";
import { assessmentStore } from "../../../../store";

export const QuestionItem = ({
  isAnswered,
  isQuestionSectionSelected,
  isFlagged = false,
  sectionId,
  questionId,
  questionOrder,
  handleSelectQuestion
}) => {
  const { assessmentId, assessmentVersionId, educatorId } = assessmentStore();

  const [flagged, setFlagged] = useState(() => isFlagged);

  const handleToggleFlagged = (event) => {
    event.stopPropagation();

    const newValue = !flagged;

    setFlagged(newValue);

    flagQuestion({
      isFlagged: newValue,
      questionId,
      sectionId,
      educatorId,
      assessivId: assessmentId,
      assessivVersionId: assessmentVersionId
    })
      .catch((error) => {
        setFlagged(!newValue);
        console.log(error);
      });
  }

  useEffect(
    () => setFlagged(isFlagged),
    [isFlagged]
  );

  return (
    <div className="flex flex-row h-8 w-full items-center cursor-pointer group mt-[-0.2em]" onClick={handleSelectQuestion}>
      <div className="w-3 rounded-bl border-whisperBlue border-l border-b ml-3 mb-10 h-[calc(100%+12px)]"></div>
      <div className="flex flex-row items-center w-full pl-2.5 py-1 group-hover:bg-slate-200 rounded">
        {isAnswered ? <CompletedIcon className="flex-shrink-0" /> : <NotStartedIcon className="flex-shrink-0" />}

        <div className={`flex justify-between ${isQuestionSectionSelected ? "text-navySmoke" : "text-duskyHarbor"} px-2 items-center text-sm w-full rounded h-full`}>
          {`Question ${questionOrder}`}

          {isQuestionSectionSelected && (
            <div className={`flex-row gap-2 ${flagged ? 'flex' : 'hidden group-hover:flex'}`} onClick={handleToggleFlagged}>
              {flagged ? <FlagActive /> : <FlagInActive />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};