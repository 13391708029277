import { useEffect, useRef, useState } from "react";
import Tree from 'react-d3-tree';
import { Header } from "../../components/Header";
import { MenuCard } from "../dashboard/components/utilityComponents";
import { fetchHierarchy } from "../../actions/dashboard";

const convertData = (user) => {
  return {
    name: user.name,
    attributes: {
      email: user.email,
      role: user.role,
    },
    children: user?.subordinates?.map?.(convertData),
  };
};

export const Organization = () => {
  const [organization, setOrganization] = useState();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);

  const data = organization ? convertData(organization) : undefined;

  useEffect(() => {
    fetchHierarchy().then((data) => {
      setOrganization(data?.data);
    });
  }, []);

  useEffect(() => {
    if (containerRef.current && dimensions.width === 0 && dimensions.height === 0) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setDimensions({ width, height });
    }
  }, [dimensions]);

  return (
    <div className="App-header flex flex-col bg-slate-200 h-screen">
      <Header/>

      <div className="flex flex-1 bg-slate-200 z-0">
        <MenuCard className="h-full text-black p-6 w-full">
          <div ref={containerRef} className="w-full h-full">
            {!!data && dimensions.width > 0 && dimensions.height > 0 && (
              <Tree
                data={data}
                orientation="vertical"
                pathFunc="step"
                nodeSize={{ x: 450, y: 150 }}
                linkStyle={{ stroke: '#888', strokeWidth: 2 }}
                translate={{ x: dimensions.width / 2, y: dimensions.height / 10 }}
              />
            )}
          </div>
        </MenuCard>
      </div>
    </div>
  );
};