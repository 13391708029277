import { DocumentPlusIcon, BookOpenIcon, CalculatorIcon } from "@heroicons/react/24/solid";

const Icon = ({ scoreName }) => {
  switch (scoreName) {
    case "Total":
      return <DocumentPlusIcon className="h-5 w-5 text-white" />;
    case "Reading and Writing":
      return <BookOpenIcon className="h-5 w-5 text-red-500" />;
    case "Math":
      return <CalculatorIcon className="h-5 w-5 text-yellow-500" />;
    default:
      return <DocumentPlusIcon className="h-5 w-5 text-green-500" />;
  }
};

export const ScoreTile = ({
  scoreName,
  iconVisible,
  highlighted,
  score,
  ofTotal,
  scoreType,
  scoreDescription,
}) => {
  return (
    <div
      className={`flex flex-1 flex-col border rounded-xl p-4 justify-center ${
        highlighted ? `bg-blue-50 border-blue-300` : `border-gray-300`} ${
        iconVisible ? '' : `items-center`
      }`}
    >
      {!!iconVisible && (
        <div className={`flex items-center justify-center h-8 w-8 ${highlighted ? `bg-blue-400` : `bg-gray-100`} rounded-md mb-4`}>
          <Icon scoreName={scoreName} />
        </div>
      )}

      <div className="w-full">
        <div className="flex flex-row gap-1 items-center">
          <p className="font-semibold text-lg">{score}</p>
          <p className="text-xs text-gray-500">/ {ofTotal}</p>
        </div>

        {iconVisible ? (
          <>
            <p className="text-sm text-gray-500">{scoreType}</p>
            <p className="text-xs text-gray-500 mt-4">{scoreDescription}</p>
          </>
        ) : (
          <div className="text-sm">Reading</div>
        )}
      </div>
    </div>
  );
}
