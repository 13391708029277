const rows = [
  { name: 'Number\nCorrect', key: 'correct' },
  { name: 'Number\nIncorrect', key: 'inCorrect'},
  { name: 'Number\nBlank', key: 'omitted' }
];

const getSectionType = (mappingType) => {
  switch (mappingType) {
    case 1: return 'A';
    case 2: return 'B';
    default: return  '';
  }
}

export const ModulesOverview = ({ sectionCumulatives = []}) => {

  if (sectionCumulatives?.length === 1) {
    return (
      <div className="w-full">
        <h2 className="w-full text-center text-base font-semibold mb-3">Modules Overview</h2>

        <div className="w-full border rounded-xl overflow-hidden">
          <table className="w-full">
            <thead>
            <tr className="bg-gray-100 text-gray-500">
              <th className="p-3 text-sm font-medium" colSpan={3}>
                Section
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              {rows.map((row, index) => (
                <td key={row.name} className={`p-3 ${index === 0 ? '' : 'border-l'}`}>
                  <div className="flex items-center text-center text-sm">
                    <div className="w-2/3 inline-block whitespace-pre-line">{row.name}</div>
                    <div className="w-1/3">{sectionCumulatives?.[0]?.[rows[index ?? 0]?.key]}</div>
                  </div>
                </td>
              ))}
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <h2 className="w-full text-center text-base font-semibold mb-3">Modules Overview</h2>

      <div className="w-full border rounded-xl overflow-hidden">
        <table className="w-full">
          <thead>
          <tr className="bg-gray-100 text-gray-500">
            {sectionCumulatives?.map(section => (
              <th key={section.name} className="p-3 text-sm font-medium">
                {section.name}
                {getSectionType(section.mappingType)}
              </th>
            ))}
          </tr>
          </thead>
          <tbody>
          {rows.map(row => (
            <tr key={row.name}>
              {sectionCumulatives.map((section, index) => (
                <td key={section.name} className={`p-3 ${index === 0 ? '' : 'border-l'}`}>
                  <div className="flex items-center text-center text-sm">
                    <div className="w-2/3 inline-block whitespace-pre-line">{row.name}</div>
                    <div className="w-1/3">{section[row.key]}</div>
                  </div>
                </td>
              ))}
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}