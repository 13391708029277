import { Abbreviator } from "../helpers/index.ts";

export const UsersBubbles = ({ users, children, bubbleBackground = 'bg-blue-600', }) => {
  return (
    <div className="flex items-center gap-1 text-black">
      {users?.length ? (
        <>
          <div className="flex">
            {[...users].slice(0, 4).map((student, index) => (
              <div
                key={student.email}
                className={`w-6 h-6 flex items-center justify-center flex-shrink-0 border border-white rounded-full text-[10px] text-white relative overflow-hidden ${bubbleBackground}`}
                style={{right: index * 6}}
              >
                {student.img ? (
                  <img className="w-full h-full" src={student.img} alt="profile"/>
                ) : (
                  Abbreviator(student.name)
                )}
              </div>
            ))}
          </div>

          {users.length > 4 && (
            <span className="relative right-4 text-xs font-medium">+{users.length - 4}</span>
          )}
        </>
      ) : (
        <span className="text-xs text-fadedDenim h-6">-</span>
      )}

      {children}
    </div>
  );
}