import { ASSESSMENT_STATUS } from "../../../../../../helpers/constants";

const statusOptions = {
  [ASSESSMENT_STATUS.FRESH]: { color: 'bg-skyBlue', text: 'New' },
  [ASSESSMENT_STATUS.NOT_STARTED]: { color: 'bg-yellow-500', text: 'Not Started' },
  [ASSESSMENT_STATUS.IN_PROGRESS]: { color: 'bg-yellow-500', text: 'In Progress' },
  [ASSESSMENT_STATUS.ABANDONED]: { color: 'bg-red-600', text: ASSESSMENT_STATUS.ABANDONED },
  [ASSESSMENT_STATUS.COMPLETED]: { color: 'bg-emeraldGreen', text: ASSESSMENT_STATUS.COMPLETED },
};

export const StatusLabel = ({ className = '', status = ASSESSMENT_STATUS.FRESH }) => {

  const { color, text } = (statusOptions[status] ?? statusOptions[ASSESSMENT_STATUS.FRESH]);

  return (
    <div className={`flex items-center justify-center gap-2 w-max bg-whisperWhite rounded-lg px-2 py-1 text-xs ml-auto ${className}`}>
      <div className={`w-1 h-1 rounded-full flex-shrink-0 ${color}`} />
      {text}
    </div>
  );
}