import React, { useState } from "react";
import { fetchQuestion } from "../../../../../actions/assessment";
import { ReactComponent as FlagActive } from "../../../../../assets/assessmentPlatform/flag-active.svg";
import { CorrectAnswer } from "./CorrectAnswer";
import { DifficultySquares } from "./DifficultySquares";
import { SelectedAnswer } from "./SelectedAnswer";
import { QuestionPreviewModal } from "../questionPreview/QuestionPreviewModal";
import { formatTime } from "../../../../../helpers/date.ts";

export const QuestionsTableRow = ({
  answer,
  index,
}) => {
  const [question, setQuestion] = useState(null);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = () => {
    if (question) {
      setIsPreviewVisible(true);
    } else {
      document.body.classList.add('cursor-wait');
      setIsLoading(true);
      fetchQuestion(answer?.questionId)
        .then((data) => {
          const question = data?.data;
          if (question) {
            setQuestion(question);
            setIsPreviewVisible(true);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          document.body.classList.remove('cursor-wait');
          setIsLoading(false);
        });
    }
  }

  return (
    <React.Fragment>
      <tr>
        <td colSpan="8" className="p-0">
          <div className="relative">
            <QuestionPreviewModal
              answer={answer}
              question={question}
              index={index}
              isPreviewVisible={isPreviewVisible}
              setIsPreviewVisible={setIsPreviewVisible}
            />
          </div>
        </td>
      </tr>
      <tr
        className={`bg-white border ${isLoading ? 'cursor-wait' : 'cursor-pointer'}`}
        onClick={handleOnClick}
      >
        <td className="text-sm text-gray-500 w-[92px]">
          <div className="px-4 m-1 rounded flex justify-center">
            <div
              className="flex justify-center text-xs items-center rounded-full border border-gray-300 text-gray-600 h-5 w-5">{index + 1}</div>
          </div>
        </td>
        <td className="text-sm text-gray-500 w-[92px]">
          <CorrectAnswer answer={answer}/>
        </td>
        <td className="text-sm text-gray-500 w-[92px] max-w-[92px]">
          <SelectedAnswer answer={answer}/>
        </td>
        <td className="text-sm text-gray-500 px-4 leading-none">{answer.skill}</td>
        <td className="text-sm text-gray-500 px-3">
          <DifficultySquares difficulty={answer?.difficulty}/>
        </td>
        <td className="text-sm text-gray-500 px-4">{formatTime(answer?.timeSpentInSec)}</td>
        <td className="pr-4 text-right w-[96px]">
          {!!answer?.annotated && <div className="w-5 h-5 rounded-full bg-gray-300 mx-auto"/>}
        </td>
        <td className="pr-4 text-right w-[96px]">
          {!!answer?.isFlagged && <FlagActive className="ml-auto mr-3"/>}
        </td>
      </tr>
    </React.Fragment>
  );
};