import { ScoreTile } from "./scoreTile";

const getScoreDescription = (subject, sortedSubjectResults) => {
  const getTotalTileDescription = (sortedSubjectResults) => {
    const subjectsNames = sortedSubjectResults
      .filter(subject => subject !== 'Total')
      .map(subject => subject.name)
      .join(', ');
    return `Sum of the scores: ${subjectsNames}.`;
  }

  if (subject.name === 'Total') {
    return getTotalTileDescription(sortedSubjectResults);
  }

  return `Your total SAT score from both ${subject.name} modules.`;
}

export const ScoreTiles = ({ sortedSubjectResults, className = '' }) => {
  return (
    <div className={`flex flex-row w-full justify-between gap-6 ${className}`}>
      {sortedSubjectResults.map((subjectResult => (
        <ScoreTile
          iconVisible
          key={subjectResult.name}
          highlighted={subjectResult.name === 'Total'}
          score={subjectResult?.score}
          ofTotal={subjectResult.name === 'Total' ? '1600' : '800'}
          scoreType={subjectResult.name === 'Total' ? 'Total Score' : subjectResult.name}
          scoreDescription={getScoreDescription(subjectResult, sortedSubjectResults)}
          scoreName={subjectResult.name}
        />
      )))}
    </div>
  );
}