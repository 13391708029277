import { motion, AnimatePresence } from "framer-motion"
import { Fragment } from "react"

export const AnimIn = (props) => (
  <AnimatePresence as={Fragment}>
    {props.isVisible && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={props.className}
      >
        {props.children}
        </motion.div>
    )}
  </AnimatePresence>
)